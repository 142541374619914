import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CONSTANTS from '../../constants';

import './SearchBar.scss';

const SearchBar = ({
  placeholder,
  customClass,
  value,
  onChange,
  onKeyUp,
  onEnter,
  onKeyPress,
  ...props
}) => {
  const classes = cx(['search-bar', customClass]);
  const onTextChanged = event => {
    if (typeof onChange === 'function') {
      const { value: searchValue = '' } = event.target;
      onChange(searchValue);
    }
  };
  const onEnterKeyPressed = event => {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13 && typeof onEnter === 'function') {
      event.preventDefault();
      const { value: searchValue = '' } = event.target;
      onEnter(searchValue);
    }
  };
  const onUserStoppedTyping = event => {
    if (typeof onKeyUp === 'function') {
      event.preventDefault();
      const { value: searchValue = '' } = event.target;
      onKeyUp(searchValue);
    }
  };

  return (
    <input
      id="searchBar"
      type="text"
      className={classes}
      placeholder={placeholder}
      value={value}
      onKeyUp={onUserStoppedTyping}
      onChange={onTextChanged}
      onKeyPress={onEnterKeyPressed}
      {...props}
    />
  );
};

SearchBar.propTypes = {
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func
};

SearchBar.defaultProps = {
  customClass: '',
  placeholder: CONSTANTS.SEARCH.searchbarPlaceHolderText,
  onEnter: null,
  onKeyPress: null,
  onKeyUp: null
};

export default SearchBar;
