const ENUM = {
  STATUS: {
    ALLOWED: 1,
    REMOVED: 2
  },
  ITEM_TYPE: {
    FEED_ITEM: 1,
    COMMENT: 2
  },
  FEED_ITEM_TYPE: {
    APPRECIATION: 1,
    BADGE: 2,
    HUMBLE_BRAG: 3
  },
  USER_ROLE: {
    USER: '1',
    MODERATOR: '2',
    ADMINISTRATOR: '3'
  },
  DISPOSITION_TYPES: {
    ALL_DISPOSITIONS: '0',
    ALLOWED: '1',
    REMOVED: '2'
  },
  POSTS_BY: {
    ALL_HCA: '0',
    BY_PERSON: '1',
    BY_DIVISION: '2'
  },
  DATE_RANGE: {
    ANY_TIME: '0',
    TODAY: '1',
    LAST_WEEK: '2',
    LAST_MONTH: '3',
    LAST_YEAR: '4',
    SELECT_DATES: '5'
  }
};

export default ENUM;
