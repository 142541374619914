import { ACTIONS } from '../actions/actionType';
import { setSession, logout } from '../../services/AuthService';

const initialState = {
  loading: false,
  user: {},
  lastActiveTime: null,
  notificationCount: 0,
  isAuthenticated: true
};

const Auth = (state = initialState, action) => {
  const tempState = { ...state };
  switch (action.type) {
    case ACTIONS.AUTH.GET_ACCESS_TOKEN_CALL_SUCCESS:
      setSession(action.data.access_token, action.data.expires_in);
      // tempState.isAuthenticated = true;
      tempState.loading = false;
      return tempState;
    case ACTIONS.AUTH.GET_ACCESS_TOKEN_CALL_FAIL:
      logout();
      // tempState.isAuthenticated = false;
      tempState.loading = false;
      return tempState;

    case ACTIONS.AUTH.SET_LAST_LOGGED_ON_SUCCESS:
    case ACTIONS.AUTH.SET_LAST_LOGGED_ON_FAIL:
      return tempState;
    default:
      return tempState;
  }
};

export default Auth;
