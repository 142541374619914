/**
 * @description All action will be written inside this file. Format for this action are as follows
 * REDUCER_NAME_ACTION_NAME(AUTH_LOGIN_IN)
 */
export const ACTIONS = {
  COMMON: {
    GET_SAS_TOKEN: 'COMMON-GET_SAS_TOKEN',
    GET_SAS_TOKEN_SUCCESS: 'COMMON-GET_SAS_TOKEN_SUCCESS',
    GET_SAS_TOKEN_FAIL: 'COMMON-GET_SAS_TOKEN_FAIL',
    QUEUE_ACTION_ON_REFRESH_SAS_TOKEN: 'QUEUE_ACTION_ON_REFRESH_SAS_TOKEN',
    SET_ALERT_MESSAGE: 'COMMON-SET_ALERT_MESSAGE',
    GET_USER_ROLE: 'COMMON-GET_USER_ROLE',
    GET_USER_ROLE_SUCCESS: 'COMMON-GET_USER_ROLE_SUCCESS',
    GET_USER_ROLE_FAIL: 'COMMON-GET_USER_ROLE_FAIL',
    RESET_COMMON_REDUCER: 'COMMON-RESET_COMMON_REDUCER',
    GET_NOTIFICATION_COUNT: 'COMMON-GET_NOTIFICATION_COUNT',
    GET_NOTIFICATION_COUNT_SUCCESS: 'COMMON-GET_NOTIFICATION_COUNT_SUCCESS',
    GET_NOTIFICATION_COUNT_FAIL: 'COMMON-GET_NOTIFICATION_COUNT_FAIL',
    FEED_REMOVAL_REASON: 'COMMON-FEED_REMOVAL_REASON',
    FEED_REMOVAL_REASON_SUCCESS: 'COMMON-FEED_REMOVAL_REASON_SUCCESS',
    FEED_REMOVAL_REASON_FAIL: 'COMMON-FEED_REMOVAL_REASON_FAIL',
    SHOW_ACTION_MODAL: 'COMMON-SHOW_ACTION_MODAL',
    HIDE_ACTION_MODAL: 'COMMON-HIDE_ACTION_MODAL',
    ALLOW_REMOVE_POST: 'COMMON-ALLOW_REMOVE_POST',
    ALLOW_REMOVE_POST_SUCCESS: 'COMMON-ALLOW_REMOVE_POST_SUCCESS',
    ALLOW_REMOVE_POST_FAIL: 'COMMON-ALLOW_REMOVE_POST_FAIL',
    FEED_ACTION: 'COMMON-FEED_ACTION',
    ALLOW_REMOVE_DONE_ALREADY: 'COMMON-ALLOW_REMOVE_DONE_ALREADY',
    DISMISS_WARNING_MODAL: 'COMMON-DISMISS_WARNING_MODAL',
    FETCH_USER_DETAIL: 'COMMON-FETCH_USER_DETAIL',
    FETCH_USER_DETAIL_SUCCESS: 'COMMON-FETCH_USER_DETAIL_SUCCESS',
    FETCH_USER_DETAIL_FAIL: 'COMMON-FETCH_USER_DETAIL_FAIL'
  },
  AUTH: {
    GET_ACCESS_TOKEN_CALL_SUCCESS: 'AUTH-GET_ACCESS_TOKEN_CALL_SUCCESS',
    GET_ACCESS_TOKEN_CALL_FAIL: 'AUTH-GET_ACCESS_TOKEN_CALL_FAIL',
    SET_LAST_LOGGED_ON: 'SET_LAST_LOGGED_ON',
    SET_LAST_LOGGED_ON_SUCCESS: 'SET_LAST_LOGGED_ON_SUCCESS',
    SET_LAST_LOGGED_ON_FAIL: 'SET_LAST_LOGGED_ON_FAIL'
  },
  TASK_LIST: {
    FETCH_TASK_LIST: 'TASK_LIST-FETCH_TASK_LIST',
    FETCH_TASK_LIST_SUCCESS: 'TASK_LIST-FETCH_TASK_LIST_SUCCESS',
    FETCH_TASK_LIST_FAIL: 'TASK_LIST-FETCH_TASK_LIST_FAIL',
    RESET_TASK_LIST: 'TASK_LIST-RESET_TASK_LIST',
    REMOVE_TASK_FROM_LIST: 'TASK_LIST-REMOVE_TASK_FROM_LIST'
  },
  REPORT: {
    FETCH_USER_LIST: 'REPORT-FETCH_USER_LIST',
    FETCH_USER_LIST_SUCCESS: 'REPORT-FETCH_USER_LIST_SUCCESS',
    FETCH_USER_LIST_FAIL: 'REPORT-FETCH_USER_LIST_FAIL',
    UPDATE_SELECTED_USERS_LIST: 'REPORT-UPDATE_SELECTED_USERS_LIST',
    CLEAR_USER_LIST: 'REPORT-CLEAR_USER_LIST',
    FETCH_DIVISION_LIST: 'REPORT-FETCH_DIVISION_LIST',
    FETCH_DIVISION_LIST_SUCCESS: 'REPORT-FETCH_DIVISION_LIST_SUCCESS',
    FETCH_DIVISION_LIST_FAIL: 'REPORT-FETCH_DIVISION_LIST_FAIL',
    UPDATE_SELECTED_DIVISIONS_LIST: 'REPORT-UPDATE_SELECTED_DIVISIONS_LIST',
    CLEAR_DIVISION_LIST: 'REPORT-CLEAR_DIVISION_LIST',
    UPDATE_REPORT_INPUTS: 'REPORT-UPDATE_REPORT_INPUTS',
    RESET_STATE: 'REPORT-RESET_STATE',
    FETCH_REPORT_SUMMARY: 'REPORT-FETCH_REPORT_SUMMARY',
    FETCH_REPORT_SUMMARY_SUCCESS: 'REPORT-FETCH_REPORT_SUMMARY_SUCCESS',
    FETCH_REPORT_SUMMARY_FAIL: 'REPORT-FETCH_REPORT_SUMMARY_FAIL',
    DOWNLOAD_REPORT_SUMMARY: 'REPORT-DOWNLOAD_REPORT_SUMMARY',
    DOWNLOAD_REPORT_SUMMARY_SUCCESS: 'REPORT-DOWNLOAD_REPORT_SUMMARY_SUCCESS',
    DOWNLOAD_REPORT_SUMMARY_FAIL: 'REPORT-DOWNLOAD_REPORT_SUMMARY_FAIL'
  },
  SEARCH: {
    FETCH_SEARCHED_USER: 'SEARCH-FETCH_SEARCHED_USER',
    FETCH_SEARCHED_USER_SUCCESS: 'SEARCH-FETCH_SEARCHED_USER_SUCCESS',
    FETCH_SEARCHED_USER_FAIL: 'SEARCH-FETCH_SEARCHED_USER_FAIL',
    CLEAR_SEARCHED_USER: 'SEARCH-CLEAR_SEARCHED_USER',
    FETCH_USER_PROFILE: 'SEARCH-FETCH_USER_PROFILE',
    FETCH_USER_PROFILE_SUCCESS: 'SEARCH-FETCH_USER_PROFILE_SUCCESS',
    FETCH_USER_PROFILE_FAIL: 'SEARCH-FETCH_USER_PROFILE_FAIL',
    CLEAR_USER_PROFILE: 'SEARCH-CLEAR_USER_PROFILE',
    USER_PERSONAL_DETAILS: 'SEARCH-USER_PERSONAL_DETAILS',
    USER_PERSONAL_DETAILS_SUCCESS: 'SEARCH-USER_PERSONAL_DETAILS_SUCCESS',
    USER_PERSONAL_DETAILS_FAIL: 'SEARCH-USER_PERSONAL_DETAILS_FAIL',
    UPDATE_FEED_ACTION: 'SEARCH-UPDATE_FEED_ACTION',
    LOAD_MORE_SEARCHED_USER: 'SEARCH-LOAD_MORE_SEARCHED_USER',
    LOAD_MORE_SEARCHED_USER_SUCCESS: 'SEARCH-LOAD_MORE_SEARCHED_USER_SUCCESS',
    LOAD_MORE_SEARCHED_USER_FAIL: 'SEARCH-LOAD_MORE_SEARCHED_USER_FAIL'
  },
  ADMIN_MODERATOR: {
    GET_SUPER_USER_LIST: 'ADMIN_MODERATOR-GET_SUPER_USER_LIST',
    GET_SUPER_USER_LIST_SUCCESS: 'ADMIN_MODERATOR-GET_SUPER_USER_LIST_SUCCESS',
    GET_SUPER_USER_LIST_FAIL: 'ADMIN_MODERATOR-GET_SUPER_USER_LIST_FAIL',
    CLEAR_SUPER_USER_LIST: 'ADMIN_MODERATOR-CLEAR_SUPER_USER_LIST',
    DELETE_SUPER_USER: 'ADMIN_MODERATOR-DELETE_SUPER_USER',
    DELETE_SUPER_USER_SUCCESS: 'ADMIN_MODERATOR-DELETE_SUPER_USER_SUCCESS',
    DELETE_SUPER_USER_FAIL: 'ADMIN_MODERATOR-DELETE_SUPER_USER_FAIL',
    UPDATE_SUPER_USER_ROLES: 'ADMIN_MODERATOR-UPDATE_SUPER_USER_ROLES',
    UPDATE_SUPER_USER_ROLES_SUCCESS:
      'ADMIN_MODERATOR-UPDATE_SUPER_USER_ROLES_SUCCESS',
    UPDATE_SUPER_USER_ROLES_FAIL:
      'ADMIN_MODERATOR-UPDATE_SUPER_USER_ROLES_FAIL',
    UPDATE_SUPER_USER_ROLES_MODIFIED:
      'ADMIN_MODERATOR-UPDATE_SUPER_USER_ROLES_MODIFIED',
    GET_USER_LIST: 'ADMIN_MODERATOR-GET_USER_LIST',
    GET_USER_LIST_SUCCESS: 'ADMIN_MODERATOR-GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAIL: 'ADMIN_MODERATOR-GET_USER_LIST_FAIL',
    CLEAR_USER_LIST: 'ADMIN_MODERATOR-CLEAR_USER_LIST',
    UPDATE_USER_ROLES_MODIFIED: 'ADMIN_MODERATOR-UPDATE_USER_ROLES_MODIFIED',
    ADD_USERS: 'ADMIN_MODERATOR-ADD_USERS',
    SHOW_ADD_USERS_MODAL: 'ADMIN_MODERATOR-SHOW_ADD_USERS_MODAL',
    HIDE_ADD_USERS_MODAL: 'ADMIN_MODERATOR-HIDE_ADD_USERS_MODAL'
  }
};

export const actionsRequireSASToken = [
  ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST_SUCCESS,
  ACTIONS.ADMIN_MODERATOR.GET_USER_LIST_SUCCESS,
  ACTIONS.TASK_LIST.FETCH_TASK_LIST_SUCCESS,
  ACTIONS.SEARCH.FETCH_SEARCHED_USER_SUCCESS,
  ACTIONS.SEARCH.FETCH_USER_PROFILE_SUCCESS
];
