import React from 'react';
import PropTypes from 'prop-types';

import { SvgImage } from '../../../common';

import cardIcon from '../../../assets/images/card_icon.svg';
import cardIconActive from '../../../assets/images/card_icon_active.svg';

import listIcon from '../../../assets/images/list_icon.svg';
import listIconActive from '../../../assets/images/list_icon_active.svg';

import './ViewBy.scss';

const ViewBy = props => {
  const { onClick, viewCard } = props;
  let cardImg;
  let listImg;

  // card view is selected then viewCard value will be true.
  if (viewCard) {
    cardImg = cardIconActive;
    listImg = listIcon;
  } else {
    cardImg = cardIcon;
    listImg = listIconActive;
  }

  const toggleViewBy = bool => {
    if (bool !== viewCard) {
      onClick(bool);
    }
  };

  return (
    <div className="view-by">
      <SvgImage
        src={cardImg}
        alt="card"
        className="icon"
        onClick={() => toggleViewBy(true)}
        role="button"
      />
      <SvgImage
        src={listImg}
        alt="list"
        className="icon"
        onClick={() => toggleViewBy(false)}
        role="button"
      />
    </div>
  );
};

ViewBy.propTypes = {
  onClick: PropTypes.func.isRequired,
  viewCard: PropTypes.bool.isRequired
};

export default ViewBy;
