import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InfiniteScroller } from '../../common';
import Card from './Card/Card';

import { createMasonryArray } from '../../utils/utils';

import './CardView.scss';

export default class CardView extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    hasMore: PropTypes.bool.isRequired,
    fetchMore: PropTypes.func.isRequired,
    nextPage: PropTypes.number.isRequired,
    sasToken: PropTypes.string.isRequired,
    allowAction: PropTypes.func.isRequired,
    removeAction: PropTypes.func.isRequired,
    isEmojiSupported: PropTypes.bool.isRequired,
    onUserDetailsClicked: PropTypes.func
  };

  static defaultProps = {
    onUserDetailsClicked: null
  };

  /**
   * @public For getting next page number when scrolled to fetch more data from server
   * @description
   * This method is used to fetch data(page) whose data has to been fetched when page is scrolled.
   */
  fetchData = () => {
    const { nextPage } = this.props;
    this.props.fetchMore(nextPage);
  };

  /**
   * @description
   * This method is used to render individual card component.
   *
   * @returns {ReactComponent} This will return Card rendering JSX.
   */
  renderCard = dataSet =>
    dataSet.map(data => {
      const { sasToken, isEmojiSupported } = this.props;
      return (
        <Card
          key={data.id}
          data={data}
          sasToken={sasToken}
          isEmojiSupported={isEmojiSupported}
          allowAction={this.props.allowAction}
          removeAction={this.props.removeAction}
          onUserDetailsClicked={this.props.onUserDetailsClicked}
        />
      );
    });

  render() {
    const { data, hasMore } = this.props;
    const dataLength = data.length;
    const [column1, column2, column3] = createMasonryArray(data);
    return (
      <div className="card-view-container">
        <InfiniteScroller
          dataLength={dataLength}
          hasMore={hasMore}
          fetchMore={this.fetchData}
        >
          <div className="staggered-grid col-1">{this.renderCard(column1)}</div>
          <div className="staggered-grid col-2">{this.renderCard(column2)}</div>
          <div className="staggered-grid col-3">{this.renderCard(column3)}</div>
        </InfiniteScroller>
      </div>
    );
  }
}
