import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScrollComponent from 'react-infinite-scroll-component';

import loaderImage from '../../assets/images/Loader.gif';
import './InfiniteScroller.scss';

const InfiniteScroller = props => {
  const { children, dataLength, fetchMore, hasMore, loader } = props;
  return (
    <InfiniteScrollComponent
      dataLength={dataLength}
      next={fetchMore}
      hasMore={hasMore}
      loader={loader}
    >
      {children}
    </InfiniteScrollComponent>
  );
};

InfiniteScroller.defaultProps = {
  loader: (
    <img src={loaderImage} className="infinite-scroll-loader" alt="Loading" />
  )
};

InfiniteScroller.propTypes = {
  children: PropTypes.any.isRequired,
  dataLength: PropTypes.number.isRequired,
  fetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loader: PropTypes.any
};

export default InfiniteScroller;
