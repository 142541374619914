import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ExifOrientation from 'react-exif-orientation-img';
import cx from 'classnames';
import './ImgWithExif.scss';

class ImgWithExif extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    onError: PropTypes.func,
    imgClassName: PropTypes.string,
    placeholderClassName: PropTypes.string
  };

  static defaultProps = {
    alt: '',
    onError: () => {},
    imgClassName: '',
    placeholderClassName: ''
  };

  state = {
    render: false
  };

  onLoad = () => {
    this.setState({ render: true });
  };

  render() {
    const {
      src,
      alt,
      onError,
      imgClassName,
      placeholderClassName
    } = this.props;
    const { render } = this.state;

    const exitImageClassName = cx([
      'animated fade-in exif-img',
      imgClassName,
      { 'display-inline-block': render, 'display-none': !render }
    ]);

    const imagePlaceholderClassName = cx([
      placeholderClassName,
      { 'display-none': render, 'display-inline-block': !render },
      'image-placeholder'
    ]);

    return (
      <Fragment>
        <ExifOrientation
          src={src}
          onLoad={this.onLoad}
          className={exitImageClassName}
          alt={alt}
          onError={onError}
        />
        <div className={imagePlaceholderClassName}>
          <div className="img-loader" />
        </div>
      </Fragment>
    );
  }
}

export default ImgWithExif;
