import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgImage from '../SvgImage/SvgImage';
import './RoundedDropDown.scss';

import dropIcon from '../../assets/images/drop.svg';
import tickIcon from '../../assets/images/tick.svg';
import upArrowIcon from '../../assets/images/up-arrow.svg';
import { isDocumentElement } from '../../utils/utils';

class RoundedDropDown extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    customClasses: PropTypes.string
  };

  static defaultProps = {
    customClasses: ''
  };

  constructor(props) {
    super(props);
    this.parentDiv = React.createRef();
  }

  state = {
    isOpen: false
  };

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleOutsideClick);
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  };

  // When user clicks outside other then component. Then drop down will close
  handleOutsideClick = event => {
    // Do not close the dropdown if user tries click on scroll bar/buttons.
    if (isDocumentElement(event.target)) {
      return;
    }

    if (!this.parentDiv.current.contains(event.target)) {
      // Click is outside. So close the dropdown menu
      this.setState({ isOpen: false });
    }
  };

  // Method which toggle show drop down
  toggleHandler = () =>
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  // Selecting on options
  onSelectHandler = selectedItem => this.props.onChange(selectedItem);

  // renders the selected icon and default icon for the options
  renderIcon = (index, item, selectedOption) => {
    if (index === 0 && item.disabled) {
      // show up arrow on default
      return <SvgImage src={upArrowIcon} alt="" className="display-icon" />;
    }
    return (
      item.value === selectedOption.value &&
      !item.disabled && (
        <SvgImage
          src={tickIcon}
          alt="selected"
          className="display-icon"
          width="13px"
        />
      )
    );
  };

  render() {
    const { isOpen } = this.state;
    const { options, selectedOption, customClasses } = this.props;

    const optionsListClass = cx(['options-list', { active: isOpen }]);
    const dropdownClasses = cx(['rounded-drop-down', customClasses]);

    return (
      <div className={dropdownClasses} ref={this.parentDiv}>
        <div className="dropdown-menu">
          {isOpen ? (
            // Open drop down
            <ul className={optionsListClass} onClick={this.toggleHandler}>
              {options.map((item, index) => (
                <li
                  key={`${item.value}-${index}`}
                  className={`options-item ${item.disabled ? 'disabled' : ''}`}
                  onClick={() =>
                    item.disabled ? false : this.onSelectHandler(item)
                  }
                >
                  <span className="display-text">{item.text}</span>
                  {this.renderIcon(index, item, selectedOption)}
                </li>
              ))}
            </ul>
          ) : (
            // Closed drop down
            <div
              className="selected-item"
              role="button"
              onClick={this.toggleHandler}
            >
              <span className="display-text">{selectedOption.text}</span>
              <SvgImage
                src={dropIcon}
                alt="drop down"
                className="display-icon"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RoundedDropDown;
