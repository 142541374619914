import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  setLastLoggedOn,
  fetchAccessTokenFailure,
  fetchAccessTokenSuccess
} from '../../../redux/actions/Auth.actions';
import CONSTANTS from '../../../constants';
import { Loader } from '../../../common';

/**
 * @description
 * After entering username & password in external login page, it will be redirected
 * to our application with "token" appended in the url as hash parameter.
 *
 */
class AuthCallBack extends Component {
  /**
   * @description This method receives parses params in hash and extracts token from it.
   * Also calls handleLoginSuccess method if access token is present in the url
   */
  componentDidMount() {
    const authReponse = this.getParamsInHash();
    if (authReponse.access_token) this.handleLoginSuccess(authReponse);
    else this.props.dispatchGetAccessTokenFailure();
  }

  /**
   * @description This method dispatches the accessTokenSuccess action and
   * Sends a  login success message to the window opener and closes itself
   */

  handleLoginSuccess = authReponse => {
    this.props.dispatchGetAccessTokenSuccess(authReponse);

    const { opener } = window;
    const { loginSuccessMessage } = CONSTANTS.LOGIN;
    const origin = `${window.location.protocol}//${window.location.host}`;

    // checks if the instance of parent window is available.
    if (opener) {
      opener.postMessage(loginSuccessMessage, origin);
      window.close();
    } else {
      this.props.dispatchSetLoggedOnTime();
      this.props.history.push(CONSTANTS.COMMON.homepage);
    }
  };

  /**
   * @description Method to extract the params in a hash as an object
   */

  getParamsInHash = () => {
    return this.props.location.hash
      .substring(1)
      .split('&')
      .reduce((acc, curr) => {
        const [key, value] = curr.split('=');
        return { ...acc, [key]: value };
      }, {});
  };

  render() {
    return <Loader />;
  }
}

AuthCallBack.propTypes = {
  dispatchSetLoggedOnTime: PropTypes.func.isRequired,
  dispatchGetAccessTokenSuccess: PropTypes.func.isRequired,
  dispatchGetAccessTokenFailure: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  dispatchSetLoggedOnTime: () => dispatch(setLastLoggedOn()),
  dispatchGetAccessTokenSuccess: data =>
    dispatch(fetchAccessTokenSuccess(data)),
  dispatchGetAccessTokenFailure: () => dispatch(fetchAccessTokenFailure())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthCallBack)
);
