import React from 'react';
import PropTypes from 'prop-types';
import Twemoji from 'react-twemoji';
import './EmojiText.scss';

const EmojiText = ({ children, className, isEmojiSupported, noWrapper }) => {
  if (isEmojiSupported) return children;
  return (
    <Twemoji options={{ className }} noWrapper={noWrapper}>
      {children}
    </Twemoji>
  );
};

EmojiText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isEmojiSupported: PropTypes.bool.isRequired,
  noWrapper: PropTypes.bool
};

EmojiText.defaultProps = {
  className: '',
  noWrapper: false
};

export default EmojiText;
