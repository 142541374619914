import React from 'react';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import tickIcon from '../../assets/images/tick.svg';
import SvgImage from '../SvgImage/SvgImage';

const Dropdown = ({ options, selectedOption, onChange }) => {
  /**
   * @description Method will be called when an option is selected in the dropdown.
   * But if the same option is being selected multiple times, it will be filtered
   * @param {Object} option - selected option
   */
  const onChangeEvent = option => {
    if (option.value !== selectedOption.value) {
      onChange(option);
    }
  };

  return (
    <SemanticDropdown
      inline
      defaultValue={selectedOption.value || ''}
      text={selectedOption.text}
    >
      <SemanticDropdown.Menu className="dropdown-panel">
        {options.map(option => (
          <SemanticDropdown.Item
            key={option.key || option.value}
            className="dropdown-text"
            selected={selectedOption.value === option.value}
            onClick={() => onChangeEvent(option)}
          >
            <span>{option.text}</span>
            {selectedOption.value === option.value && (
              <SvgImage
                className="tick-mark"
                src={tickIcon}
                alt="Selected"
                width="12px"
                verticalAlign="none"
              />
            )}
          </SemanticDropdown.Item>
        ))}
      </SemanticDropdown.Menu>
    </SemanticDropdown>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.object.isRequired
};
