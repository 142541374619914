import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import 'react-daterange-picker/dist/css/react-calendar.css';

import './DateRangePickerDropdown.scss';
import DropdownButton from './DropdownButton/DropdownButton';
import DropdownPanel from './DropdownPanel/DropdownPanel';
import RoundedDropDown from '../RoundedDropDown/RoundedDropDown';

/**
 * @description
 * UI component which has these pre-defined date ranges:
 * "Any Date", "Today", "Last week", "Last month" & "Last year"
 * and a calendar view where user can select custom date ranges.
 *
 * Props:
 * selectedOption {Object} - The option which has to be selected initially.
 * onSelect {function} - Callback to be triggered on select of a date range.
 * customClasses {String} - list of custom css classes appended as a string.
 */
class DateRangePickerDropdown extends RoundedDropDown {
  static propTypes = {
    ...RoundedDropDown.propTypes,
    onSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.array
  };

  /**
   * @description
   * Handler gets called on select of any item in the dropdown or any date range being selected.
   * @param {Object} item - One of the option in the dropdown.
   * Note: In case of a date range selected from date-range-picker, `item` will have two properties
   * `start` and `end` representing the date range.
   */
  onItemSelected = item => {
    this.props.onSelect(item);
    this.toggleHandler();
  };

  render() {
    const { isOpen } = this.state;
    const { selectedOption, customClasses } = this.props;
    const dropdownClasses = cx(['datepicker-drop-down', customClasses]);

    return (
      <div className={dropdownClasses} ref={this.parentDiv}>
        <div className="dropdown-menu">
          {isOpen ? (
            // Open drop down
            <DropdownPanel
              isOpen={isOpen}
              selectedOption={selectedOption}
              onSelectHandler={this.onItemSelected}
            />
          ) : (
            // Closed drop down
            <DropdownButton
              selectedOption={selectedOption}
              toggleHandler={this.toggleHandler}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DateRangePickerDropdown;
