import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import cx from 'classnames';

import {
  Modal,
  PaginatedTable,
  SearchBar,
  UserDetails,
  Dropdown,
  PaginatedButton,
  Button,
  Loader
} from '../../../common';
import CONSTANTS from '../../../constants';
import {
  trackUserRoleChanges,
  updateUserRoles,
  getUserList,
  clearUserList,
  hideAddUsersModal
} from '../../../redux/actions/ModeratorList.actions';
import { ACTIONS } from '../../../redux/actions/actionType';
import { replaceWithNA } from '../../../utils/utils';
import './AddUsers.scss';

export class AddUsers extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onFetchUserList: PropTypes.func.isRequired,
    onClearUserList: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    common: PropTypes.object.isRequired,
    adminPageData: PropTypes.object.isRequired,
    onUserRoleChanged: PropTypes.func.isRequired,
    onUpdateUserRoles: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired
  };

  state = {
    searchTerm: ''
  };

  onKeyUp = debounce(() => {
    if (this.state.searchTerm.length > 2) {
      this.fetchData();
    }
  }, 1000);

  componentWillUnmount() {
    this.props.onClearUserList();
  }

  /**
   * @description
   * This method is used to fetch data from server.
   */
  fetchData = () => {
    const { searchTerm } = this.state;
    const data = { ...this.getCurrentPageData(), searchTerm };
    this.props.onFetchUserList(data);
  };

  /**
   * @description  This method listen to change event in search bar and set's state.
   * @param {string} `searchTerm` - The value entered by the user.
   */
  searchUsers = searchTerm => {
    this.setState({ searchTerm });
  };

  /**
   * @description Closes the "Add users" modal when called.
   * First the search term has to be reset and then dispatch a saga call to close the modal.
   * In the saga part, when close action is received, it resets the state so that
   * search table results are reset.
   */

  closeModal = () => {
    this.setState({ searchTerm: '' }, () => this.props.onModalClosed());
  };

  /**
   * @description This method gets called when user clicks on "Previous" or "Next" buttons
   * in Add User modal.
   * The table that lists the search results are limited to 5 results per page.
   * So to navigate to next page, this method is called on every button press.
   * @param {number} page - the new page number that the user has navigated to.
   * So it sends request to load that page's data.
   */
  onTablePageChange = page => {
    const data = { ...this.getCurrentPageData(), page };
    this.props.onFetchUserList(data);
  };

  /**
   * @description Always returns the current page number, total page size,
   * the search term specified (if any) as an object
   * Returned object is used to manipulate on page navigation or perform search
   */
  getCurrentPageData = (page = 0) => {
    const { pageSize: size } = CONSTANTS.ADMIN_AND_MODERATORS.addUser;
    const searchTerm = this.state.searchTerm.trim();
    const sort = 'firstName,asc';
    return { page, size, searchTerm, sort };
  };

  /**
   * @description This method gets called when admin modifies any other user's role
   * @param updatedRole
   * @param {number} userId - the user id for whom the role is modified
   */
  onUserRoleChanged = (updatedRole, userId) => {
    // updating props with selected role;
    const { list: userList } = this.props.user;
    const user = userList.find(({ id }) => id === userId);
    const actualRole = user.role;
    const { modifiedRoles } = this.props.user;
    const { UPDATE_USER_ROLES_MODIFIED: actionType } = ACTIONS.ADMIN_MODERATOR;
    this.props.onUserRoleChanged({
      actionType,
      modifiedRoles,
      userRole: {
        userId,
        actualRole,
        updatedRole
      }
    });
  };

  /**
   * @description Method is called on clicking "SAVE" button.
   * This method transforms the object that is maintained in the store
   * into a form that the backend accepts.
   * And then fires the saga call to update those roles.
   *
   * Since calling "onUpdateUserRoles" shows a default alert message,
   * it is overridden by passing custom success message on adding users.
   */
  addUsers = () => {
    const updateRolesRequest = {
      userRoles: []
    };
    const {
      user: { modifiedRoles },
      adminPageData
    } = this.props;
    const userIds = Object.keys(modifiedRoles);
    userIds.forEach(id => {
      const userIdAsNumber = Number(id);
      updateRolesRequest.userRoles.push({
        userId: userIdAsNumber,
        role: modifiedRoles[id].updatedRole
      });
    });

    this.setState({ searchTerm: '' }, () => {
      const addUsers = true; // a flag to identify that this "onUpdateUserRoles" is called from AddUsers modal
      this.props.onUpdateUserRoles({
        updateRolesRequest, // roles data to be updated
        fetchSuperUserList: adminPageData, // sending admin's pageData to reload the page after cosing the modal
        addUsers
      });
    });
  };

  /**
   * @description
   * For the received search results which is of type "array", it is passed into this method
   * to transform the data into corresponding UI element "table-row"
   * A row in the table is made of 5 different types of columns. Each different cell's UI is
   * created here, grouped together as an array.
   * @returns Returns an array of UI component that represents the "table-row".
   */
  generateUserTableData = data => {
    const { sharedAccessSignature } = this.props.common.sasToken;
    const { modifiedRoles } = this.props.user;

    return data.map(row => {
      const {
        id,
        name,
        imageUrl,
        currentRole,
        unitName,
        hospitalName,
        divisionName,
        role
      } = row;
      let renderImage = imageUrl;
      // imageUrl value is not null
      if (renderImage) {
        // Appending SAS token
        renderImage = `${renderImage}?${sharedAccessSignature}`;
      }

      const currentRoleValue = modifiedRoles[id]
        ? modifiedRoles[id].updatedRole
        : role;

      const tableRow = [
        <UserDetails
          name={name}
          imageUrl={renderImage}
          currentRole={currentRole}
        />,
        <div className="table-data">{replaceWithNA(unitName)}</div>,
        <div className="table-data">{replaceWithNA(hospitalName)}</div>,
        <div className="table-data">{replaceWithNA(divisionName)}</div>,
        this.renderUserRolesDropdown(id, currentRoleValue)
      ];

      return tableRow;
    });
  };

  /**
   * @description Renders the table in UI with search results which
   * contains the list of users whose names matched the search-term.
   *
   * @param {array} list - The data obtained for the search term.
   */
  renderUsers = list => {
    const { searchDataLoaded, isInitialState } = this.props.user;
    const { tableHeader } = CONSTANTS.ADMIN_AND_MODERATORS.addUser;
    const tableData = this.generateUserTableData(list);

    if (searchDataLoaded && list.length === 0) {
      if (isInitialState) {
        return <div />;
      }
      return (
        <h2 className="data-not-found">
          {CONSTANTS.ADMIN_AND_MODERATORS.emptySearchResult}
        </h2>
      );
    }

    return <PaginatedTable headers={tableHeader} data={tableData} />;
  };

  /**
   * @description
   * For the pagination buttons to be displayed, either the search term should be present
   * or the table should have some data to be shown in the UI.
   * This method checks both the conditions and returns a boolean value to decide
   * whether to show the pagination buttons or not.
   *
   * @returns {boolean} True - if search term & search results are available.
   * @returns {boolean} False - no search term or search results
   */
  showPaginationButtons = () => {
    const { searchTerm } = this.state;
    const {
      user: { list = [] }
    } = this.props;
    return searchTerm.length > 2 && list.length > 0;
  };

  /**
   * @description Renders a dropdown for roles (User, Admin, Moderator) in every row.
   * Method gets called for every row in the table.
   *
   * @param {number} UserId - User id of each user obtained from the search result
   * @param {enum} role - Enum of roles which can either be one of the following:
   *  1 (User) , 2 (Moderator), 3 (Administrator)
   */
  renderUserRolesDropdown = (userId, role) => {
    const { rolesDropdownOptions: roles } = CONSTANTS.ADMIN_AND_MODERATORS;
    const options = [roles.MODERATOR, roles.ADMINISTRATOR];
    const selectedOption =
      options.find(option => option.value === role) || roles.DEFAULT;
    return (
      <Dropdown
        options={options}
        selectedOption={selectedOption}
        onChange={updatedRole => {
          this.onUserRoleChanged(updatedRole.value, userId);
        }}
      />
    );
  };

  /**
   * @description Renders pagination buttons based on the pageData passed into it.
   * @param {Object} pageData - has information about current page, current page Size,
   * total pages and total number of entries.
   */
  renderPaginationButtons = pageData => {
    const { page: currentPage, totalPages } = pageData;

    return (
      <PaginatedButton
        prevClick={this.onTablePageChange}
        nextClick={this.onTablePageChange}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    );
  };

  render() {
    const {
      showModal,
      user: { list = [], page, modifiedRoles }
    } = this.props;

    const { title } = CONSTANTS.ADMIN_AND_MODERATORS.addUser;
    const { save: saveBtnText } = CONSTANTS.COMMON;
    const { searchTerm = '' } = this.state;

    const isSaveBtnDisabled = Object.keys(modifiedRoles).length === 0;
    const saveBtnCss = cx([
      'btn',
      'size-large',
      isSaveBtnDisabled ? 'btn-primary-disabled' : 'btn-primary'
    ]);
    return (
      <Modal
        show={showModal}
        modalClosed={this.closeModal}
        title={title}
        customClasses="modal-large modal-gray"
      >
        <section className="modal-body">
          <SearchBar
            onChange={this.searchUsers}
            onKeyUp={this.onKeyUp}
            customClass="search-bar-large"
            value={searchTerm}
          />
          <div className="add-users-table-wrapper">
            {!this.props.user.searchDataLoaded && searchTerm.length > 2 && (
              <Loader alt={CONSTANTS.COMMON.LOADING} />
            )}
            {this.renderUsers(list)}
          </div>
          <footer className="add-users-footer">
            {this.showPaginationButtons() && this.renderPaginationButtons(page)}
            <Button
              value={saveBtnText}
              onClick={this.addUsers}
              disabled={isSaveBtnDisabled}
              customClass={saveBtnCss}
            />
          </footer>
        </section>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.admin.user,
  common: state.common
});

const mapDispatchToProps = dispatch => ({
  onFetchUserList: data => dispatch(getUserList(data)),
  onClearUserList: () => dispatch(clearUserList()),
  onUserRoleChanged: data => dispatch(trackUserRoleChanges(data)),
  onUpdateUserRoles: data => dispatch(updateUserRoles(data)),
  onModalClosed: () => dispatch(hideAddUsersModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUsers);
