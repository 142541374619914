import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from '../../common';

import CONSTANTS from '../../constants';
import ENUM from '../../enum';

import { renderTimeForAction } from '../../utils/utils';

import allowIcon from '../../assets/images/allow-icon.svg';
import removeIcon from '../../assets/images/remove-icon.svg';

import './AllowRemoveAction.scss';

/**
 * @description
 * This method is used to render allow/remove button.
 *
 * This method will render approve if post/comment is approved else allow/remove button.
 * If there are no reporter's then only remove button will be rendered.
 *
 * @param {Object} props contains props
 */
const AllowRemoveAction = props => {
  const { ALLOW, REMOVE } = CONSTANTS.COMMON;

  const {
    onAllowClicked,
    onRemoveClicked,
    hasReporter,
    customClass,
    fromCard,
    action
  } = props;

  // Wrapper class css
  const actionClasses = cx([
    'action-button-panel',
    `${customClass}`,
    { 'card-view-action': fromCard }
  ]);

  // Button css
  const btnClasses = cx([
    'btn-empty',
    'btn-actions',
    { 'w-50': hasReporter },
    { 'w-100': !hasReporter }
  ]);

  let isRemovedFeed = false;
  let isAllowedFeed = false;
  let AllowRemoveContent = null;

  // If action is already taken.
  if (action) {
    const { status, actionBy, actionTime } = action;

    // Adding class to add styles.
    const statusClasses = cx([
      'action-status',
      'w-100',
      { 'action-status-card': fromCard },
      { 'allowed-status': status === ENUM.STATUS.ALLOWED },
      { 'removed-status': status === ENUM.STATUS.REMOVED }
    ]);

    // Get time in displaying format
    const time = renderTimeForAction(actionTime);

    // check if it is a removed feed.
    if (status === ENUM.STATUS.REMOVED) {
      isRemovedFeed = true;
    }

    // check if it is a allowed feed.
    if (status === ENUM.STATUS.ALLOWED) {
      isAllowedFeed = true;
    }

    // Message to be rendered if post has action.
    const message = `${
      CONSTANTS.COMMON.STATUS_MESSAGE[status]
    } ${actionBy} on ${time}`;
    AllowRemoveContent = <div className={statusClasses}>{message}</div>;
  }

  return (
    <div className={actionClasses}>
      {AllowRemoveContent}
      {!isRemovedFeed && !isAllowedFeed && hasReporter && (
        <div>
          <Fragment>
            <Button
              value={ALLOW}
              iconPath={allowIcon}
              iconHeight={CONSTANTS.COMMON.ACTION_ICON_SIZE}
              iconWidth={CONSTANTS.COMMON.ACTION_ICON_SIZE}
              customClass={btnClasses}
              onClick={onAllowClicked}
            />
            <div className="separator-line" />
          </Fragment>
          <Button
            value={REMOVE}
            iconPath={removeIcon}
            iconHeight={CONSTANTS.COMMON.ACTION_ICON_SIZE}
            iconWidth={CONSTANTS.COMMON.ACTION_ICON_SIZE}
            customClass={btnClasses}
            onClick={onRemoveClicked}
          />
        </div>
      )}
    </div>
  );
};

AllowRemoveAction.defaultProps = {
  action: null,
  customClass: '',
  fromCard: false,
  hasReporter: true
};

AllowRemoveAction.propTypes = {
  onRemoveClicked: PropTypes.func.isRequired,
  onAllowClicked: PropTypes.func.isRequired,
  action: PropTypes.object,
  customClass: PropTypes.string,
  fromCard: PropTypes.bool,
  hasReporter: PropTypes.bool
};

export default AllowRemoveAction;
