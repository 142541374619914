import { getAccessToken } from '../services/AuthService';
import { ACTIONS } from '../redux/actions/actionType';
/**
 * @description
 * This method is check if Access token is available
 */

const checkAccessTokenMiddleWare = () => next => action => {
  // Let the action through if the type is GET_ACCESS_TOKEN_CALL_SUCCESS
  if (action.type === ACTIONS.AUTH.GET_ACCESS_TOKEN_CALL_SUCCESS) {
    return next(action);
  }
  const accessToken = getAccessToken();

  // If the access token is present then let the action through
  if (accessToken) return next(action);

  // Return null if the token is not present and let the redirect logic on Main Layout Route kick in
  return null;
};

export default checkAccessTokenMiddleWare;
