import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ActionModal,
  Header,
  ViewBy,
  TableView,
  CardView
} from '../../components';
import {
  Alert,
  Loader,
  RoundedDropDown,
  SubHeader,
  WarningModal
} from '../../common';

import { setTitle } from '../../utils/utils';

import CONSTANTS from '../../constants';
import {
  clearUserProfile,
  fetchUserProfileTask,
  getUserPersonalDetails
} from '../../redux/actions/Search.actions';
import {
  setAlertMessage,
  fetchUserDetails,
  dismissWarningModal,
  fetchRemovalReasons
} from '../../redux/actions/Common.actions';

import './UserProfile.scss';

export class UserProfile extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    common: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onClearUserProfile: PropTypes.func.isRequired,
    onFetchUserProfileTask: PropTypes.func.isRequired,
    onFetchUserDetails: PropTypes.func.isRequired,
    getUserPersonalDetailsById: PropTypes.func.isRequired,
    onFetchRemovalReasons: PropTypes.func.isRequired,
    onWarningModalDismiss: PropTypes.func.isRequired,
    onSetAlertMessage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    viewCard: true,
    filterBy: CONSTANTS.COMMON.FILTER_BY_OPTIONS.MOST_RECENT
  };

  componentDidMount() {
    setTitle(CONSTANTS.COMMON.PAGE_TITLE.USER_PROFILE);
    this.fetchUserData();
    this.props.onFetchRemovalReasons();
  }

  componentWillUnmount() {
    this.props.onClearUserProfile();
  }

  /**
   * @description
   * This method is used to create fetch params from which we can fetch user's task list.
   *
   * @returns {Object} This object contains, size, sort and userID whose data has to be fetched,
   */
  fetchDataQuery = page => {
    // Add "page" which you'll get from infinite scroll.
    const {
      filterBy: { value: sort }
    } = this.state;
    const {
      match: {
        params: { userId }
      }
    } = this.props;
    const { PAGE_SIZE: size } = CONSTANTS.SEARCH.USER_PROFILE;
    return { userId, size, sort, page };
  };

  /**
   * @description
   * This method is used to do API call based on query params
   */
  fetchUserData = (page = 0) => {
    // Do API call here. take page with default value 0. pass it to fetchDataQuery. After infinite scroll.
    const data = this.fetchDataQuery(page);
    // To get user's personal details
    this.props.getUserPersonalDetailsById({ userId: data.userId });
    // To get user's post
    this.props.onFetchUserProfileTask(data);
  };

  // This method updated filterBy state. On click of drop down.
  onChangeFilterBy = filterBy => {
    this.props.onClearUserProfile();
    this.setState({ filterBy }, () => {
      // After updating filter by option, do an API call.
      this.fetchUserData();
    });
  };

  // Method that toggle view to card view to table view or vice versa. When clicked on icons
  viewToggle = viewCard => {
    this.setState({ viewCard });
  };

  // Reset alert message after dismissal
  resetAlertMessage = () => {
    this.props.onSetAlertMessage(null);
  };

  /**
   * @description
   * Checks if current page number has reached total page .
   *
   * @returns
   * This function return true if current page number and total page number are different.
   * Else this will return false.
   */
  hasMorePages = () => {
    const {
      userProfilePage: { number, totalPages }
    } = this.props.search;
    return number < totalPages - 1;
  };

  /**
   * @description
   * This method when called, dispatches an action to the reducer with some data
   * which opens the "ActionsModal".
   * This method is passed as a property to the UI Component TableView.js and is wired with
   * "Allow" button's onClick property.
   * It has a boolean flag `isAllowAction` which represents the context that the "ActionsModal"
   * is launched to allow the post message.
   *
   * @param {object} feedData An object that represents the properties of a row:
   * Some properties which this function is concerned about:
   * `senderId` - The user who initially posted that message.
   * `itemType` - Determines whether it is a comment or a post
   * `id` - Unique Id representing a post
   * `imageUrl` - Url for profile pic of the sender with SAS token appended to it
   */
  openAllowAction = feedData => {
    const isAllowAction = true;
    this.props.onFetchUserDetails({
      isAllowAction,
      ...feedData
    });
  };

  /**
   * @description
   * This method when called, dispatches an action to the reducer with some data
   * which opens the "ActionsModal".
   * This method is passed as a property to the UI Component TableView.js and is wired with
   * "Remove" button's onClick property.
   *
   * @params {object} An object representing the table's row data which only has the following props:
   * `senderId` The user who initially posted that message.
   * `imageUrl` user profile picture icon's url
   * `itemType` Determines whether it is a comment or a post
   */
  openRemoveAction = feedData => {
    const isAllowAction = false;
    this.props.onFetchUserDetails({
      isAllowAction,
      ...feedData
    });
  };

  // This method is used to render card view when viewCard state is set to true.
  renderCardView = (
    userProfileList,
    hasMore,
    nextPage,
    sasToken,
    isEmojiSupported
  ) => (
    <CardView
      data={userProfileList}
      fetchMore={page => this.fetchUserData(page)}
      hasMore={hasMore}
      nextPage={nextPage}
      sasToken={sasToken}
      allowAction={this.openAllowAction}
      removeAction={this.openRemoveAction}
      isEmojiSupported={isEmojiSupported}
      onUserDetailsClicked={this.navigateToUserProfile}
    />
  );

  navigateToUserProfile = userId => {
    this.props.history.push(`/profile/${userId}`);
  };

  // This method is used to render table view when viewCard state is set to false.
  renderTableView = (
    userProfileList,
    hasMore,
    nextPage,
    sasToken,
    isEmojiSupported
  ) => (
    <TableView
      data={userProfileList}
      fetchMore={page => this.fetchUserData(page)}
      hasMore={hasMore}
      nextPage={nextPage}
      sasToken={sasToken}
      allowAction={this.openAllowAction}
      removeAction={this.openRemoveAction}
      isEmojiSupported={isEmojiSupported}
      onUserDetailsClicked={this.navigateToUserProfile}
    />
  );

  /**
   * @description
   * This method is used to render content. If data is present it renders table or card view.
   *
   * @returns {ReactComponent} content of page, either table or card view, if data is there else no data warning.
   */
  renderContent = (userProfileList, page) => {
    const { viewCard } = this.state;
    const {
      isEmojiSupported,
      sasToken: { sharedAccessSignature: SASToken }
    } = this.props.common;
    const { NO_DATA_FOUND } = CONSTANTS.SEARCH.USER_PROFILE;
    const hasMore = this.hasMorePages();
    const nextPage = page + 1;

    if (userProfileList.length === 0) {
      return <h1 className="data-not-found">{NO_DATA_FOUND}</h1>;
    }

    // Logic for table view and card view.
    if (viewCard) {
      return this.renderCardView(
        userProfileList,
        hasMore,
        nextPage,
        SASToken,
        isEmojiSupported
      );
    }
    // Table view
    return this.renderTableView(
      userProfileList,
      hasMore,
      nextPage,
      SASToken,
      isEmojiSupported
    );
  };

  render() {
    const { filterBy, viewCard } = this.state;
    const {
      userProfileList,
      userDetails,
      initialUserProfileDataLoaded,
      userProfilePage: { number: page }
    } = this.props.search;

    const {
      actionModal: { show },
      warningModal: { show: showWarningModal, message: warningMessage },
      alertMessage
    } = this.props.common;

    // Filter drop down
    const {
      FILTER_BY_OPTIONS,
      WARNING_MODAL: { TITLE: warningTitle }
    } = CONSTANTS.COMMON;
    const dropDownOption = Object.values(FILTER_BY_OPTIONS);

    const { onWarningModalDismiss } = this.props;

    // show loader until data is loaded.
    if (!initialUserProfileDataLoaded || !userDetails) {
      return <Loader />;
    }

    // User name as title
    const { name: title } = userDetails;

    return (
      <div className="container user-profile">
        {alertMessage && (
          <Alert message={alertMessage} onDismiss={this.resetAlertMessage} />
        )}
        <ActionModal showModal={show} />
        <WarningModal
          showModal={showWarningModal}
          title={warningTitle}
          message={warningMessage}
          onDismiss={onWarningModalDismiss}
        />
        <Header title={title}>
          <RoundedDropDown
            options={dropDownOption}
            selectedOption={filterBy}
            onChange={this.onChangeFilterBy}
          />
          <ViewBy onClick={this.viewToggle} viewCard={viewCard} />
        </Header>
        <div className="personal-task-list">
          <SubHeader userDetail={userDetails} />
          {this.renderContent(userProfileList, page)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  search: state.search,
  common: state.common
});

const mapDispatchToProps = dispatch => ({
  onClearUserProfile: () => dispatch(clearUserProfile()),
  onFetchUserProfileTask: data => dispatch(fetchUserProfileTask(data)),
  onFetchUserDetails: data => dispatch(fetchUserDetails(data)),
  getUserPersonalDetailsById: data => dispatch(getUserPersonalDetails(data)),
  onFetchRemovalReasons: () => dispatch(fetchRemovalReasons()),
  onSetAlertMessage: data => dispatch(setAlertMessage(data)),
  onWarningModalDismiss: () => dispatch(dismissWarningModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfile));
