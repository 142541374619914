import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { debounce } from 'lodash';

import {
  Button,
  Dropdown,
  Loader,
  SearchBar,
  PaginatedTable,
  UserDetails,
  PaginatedButton,
  Alert,
  SvgImage
} from '../../common';

import CONSTANTS from '../../constants';

import {
  getSuperUserList,
  clearSuperUserList,
  deleteSuperUser,
  updateUserRoles,
  trackUserRoleChanges,
  showAddUsersModal
} from '../../redux/actions/ModeratorList.actions';
import { setAlertMessage } from '../../redux/actions/Common.actions';

import {
  mapEnumToRole,
  replaceWithNA,
  toggleBodyScroll,
  setTitle
} from '../../utils/utils';
import RemoveUsersModal from './RemoveUsers/RemoveUsers';
import AddUsersModal from './AddUsers/AddUsers';
import { ACTIONS } from '../../redux/actions/actionType';
import DeleteIcon from '../../assets/images/icn_delete.svg';
import plusIcon from '../../assets/images/plus-icon.svg';
import './ModeratorList.scss';

export class ModeratorList extends Component {
  static propTypes = {
    admin: PropTypes.object.isRequired,
    common: PropTypes.object.isRequired,
    onFetchSuperUserList: PropTypes.func.isRequired,
    onClearSuperUserList: PropTypes.func.isRequired,
    onDeleteUser: PropTypes.func.isRequired,
    onSetAlertMessage: PropTypes.func.isRequired,
    onUpdateUserRoles: PropTypes.func.isRequired,
    onUserRoleChanged: PropTypes.func.isRequired,
    onAddUsersModalOpen: PropTypes.func.isRequired
  };

  state = {
    searchTerm: '',
    showDeleteModal: false,
    deleteUserDetails: {}
  };

  searchModeratorAndAdmins = debounce(() => {
    // this will return value for each keystrokes.
    this.fetchSuperUserListHandler();
  }, 1000);

  // Fetching initialing data
  componentDidMount() {
    setTitle(CONSTANTS.COMMON.PAGE_TITLE.MODERATOR_AND_ADMINS);
    this.fetchSuperUserListHandler();
  }

  // Check if add modal or delete modal is open. If open then disable body scroll.
  componentDidUpdate() {
    toggleBodyScroll(
      this.props.admin.showAddUserModal || this.state.showDeleteModal
    );
  }

  componentWillUnmount() {
    this.props.onClearSuperUserList();
  }

  /**
   * @description Always returns the current page number, total page size,
   * the search term specified (if any) & logged in user's role as an object
   */
  getCurrentPageData = () => {
    const { pageSize: size } = CONSTANTS.ADMIN_AND_MODERATORS;
    const { page } = this.props.admin.superAdminPage;

    const searchTerm = this.state.searchTerm.trim();
    const sort = 'lastName,asc';
    return { role: 4, page, size, searchTerm, sort };
  };

  onSearchChanged = searchTerm => {
    this.setState({ searchTerm });
  };

  fetchSuperUserListHandler = (page = 0) => {
    const data = this.getCurrentPageData();
    data.page = page;
    this.props.onFetchSuperUserList(data);
  };

  /**
   * When admin clicks on trash icon, we get user's id and name.
   * Then we store this in deleteUserDetails state.
   * Modal will appear, once clicked by setting showDeleteModal to true
   */
  onRemoveButtonClicked = (id, name) => {
    this.setState({ deleteUserDetails: { id, name }, showDeleteModal: true });
  };

  /**
   * This method will close modal, once the user has been deleted. deleteUserDetails will be reset to {}.
   * After that it will dispatch call to delete(API).
   */
  removeUsers = () => {
    const { deleteUserDetails } = this.state;
    this.setState({ showDeleteModal: false, deleteUserDetails: {} }, () => {
      const fetchUserAction = this.getCurrentPageData();
      this.props.onDeleteUser({
        deleteAction: deleteUserDetails,
        fetchUserAction
      });
    });
  };

  // Reset alert message after dismissal
  resetAlertMessage = () => {
    this.props.onSetAlertMessage(null);
  };

  /**
   * @description
   * This function takes a UTC time value either as a moment object or a string
   * and returns a UI component with two rows containing date and time respectively.
   *
   * @param {String | momentTime} time
   */
  renderDateAndTime = time => {
    if (!time) {
      return 'N/A';
    }

    const { DATE_FORMAT_DOTTED, TIME_FORMAT_HHMMA } = CONSTANTS.COMMON;
    return (
      <Fragment>
        <div>{moment(time).format(DATE_FORMAT_DOTTED)}</div>
        <div>{moment(time).format(TIME_FORMAT_HHMMA)}</div>
      </Fragment>
    );
  };

  /**
   * @description This method gets called when admin modifies any other user's role
   * @param actualRole
   * @param updatedRole
   * @param userId - the user id for whom the role is modified
   *
   */
  onRoleChanged = (updatedRole, userId) => {
    // updating props with selected role;
    const { superAdminList } = this.props.admin;
    const user = superAdminList.find(({ id }) => id === userId);
    const actualRole = user.role;
    const { modifiedRoles } = this.props.admin;
    const { rolesDropdownOptions: roles } = CONSTANTS.ADMIN_AND_MODERATORS;
    const options = [roles.MODERATOR, roles.ADMINISTRATOR];
    // const selectedOption = options.find(option => option.text === role);
    const updatedRoleId = options.filter(
      option => option.text === updatedRole
    )[0].value;

    const {
      UPDATE_SUPER_USER_ROLES_MODIFIED: actionType
    } = ACTIONS.ADMIN_MODERATOR;
    this.props.onUserRoleChanged({
      actionType,
      modifiedRoles,
      userRole: {
        userId,
        actualRole,
        updatedRole,
        updatedRoleId
      }
    });
  };

  /**
   * @description Method is called on clicking "SAVE CHANGES" button.
   * This method transforms the object that is maintained in this component's state
   * into a form that the backend accepts.
   * And then fires the saga call to update those roles.
   */
  updateRoles = () => {
    const updateRolesRequest = {
      userRoles: []
    };
    const { modifiedRoles } = this.props.admin;
    const userIds = Object.keys(modifiedRoles);

    userIds.forEach(id => {
      const userIdAsNumber = Number(id);
      updateRolesRequest.userRoles.push({
        userId: userIdAsNumber,
        role: modifiedRoles[id].updatedRoleId
      });
    });

    const fetchSuperUserList = this.getCurrentPageData();
    this.props.onUpdateUserRoles({
      updateRolesRequest, // roles data to be updated
      fetchSuperUserList // sending pageData to reload the page
    });
  };

  renderRolesDropdown = (userId, role) => {
    const { rolesDropdownOptions: roles } = CONSTANTS.ADMIN_AND_MODERATORS;
    const options = [roles.MODERATOR, roles.ADMINISTRATOR];
    const selectedOption = options.find(option => option.text === role);

    return (
      <Dropdown
        options={options}
        selectedOption={selectedOption}
        onChange={updatedRole => {
          this.onRoleChanged(updatedRole.text, userId);
        }}
      />
    );
  };

  renderDeleteIcon = (userId, name) => {
    return (
      <SvgImage
        src={DeleteIcon}
        alt={CONSTANTS.COMMON.delete}
        className="delete-icon"
        role="button"
        onClick={() => this.onRemoveButtonClicked(userId, name)}
      />
    );
  };

  // This is used to format data which which can push to table for rendering
  generateTableData = (data, isAdmin) => {
    const { sharedAccessSignature } = this.props.common.sasToken;
    const { modifiedRoles } = this.props.admin;

    return data.map(row => {
      const {
        id,
        firstName,
        lastName,
        imageUrl,
        currentRole,
        unitName,
        hospitalName,
        divisionName,
        lastLoggedOn,
        role
      } = row;

      const renderImage =
        imageUrl && sharedAccessSignature
          ? `${imageUrl}?${sharedAccessSignature}`
          : null;

      const currentRoleValue = modifiedRoles[id]
        ? modifiedRoles[id].updatedRole
        : role;

      const tableRow = [
        <UserDetails
          name={`${lastName}, ${firstName}`}
          imageUrl={renderImage}
          currentRole={currentRole}
        />,
        <div className="table-data">{replaceWithNA(unitName)}</div>,
        <div className="table-data">{replaceWithNA(hospitalName)}</div>,
        <div className="table-data">{replaceWithNA(divisionName)}</div>,
        this.renderDateAndTime(lastLoggedOn)
      ];

      /**
       * Based on role, changing the render screen.
       * We append role and delete data.
       */
      if (isAdmin) {
        tableRow.push(
          this.renderRolesDropdown(id, currentRoleValue),
          this.renderDeleteIcon(id, `${firstName} ${lastName}`)
        );
      } else {
        tableRow.push(mapEnumToRole(role), '');
      }

      return tableRow;
    });
  };

  // This function checks if data is there or not based on which table is displayed.
  renderData = (tableHeader, data, isAdmin) => {
    const { superUserSearchLoaded } = this.props.admin;
    // Render no data message if data is not found
    if (superUserSearchLoaded) {
      if (data.length === 0) {
        return (
          <h1 className="data-not-found">
            {CONSTANTS.ADMIN_AND_MODERATORS.emptySearchResult}
          </h1>
        );
      }
      // Function that returns table data.
      const tableData = this.generateTableData(data, isAdmin);
      return <PaginatedTable headers={tableHeader} data={tableData} />;
    }
    return null;
  };

  // Page navigation used to call
  onPageChange = page => {
    this.fetchSuperUserListHandler(page);
  };

  renderRemoveUsersModal = () => {
    const { showDeleteModal } = this.state;
    const { name = '' } = this.state.deleteUserDetails;
    const closeModal = () => this.setState({ showDeleteModal: false });

    return (
      <RemoveUsersModal
        showModal={showDeleteModal}
        userName={name}
        closeModal={closeModal}
        submitAction={this.removeUsers}
      />
    );
  };

  /**
   * @description This method gets called when "Add user" button is clicked.
   * It opens up a modal, where new users can be added to this moderator tool.
   */
  openAddUserModal = () => {
    this.props.onAddUsersModalOpen();
  };

  /**
   * @description Returns the JSX for "Add users" modal.
   * The modal is shown/hidden based on a flag that is maintained in the store.
   * When this modal is closed by clicking "Save", it will update the changes in the backend
   * and reloading the current page is required.
   *
   * For that purpose, the current page's data is passed to "AddUsersModal" as a prop `adminPageData`
   */
  renderAddUsersModal = () => {
    const { showAddUserModal: showModal } = this.props.admin;
    return (
      <AddUsersModal
        showModal={showModal}
        adminPageData={this.getCurrentPageData()}
      />
    );
  };

  render() {
    // List of all moderators and admins
    const {
      superAdminList,
      superAdminPage,
      initialDataLoaded,
      superUserSearchLoaded,
      modifiedRoles
    } = this.props.admin;

    // Alert message
    const { alertMessage, userRole } = this.props.common;
    const { page: currentPage, totalPages } = superAdminPage;

    // Table data and title.
    const {
      title,
      tableHeader,
      rolesDropdownOptions: roles,
      addUser: { title: addUserTitle }
    } = CONSTANTS.ADMIN_AND_MODERATORS;
    const { LOADING } = CONSTANTS.COMMON;

    // Data check pre-loader
    if (!initialDataLoaded) {
      return <Loader alt={LOADING} />;
    }

    // Checks if user is admin or not, and also type casting to integer.
    const isAdmin = +roles.ADMINISTRATOR.value === userRole.role;

    // Fetch table or warning message for no data
    const renderData = this.renderData(tableHeader, superAdminList, isAdmin);

    // Save button status
    const isSaveBtnDisabled = Object.keys(modifiedRoles).length === 0;
    const saveBtnCss = cx([
      'btn',
      isSaveBtnDisabled ? 'btn-primary-disabled' : 'btn-primary'
    ]);

    const { searchTerm = '' } = this.state;

    return (
      <div className="admin-moderate container">
        {this.renderRemoveUsersModal()}
        {this.renderAddUsersModal()}
        {alertMessage && (
          <Alert message={alertMessage} onDismiss={this.resetAlertMessage} />
        )}
        <header>
          <h1>{title}</h1>
        </header>
        <main>
          <SearchBar
            onChange={this.onSearchChanged}
            onKeyUp={this.searchModeratorAndAdmins}
            customClass="search-bar"
            value={searchTerm}
          />
          {/* Render "save" & "add user" button only if user is an admin */}
          {isAdmin && (
            <div
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              lassName="btn-container"
            >
              <Button
                customClass="btn btn-secondary"
                value={addUserTitle}
                onClick={this.openAddUserModal}
                iconPath={plusIcon}
              />
              <Button
                customClass={saveBtnCss}
                value={CONSTANTS.COMMON.saveChanges}
                onClick={this.updateRoles}
                disabled={isSaveBtnDisabled}
              />
            </div>
          )}
          {!superUserSearchLoaded && <Loader alt={CONSTANTS.COMMON.LOADING} />}
          {renderData}
        </main>
        {/* Condition render footer only if table has data */}
        {superAdminList.length !== 0 && (
          <footer>
            <PaginatedButton
              prevClick={this.onPageChange}
              nextClick={this.onPageChange}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          </footer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  admin: state.admin,
  common: state.common
});

const mapDispatchToProps = dispatch => ({
  onFetchSuperUserList: data => dispatch(getSuperUserList(data)),
  onClearSuperUserList: () => dispatch(clearSuperUserList()),
  onDeleteUser: userID => dispatch(deleteSuperUser(userID)),
  onSetAlertMessage: data => dispatch(setAlertMessage(data)),
  onUpdateUserRoles: data => dispatch(updateUserRoles(data)),
  onUserRoleChanged: data => dispatch(trackUserRoleChanges(data)),
  onAddUsersModalOpen: () => dispatch(showAddUsersModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModeratorList);
