import axios from 'axios';
import moment from 'moment';
import { getAccessToken, logout } from '../services/AuthService';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const setSessionHeader = () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    // empty buffered sessionId if cookie is cleared
    delete axios.defaults.headers.common.Authorization;
  }
};

const _ajax = ({ method, url, data = {} }) => {
  setSessionHeader();
  return axios({ method, url, data })
    .then(response => response)
    .catch(error => error.response);
};

const postData = (url, data) => _ajax({ method: 'post', url, data });

const getData = (url, data) => _ajax({ method: 'get', url, data });

const putData = (url, data) => _ajax({ method: 'put', url, data });

const deleteData = (url, data) => _ajax({ method: 'delete', url, data });

const patchData = (url, data) => _ajax({ method: 'patch', url, data });

const downloadFile = url =>
  axios.request({
    url,
    method: 'get',
    responseType: 'blob'
  });

function handleAPIError(error) {
  const { status } = error.response;
  if (status === 403 || status === 401) {
    localStorage.setItem('unauthorized', true);
    logout();
  } else {
    return Promise.reject(error);
  }
  return false;
}

axios.interceptors.response.use(response => {
  const {
    headers: { date }
  } = response;

  // Record the time difference between the system and server on all api requests
  if (date)
    localStorage.setItem('timeDiff', moment(date).diff(moment().valueOf()));

  return response;
}, handleAPIError);

export { getData, postData, putData, deleteData, patchData, downloadFile };
