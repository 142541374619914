// Importing action file
import { ACTIONS } from '../actions/actionType';
import ENUM from '../../enum';

/**
 * @description
 * This method will return empty array if it's allowed type else return reportedBy
 *
 * @param {Array} reportedBy
 * @param {Number} status
 *
 * @returns {Array} reportedBy
 */
const updateIfAllow = (reportedBy, status) => {
  if (status === ENUM.STATUS.ALLOWED) {
    return [];
  }
  return reportedBy;
};

const initialState = {
  searchResultsLoaded: true,
  isInitialState: true,
  searchUserList: [],
  searchUserPage: {
    page: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  },
  userProfileList: [],
  userProfilePage: {
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  },
  userDetails: null,
  initialUserProfileDataLoaded: false
};

const Search = (state = initialState, action) => {
  const tempState = { ...state };
  switch (action.type) {
    case ACTIONS.SEARCH.FETCH_SEARCHED_USER:
      tempState.searchResultsLoaded = false;
      tempState.isInitialState = false;
      return tempState;
    case ACTIONS.SEARCH.FETCH_SEARCHED_USER_SUCCESS: {
      tempState.searchResultsLoaded = true;
      tempState.searchUserList = [...action.data._embedded.resources];
      tempState.searchUserPage = { ...action.data.page };
      return tempState;
    }
    case ACTIONS.SEARCH.FETCH_SEARCHED_USER_FAIL: {
      tempState.searchResultsLoaded = true;
      tempState.searchUserList = [...initialState.searchUserList];
      tempState.searchUserPage = { ...initialState.searchUserPage };
      return tempState;
    }
    case ACTIONS.SEARCH.CLEAR_SEARCHED_USER: {
      tempState.isInitialState = true;
      tempState.searchUserList = [...initialState.searchUserList];
      tempState.searchUserPage = { ...initialState.searchUserPage };
      return tempState;
    }
    case ACTIONS.SEARCH.LOAD_MORE_SEARCHED_USER_SUCCESS: {
      tempState.searchUserList = [
        ...tempState.searchUserList,
        ...action.data._embedded.resources
      ];
      tempState.searchUserPage = { ...action.data.page };
      return tempState;
    }
    case ACTIONS.SEARCH.FETCH_USER_PROFILE_SUCCESS: {
      tempState.userProfileList = [
        ...tempState.userProfileList,
        ...action.data._embedded.resources
      ];
      tempState.userProfilePage = { ...action.data.page };
      tempState.initialUserProfileDataLoaded = true;
      return tempState;
    }
    case ACTIONS.SEARCH.FETCH_USER_PROFILE_FAIL: {
      tempState.userProfileList = [...initialState.userProfileList];
      tempState.userProfilePage = { ...initialState.userProfilePage };
      tempState.initialUserProfileDataLoaded = true;
      return tempState;
    }
    case ACTIONS.SEARCH.CLEAR_USER_PROFILE: {
      tempState.userProfileList = [...initialState.userProfileList];
      tempState.userProfilePage = { ...initialState.userProfilePage };
      tempState.userDetails = null;
      tempState.initialUserProfileDataLoaded = false;
      return tempState;
    }
    case ACTIONS.SEARCH.UPDATE_FEED_ACTION: {
      const feedIndex = tempState.userProfileList.findIndex(
        list => list.id === action.id
      );
      const actionFeed = tempState.userProfileList[feedIndex];
      actionFeed.action = { ...action.data };
      actionFeed.reportedBy = updateIfAllow(
        actionFeed.reportedBy,
        action.data.status
      );
      // Replace existing object from the list then replace it with updated one.
      tempState.userProfileList.splice(feedIndex, 1, actionFeed);
      return tempState;
    }
    case ACTIONS.SEARCH.USER_PERSONAL_DETAILS_SUCCESS: {
      tempState.userDetails = { ...action.data };
      return tempState;
    }
    case ACTIONS.SEARCH.USER_PERSONAL_DETAILS_FAIL: {
      tempState.userDetails = null;
      return tempState;
    }
    default:
      return tempState;
  }
};

export default Search;
