import React from 'react';
import PropTypes from 'prop-types';
import ImgWithExif from '../ImgWithExif/ImgWithExif';
import './UserAvatar.scss';

/**
 * @description
 * This method is used to take name and split into words then take first character of individual word and capitalize it. Then join this capitalize word.
 *
 * @param {String} name User's name will be split.
 *
 * @return Uppercase char of individual word, passed as name.
 */
const TrimNameToInitials = name => {
  return name
    .split(' ')
    .map(el => el.charAt(0).toUpperCase())
    .join('');
};

const UserAvatar = props => {
  const { imageUrl, name, ...rest } = props;
  // If image is there
  if (imageUrl) {
    return (
      <div className="user-avatar-wrapper" {...rest}>
        <ImgWithExif src={imageUrl} alt={name} />
      </div>
    );
  }
  // If image is not there, then Create icon with User's name initials.
  return (
    <div className="user-avatar-wrapper round-border" {...rest}>
      {TrimNameToInitials(name)}
    </div>
  );
};

UserAvatar.defaultProps = {
  imageUrl: ''
};

UserAvatar.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired
};

export default UserAvatar;
