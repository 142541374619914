import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { UserDetails, PaginatedButton } from '../../../common';

import { appendSASToken, replaceWithNA } from '../../../utils/utils';

import './SearchTable.scss';

// This method will take individual row data and prepare it's data to be rendered.
const prepareRowData = (data, sasToken, history) => {
  const {
    id,
    firstName,
    lastName,
    currentRole,
    imageUrl,
    emp34,
    unitName,
    hospitalName,
    divisionName,
    postCount,
    reportedCount
  } = data;

  const img = appendSASToken(sasToken, imageUrl);
  return (
    <Table.Row
      key={id}
      onClick={() => history.push(`/profile/${id}`)}
      className="search-table-row"
    >
      <Table.Cell className="table-data">
        <UserDetails
          name={`${lastName}, ${firstName}`}
          currentRole={currentRole}
          imageUrl={img}
        />
      </Table.Cell>
      <Table.Cell className="table-data">{emp34}</Table.Cell>
      <Table.Cell className="table-data">
        <div className="word-wrap">{replaceWithNA(unitName)}</div>
      </Table.Cell>
      <Table.Cell className="table-data">
        <div className="word-wrap">{replaceWithNA(hospitalName)}</div>
      </Table.Cell>
      <Table.Cell className="table-data">
        <div className="word-wrap">{replaceWithNA(divisionName)}</div>
      </Table.Cell>
      <Table.Cell className="table-data">
        <div className="post-status">
          <div>{postCount} Posts</div>
          <div>{reportedCount} Reports</div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
  // }
};

// This method renders the search results in a table view
const renderTable = (
  header,
  data,
  sasToken,
  history,
  currentPage,
  totalPages,
  fetchMore
) => {
  return (
    <Table padded singleLine basic="very" className="search-table">
      <Table.Header className="table-head">
        <Table.Row>
          {header.map((head, index) => (
            <Table.HeaderCell key={index}>{head}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body className="table-body">
        {data.map(row => prepareRowData(row, sasToken, history))}
      </Table.Body>
      <Table.Footer>
        <PaginatedButton
          prevClick={() => fetchMore(currentPage - 1)}
          nextClick={() => fetchMore(currentPage + 1)}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Table.Footer>
    </Table>
  );
};

const SearchTable = props => {
  const {
    data,
    header,
    sasToken,
    history,
    fetchMore,
    currentPage,
    totalPages
  } = props;
  if (data.length === 0) {
    return null;
  }

  return renderTable(
    header,
    data,
    sasToken,
    history,
    currentPage,
    totalPages,
    fetchMore
  );
};

SearchTable.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  sasToken: PropTypes.string.isRequired,
  fetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired
};

export default withRouter(SearchTable);
