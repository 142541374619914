import { constructQueryParams } from '../utils/utils';
import CONSTANTS from '../constants';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_SERVER_TOKEN_URL = process.env.REACT_APP_AUTH_SERVER_TOKEN_URL;
const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;
const OAUTH_REDIRECT_URI = process.env.REACT_APP_OAUTH_REDIRECT_URI;
const SCOPE = process.env.REACT_APP_OAUTH_SCOPE;
const { ACCESS_TOKEN_PARAMS } = CONSTANTS.AUTH;

export const getLoginUrl = () => {
  return `${AUTH_SERVER_TOKEN_URL}?${constructQueryParams({
    ...ACCESS_TOKEN_PARAMS,
    client_id: OAUTH_CLIENT_ID,
    redirect_uri: OAUTH_REDIRECT_URI,
    scope: SCOPE
  })}`;
};

export const searchUrl = `${BASE_URL}/cms/search/users`;

export const divisionUrl = `${BASE_URL}/cms/search/divisions`;

export const userUrl = `${BASE_URL}/cms/users`;

export const storageUrl = `${BASE_URL}/cms/storage`;

export const rolesUrl = `${BASE_URL}/cms/users/roles`;

export const superUserUrl = `${BASE_URL}/cms/superusers`;

export const taskListUrl = `${BASE_URL}/cms/task-list`;

export const feedUrl = `${BASE_URL}/cms/feeds`;

export const masterUrl = `${BASE_URL}/cms/master`;

export const commentUrl = `${BASE_URL}/cms/comments`;

export const generateReportUrl = `${BASE_URL}/cms/task-list/summary`;

export const downloadReportUrl = `${BASE_URL}/cms/task-list/detailed`;
