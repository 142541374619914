import { ACTIONS } from './actionType';

const setLastLoggedOn = () => {
  return {
    type: ACTIONS.AUTH.SET_LAST_LOGGED_ON
  };
};

const fetchAccessTokenSuccess = data => {
  return {
    type: ACTIONS.AUTH.GET_ACCESS_TOKEN_CALL_SUCCESS,
    data
  };
};

const fetchAccessTokenFailure = data => {
  return {
    type: ACTIONS.AUTH.GET_ACCESS_TOKEN_CALL_FAIL,
    data
  };
};

export { setLastLoggedOn, fetchAccessTokenSuccess, fetchAccessTokenFailure };
