import { ACTIONS } from './actionType';

const fetchSearchedUser = data => {
  return {
    type: ACTIONS.SEARCH.FETCH_SEARCHED_USER,
    data
  };
};

const clearSearchUser = () => {
  return {
    type: ACTIONS.SEARCH.CLEAR_SEARCHED_USER
  };
};

const fetchUserProfileTask = data => {
  return {
    type: ACTIONS.SEARCH.FETCH_USER_PROFILE,
    data
  };
};

const clearUserProfile = () => {
  return {
    type: ACTIONS.SEARCH.CLEAR_USER_PROFILE
  };
};

const getUserPersonalDetails = data => {
  return {
    type: ACTIONS.SEARCH.USER_PERSONAL_DETAILS,
    data
  };
};

const loadMoreSearchedUsers = data => {
  return {
    type: ACTIONS.SEARCH.LOAD_MORE_SEARCHED_USER,
    data
  };
};

export {
  fetchSearchedUser,
  clearSearchUser,
  fetchUserProfileTask,
  clearUserProfile,
  getUserPersonalDetails,
  loadMoreSearchedUsers
};
