import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './ReportOptions.scss';
import ENUM from '../../enum';

/**
 * This function renders the header component in report summary page which
 * denotes the selected options.
 * @param {Object} props
 */
const ReportOptions = ({ customClasses, ...props }) => {
  const cssClasses = cx(['report-options-group', customClasses]);
  const showRemovalReason =
    props.removalReason &&
    props.dispositionType.value === ENUM.DISPOSITION_TYPES.REMOVED;

  return (
    <div className={cssClasses}>
      {props.dispositionType && (
        <div className="report-option">{props.dispositionType.text}</div>
      )}
      {showRemovalReason && (
        <div className="report-option">{props.removalReason.text}</div>
      )}
      {props.postsBy && <div className="report-option">{props.postsBy}</div>}
      {props.dateRange && (
        <div className="report-option">{props.dateRange}</div>
      )}
    </div>
  );
};

ReportOptions.propTypes = {
  customClasses: PropTypes.string,
  dispositionType: PropTypes.object.isRequired,
  removalReason: PropTypes.object.isRequired,
  postsBy: PropTypes.string.isRequired,
  dateRange: PropTypes.string.isRequired
};

ReportOptions.defaultProps = {
  customClasses: ''
};

export default ReportOptions;
