import { call, put } from 'redux-saga/effects';
import { constructQueryParams } from '../../utils/utils';
import { getData } from '../../utils/axios';

import { ACTIONS } from '../actions/actionType';
import API from '../../API';

/**
 * @description
 * This method is used to get task list from server. Task list may contain post or comments.
 * itemType enum - FeedItem(1) and Comment(2)
 * feedItemType - APPRECIATION(1), BADGE(2) and HUMBLE_BRAG(3)
 *
 * @param {Object} action contains action object via which event has been triggered.
 * @argument sort createdOn, desc(By Oldest) post and createdOn, asc(Most Recent).
 */
function* fetchTaskList(action) {
  let response;
  try {
    const URL = `${API.taskList}?${constructQueryParams(action.data)}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.TASK_LIST.FETCH_TASK_LIST_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.TASK_LIST.FETCH_TASK_LIST_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.TASK_LIST.FETCH_TASK_LIST_FAIL,
      data: error
    });
  }
}

export { fetchTaskList };
