import { ACTIONS } from './actionType';

const getSuperUserList = data => ({
  type: ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST,
  data
});

const clearSuperUserList = () => ({
  type: ACTIONS.ADMIN_MODERATOR.CLEAR_SUPER_USER_LIST
});

const deleteSuperUser = data => ({
  type: ACTIONS.ADMIN_MODERATOR.DELETE_SUPER_USER,
  data
});

const updateUserRoles = data => ({
  type: ACTIONS.ADMIN_MODERATOR.UPDATE_SUPER_USER_ROLES,
  data
});

/**
 * @description Method gets called everytime when a roles dropdown is changed.
 * This maintains an object in store that keeps track of what rows are "touched",
 * initial role value and the updated value.
 * @param {Object} modifiedRoles - previous state of modified roles
 * @param {Object} userRole - changes made by the user on a single row
 * @returns {Object} - updated state of modified roles with current changes merged into it.
 */
const onUserRoleModified = (modifiedRoles, userRole) => {
  const { userId, actualRole, updatedRole, updatedRoleId } = userRole;

  const existingChange = modifiedRoles[userId];
  if (existingChange && existingChange.actualRole === updatedRole) {
    /**
     * scenario 1: user was an administrator initially,
     * but changed to moderator and now changing back to administrator.
     * So remove that userId.
     */
    const userIds = { ...modifiedRoles };
    delete userIds[userId];
    return userIds;
  }

  // scenario 2: User was an administrator, but changed to moderator or vice versa
  return {
    ...modifiedRoles,
    [userId]: {
      actualRole,
      updatedRole,
      updatedRoleId
    }
  };
};

const trackUserRoleChanges = data => {
  return {
    type: data.actionType,
    modifiedRoles: onUserRoleModified(data.modifiedRoles, data.userRole)
  };
};

const getUserList = data => ({
  type: ACTIONS.ADMIN_MODERATOR.GET_USER_LIST,
  data
});

const clearUserList = () => {
  return {
    type: ACTIONS.ADMIN_MODERATOR.CLEAR_USER_LIST
  };
};

const addUsers = data => ({
  type: ACTIONS.ADMIN_MODERATOR.ADD_USERS,
  data
});

const showAddUsersModal = () => ({
  type: ACTIONS.ADMIN_MODERATOR.SHOW_ADD_USERS_MODAL
});

const hideAddUsersModal = () => ({
  type: ACTIONS.ADMIN_MODERATOR.HIDE_ADD_USERS_MODAL
});

export {
  getSuperUserList,
  clearSuperUserList,
  deleteSuperUser,
  updateUserRoles,
  trackUserRoleChanges,
  getUserList,
  clearUserList,
  addUsers,
  showAddUsersModal,
  hideAddUsersModal
};
