import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import App from './App';

const AppTemplate = (
  <Provider store={store}>
    <App />
  </Provider>
);
ReactDOM.render(AppTemplate, document.getElementById('root'));
