import { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { deleteSession, redirectToLogin } from '../../services/AuthService';
import { resetCommonReducer } from '../../redux/actions/Common.actions';

class Logout extends Component {
  static propTypes = {
    resetCommonStore: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { resetCommonStore } = this.props;
    deleteSession();
    resetCommonStore();
    redirectToLogin();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  resetCommonStore: () => dispatch(resetCommonReducer())
});

export default connect(
  null,
  mapDispatchToProps
)(Logout);
