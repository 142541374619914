import React from 'react';
import PropTypes from 'prop-types';

import { replaceWithNA } from '../../utils/utils';
import CONSTANTS from '../../constants';

import './SubHeader.scss';

const SubHeader = props => {
  const {
    emp34,
    currentRole,
    // manager,
    postCount,
    commentCount,
    unitName,
    hospitalName
  } = props.userDetail;

  const {
    EMP34,
    /* MANAGER, */ UNIT,
    HOSPITAL
  } = CONSTANTS.COMMON.ACTIONS_MODAL;

  return (
    <div className="sub-header">
      <div className="left-section">
        <div className="emp34">
          <span>{EMP34}</span> {replaceWithNA(emp34)}
        </div>
        <div className="current-role">{replaceWithNA(currentRole)}</div>
        <div className="post-count">
          <span>{postCount}</span> post(s)
        </div>
        <div className="comment-count">
          <span>{commentCount}</span> comment(s)
        </div>
      </div>
      <div className="right-section">
        {/* <div className="manager">
          <span>{MANAGER}</span> {replaceWithNA(manager)}
  </div> */}
        <div className="unit-name">
          <span>{UNIT}</span> {replaceWithNA(unitName)}
        </div>
        <div className="hospitalName">
          <span>{HOSPITAL}</span> {replaceWithNA(hospitalName)}
        </div>
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  userDetail: PropTypes.object.isRequired
};

export default SubHeader;
