import { actionsRequireSASToken, ACTIONS } from '../redux/actions/actionType';
import { getSASToken } from '../redux/actions/Common.actions';
import Config from '../constants';
/**
 * @description
 * This method is used to generate Refresh SAS Token Middleware
 */

function generateRefreshSasTokenMiddleware() {
  const queue = [];
  return store => next => action => {
    // Clear the queue if the action is a sas token success
    if (
      action.type === ACTIONS.COMMON.GET_SAS_TOKEN_SUCCESS ||
      action.type === ACTIONS.COMMON.GET_SAS_TOKEN_FAIL
    ) {
      next(action);
      while (queue.length) {
        next(queue.shift());
      }
      return null;
    }

    // Pass the action to the next middleware if sas token is not required for the action
    if (!actionsRequireSASToken.includes(action.type)) {
      return next(action);
    }

    const {
      common: {
        sasToken: { isRefreshing, signedExpiry, sharedAccessSignature }
      }
    } = store.getState();

    // Queue the actions if the token is refreshing
    if (isRefreshing) {
      queue.push(action);
      return null;
    }

    const sasTokenExpiryTime = signedExpiry
      ? new Date(signedExpiry).getTime()
      : null;

    // Compensate for any time difference between system time and server time
    const timeDiff = localStorage.getItem('timeDiff');
    const systemTime = new Date().getTime();
    const now = timeDiff ? systemTime + parseInt(timeDiff, 10) : systemTime;

    // Logic to check the expiration of SAS Token
    if (
      !sasTokenExpiryTime ||
      !sharedAccessSignature ||
      sasTokenExpiryTime - now < Config.AUTH.sasTokenRefreshTimeInMs
    ) {
      queue.push(action);
      return next(getSASToken());
    }

    return next(action);
  };
}

export default generateRefreshSasTokenMiddleware();
