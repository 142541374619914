import { call, put } from 'redux-saga/effects';
import moment from 'moment';
import { getData, postData } from '../../utils/axios';

import API from '../../API';
import { ACTIONS } from '../actions/actionType';
import CONSTANTS from '../../constants';
import ENUMS from '../../enum';

function* getSASToken() {
  yield put({
    type: ACTIONS.COMMON.QUEUE_ACTION_ON_REFRESH_SAS_TOKEN
  });

  let response;
  try {
    response = yield call(getData, API.sas);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.COMMON.GET_SAS_TOKEN_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.COMMON.GET_SAS_TOKEN_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.COMMON.GET_SAS_TOKEN_FAIL,
      data: error
    });
  }
}

function* getUserRole() {
  let response;
  try {
    response = yield call(getData, API.superUserRole);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.COMMON.GET_USER_ROLE_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.COMMON.GET_USER_ROLE_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.COMMON.GET_USER_ROLE_FAIL,
      data: error
    });
  }
}

function* getRemovalReasons() {
  let response;
  try {
    response = yield call(getData, API.contentRemovalReasons);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.COMMON.FEED_REMOVAL_REASON_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.COMMON.FEED_REMOVAL_REASON_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.COMMON.FEED_REMOVAL_REASON_FAIL,
      data: error
    });
  }
}

function* getNotificationCount() {
  let response;
  try {
    const URL = `${API.taskList}?page=1&size=1`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.COMMON.GET_NOTIFICATION_COUNT_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.COMMON.GET_NOTIFICATION_COUNT_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.COMMON.GET_NOTIFICATION_COUNT_FAIL,
      data: error
    });
  }
}

/**
 * @description Determines whether the action being performed on is either a
 * post or a comment and decides which API endpoint is to be triggered.
 *
 * @param {object} actionData - which contains the post Id & itemType
 * `id` - unique id representing a post.
 * `itemType` - enum; 1 = FEED_ITEM, 2 = COMMENT (defined in enum.js)
 *
 * @returns {string} url - Corresponding API endpoint for the item type
 */
const getFeedActionUrl = ({ id, itemType }) => {
  if (itemType === ENUMS.ITEM_TYPE.FEED_ITEM) {
    return `${API.feedUrl}/${id}/feed-action`;
  }
  return `${API.commentUrl}/${id}/comment-action`;
};

/**
 * @description Determines the type of message to be shown on success of an action.
 * If it is an allow action, success message related to "Allow" action should be shown and vice versa.
 *
 * @param {object} actionData - which contains the status enum
 *  that denotes the actionType (ALLOW/REJECT).
 *
 * @returns {object} message - An object which has text message as its property.
 */
const getFeedActionSuccessMessage = ({ status }) => {
  const { ALLOW_SUCCESS, REMOVE_SUCCESS } = CONSTANTS.COMMON.ACTIONS_MODAL;
  if (status === ENUMS.STATUS.ALLOWED) {
    return { message: ALLOW_SUCCESS };
  }
  return { message: REMOVE_SUCCESS };
};

/**
 * @description Determines the type of message to be shown on success of an action.
 * If it is an remove action, success message related to "Remove" action should be shown and vice versa.
 *
 * @param {object} actionData - which contains the status enum
 *  that denotes the actionType (ALLOW/REJECT).
 *
 * @returns {object} message - An object which has text message as its property.
 */
const getFeedActionFailureMessage = ({ status }) => {
  const { ALLOW_FAIL, REMOVE_FAIL } = CONSTANTS.COMMON.ACTIONS_MODAL;
  if (status === ENUMS.STATUS.ALLOWED) {
    return { message: ALLOW_FAIL };
  }
  return { message: REMOVE_FAIL };
};

function* feedAction(action) {
  let response;

  const { id, itemType, request, username } = action.data;

  const successMessage = getFeedActionSuccessMessage(request);
  const errorMessage = getFeedActionFailureMessage(request);

  try {
    const URL = getFeedActionUrl({ id, itemType });
    response = yield call(postData, URL, request);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.COMMON.ALLOW_REMOVE_POST_SUCCESS,
        data: response.data
      });
      if (action.fromTaskList) {
        yield put({
          type: ACTIONS.TASK_LIST.REMOVE_TASK_FROM_LIST,
          data: action.data.id
        });
      } else {
        // update status
        const timeServerDiff = localStorage.getItem('timeDiff');
        const actionTime = moment()
          .utc()
          .add(timeServerDiff, 'ms')
          .format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
        yield put({
          type: ACTIONS.SEARCH.UPDATE_FEED_ACTION,
          id,
          data: {
            status: response.data.status,
            actionBy: username,
            actionTime
          }
        });
      }
      // Updating notification count
      yield getNotificationCount();
      yield put({
        type: ACTIONS.COMMON.SET_ALERT_MESSAGE,
        data: successMessage
      });
    } else if (response.status === 400) {
      yield put({
        type: ACTIONS.COMMON.ALLOW_REMOVE_DONE_ALREADY,
        data: response.data
      });
      if (action.fromTaskList) {
        yield put({
          type: ACTIONS.TASK_LIST.REMOVE_TASK_FROM_LIST,
          data: action.data.id
        });
      }
      // Updating notification count
      yield getNotificationCount();
    } else {
      yield put({
        type: ACTIONS.COMMON.ALLOW_REMOVE_POST_FAIL,
        data: response.data
      });
      yield put({
        type: ACTIONS.COMMON.SET_ALERT_MESSAGE,
        data: errorMessage
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.COMMON.ALLOW_REMOVE_POST_FAIL,
      data: error
    });
    yield put({
      type: ACTIONS.COMMON.SET_ALERT_MESSAGE,
      data: errorMessage
    });
  }
}

function* fetchUserDetail(action) {
  let response;
  const { USER_DETAILS_FAIL: message } = CONSTANTS.COMMON.ACTIONS_MODAL;
  const alertMessage = { message };

  try {
    const URL = `${API.searchUrl}/${action.data.senderId}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.COMMON.FETCH_USER_DETAIL_SUCCESS,
        data: response.data
      });
      yield put({
        type: ACTIONS.COMMON.SHOW_ACTION_MODAL,
        data: { ...action.data }
      });
    } else {
      yield put({
        type: ACTIONS.COMMON.FETCH_USER_DETAIL_FAIL,
        data: response.data
      });
      yield put({
        type: ACTIONS.COMMON.SET_ALERT_MESSAGE,
        data: alertMessage
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.COMMON.FETCH_USER_DETAIL_FAIL,
      data: error
    });
    yield put({
      type: ACTIONS.COMMON.SET_ALERT_MESSAGE,
      data: alertMessage
    });
  }
}

export {
  getSASToken,
  getUserRole,
  getRemovalReasons,
  getNotificationCount,
  feedAction,
  fetchUserDetail
};
