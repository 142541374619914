import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Badge.scss';

/**
 * @description
 * This method will show notification count. If count is greater then 100 then it will render 99+
 *
 * @param {Number} notificationCount number of task list
 *
 * @returns {Number | String} notification count to be rendered.
 */
const renderNotificationCount = notificationCount =>
  notificationCount < 100 ? notificationCount : '99+';

const Badge = ({ notificationCount, customClass }) => {
  const badgeClasses = cx(['badge', `${customClass}`]);
  // Render only if there is notificationCount has value.
  if (notificationCount) {
    return (
      <div className={badgeClasses}>
        {renderNotificationCount(notificationCount)}
      </div>
    );
  }
  return null;
};

Badge.propTypes = {
  notificationCount: PropTypes.number,
  customClass: PropTypes.string
};

Badge.defaultProps = {
  notificationCount: 0,
  customClass: ''
};

export default Badge;
