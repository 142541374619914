import { ACTIONS } from './actionType';

const resetTaskList = data => {
  return {
    type: ACTIONS.TASK_LIST.RESET_TASK_LIST,
    data
  };
};

const fetchTaskList = data => {
  return {
    type: ACTIONS.TASK_LIST.FETCH_TASK_LIST,
    data
  };
};

const actOnFeed = data => {
  return {
    type: ACTIONS.TASK_LIST.ALLOW_REMOVE_POST,
    data
  };
};

export { resetTaskList, fetchTaskList, actOnFeed };
