// Active icons
import taskListActive from './assets/navigation-icon/active/task_list_active.svg';
import reportActive from './assets/navigation-icon/active/report_active.svg';
import searchActive from './assets/navigation-icon/active/search_active.svg';
import modActive from './assets/navigation-icon/active/admin_and_moderator_active.svg';

// Inactive icons
import taskListInactive from './assets/navigation-icon/inactive/task_list.svg';
import reportInactive from './assets/navigation-icon/inactive/report.svg';
import searchInactive from './assets/navigation-icon/inactive/search.svg';
import modInactive from './assets/navigation-icon/inactive/admin_and_moderator.svg';
import logout from './assets/navigation-icon/inactive/logout.svg';

/**
 * @description
 * This file return routes which will be rendered for individual element.
 */
const routes = [
  {
    path: '/task-list',
    name: 'Task List',
    activeIcon: taskListActive,
    inactiveIcon: taskListInactive,
    tooltip: 'Task List'
  },
  {
    path: '/report',
    name: 'Report',
    activeIcon: reportActive,
    inactiveIcon: reportInactive,
    tooltip: 'Generate Report'
  },
  {
    path: '/search',
    name: 'search',
    activeIcon: searchActive,
    inactiveIcon: searchInactive,
    tooltip: 'Search for Users'
  },
  {
    path: '/moderator-list',
    name: 'Moderator and Admins',
    activeIcon: modActive,
    inactiveIcon: modInactive,
    tooltip: 'Moderators & Admins'
  },
  {
    path: '/logout',
    name: 'Logout',
    activeIcon: '',
    inactiveIcon: logout,
    tooltip: 'Logout'
  }
];

export const TASK_LIST_NAME = 'Task List';

export default routes;
