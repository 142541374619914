import React from 'react';
import PropTypes from 'prop-types';

/**
 * @description HACK to load svg images in Safari "v12.0.2"
 * Implemented based on the following answer: https://stackoverflow.com/a/27249490/2661938
 * But a drawback: "<object>" tags do not respond to user "clicks".
 * So to fix that,
 * 1) css property: "pointerEvents" are set to none.
 * 2) "<object>" tag is wrapped by a <span> tag with display type set to "inline-block".
 *
 * Caution: "pointerEvents" is supported only in IE 11 & above.
 */
const SvgImage = ({
  src,
  alt,
  height,
  width,
  verticalAlign,
  displayType,
  ...props
}) => {
  const isSafari = !navigator.userAgent.includes('Chrome');
  const isSvg = src.includes('.svg');
  const imageStyles = {
    pointerEvents: 'none',
    maxWidth: width || 'none',
    width,
    maxHeight: height || 'none',
    height,
    verticalAlign
  };

  return (
    <span style={{ display: displayType }} {...props}>
      {isSafari && isSvg ? (
        <object
          data={src}
          style={imageStyles}
          type="image/svg+xml"
          aria-label={alt}
        />
      ) : (
        <img src={src} style={imageStyles} alt={alt} />
      )}
    </span>
  );
};

SvgImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  verticalAlign: PropTypes.string,
  displayType: PropTypes.string
};

SvgImage.defaultProps = {
  height: '',
  width: '',
  verticalAlign: 'middle',
  displayType: 'inline-block'
};

export default SvgImage;
