import { call, put } from 'redux-saga/effects';
import { constructQueryParams } from '../../utils/utils';
import { getData, deleteData, patchData } from '../../utils/axios';

import API from '../../API';
import { ACTIONS } from '../actions/actionType';
import CONSTANTS from '../../constants';

function* getSuperuserList(action) {
  let response;
  try {
    const URL = `${API.searchUrl}?${constructQueryParams(action.data)}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST_FAIL,
      data: error
    });
  }
}

function* deleteSuperUserList(action) {
  let response;
  const alertType = CONSTANTS.ADMIN_AND_MODERATORS.alertMessageType.delete;
  try {
    const URL = `${API.userUrl}/${action.data.deleteAction.id}/roles`;
    response = yield call(deleteData, URL);
    if (response.status === 200) {
      yield* getSuperuserList({
        type: ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST,
        data: action.data.fetchUserAction
      });
      yield put({
        type: ACTIONS.COMMON.SET_ALERT_MESSAGE,
        data: { message: action.data.deleteAction.name, alertType }
      });
      yield put({
        type: ACTIONS.ADMIN_MODERATOR.DELETE_SUPER_USER_SUCCESS,
        data: action.data.deleteAction.id
      });
    } else {
      yield put({
        type: ACTIONS.ADMIN_MODERATOR.DELETE_SUPER_USER_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.ADMIN_MODERATOR.DELETE_SUPER_USER_FAIL,
      data: error
    });
  }
}

/**
 * @description
 * Based on from where the method is called, alert message is decided.
 * This method is called from two places.
 *  1) In Moderators & Admins table - clicking on "Save changes" button after updating roles.
 *  2) Add Users modal - Changing the roles of some users and clicking save button.
 *
 * Messages for both invocations are different. Hence they are differentiated by a boolean flag
 * set as true when it is invoked from "Add users modal" (AddUses.js).
 * @param {Object} action - action which holds the list of roles to be updated
 * and a flag `addUsers` representing the context that this method is called from.
 *
 * @returns {Object} alertMessage - which has two keys 1) Message & 2) Alert type.
 * Based on the action passed, the message may vary.
 *
 */
function getAlertMessage(action) {
  if (action.data.addUsers) {
    return {
      message: CONSTANTS.ADMIN_AND_MODERATORS.addUser.successMessage,
      alertType: CONSTANTS.ADMIN_AND_MODERATORS.alertMessageType.modify
    };
  }
  return {
    message: CONSTANTS.ADMIN_AND_MODERATORS.modifyAlertMessage,
    alertType: CONSTANTS.ADMIN_AND_MODERATORS.alertMessageType.modify
  };
}

function* updateUserRoles(action) {
  let response;
  const alertMessage = getAlertMessage(action);
  try {
    const URL = API.rolesUrl;
    response = yield call(patchData, URL, action.data.updateRolesRequest);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.COMMON.SET_ALERT_MESSAGE,
        data: alertMessage
      });
      yield* getSuperuserList({
        type: ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST,
        data: action.data.fetchSuperUserList
      });
      yield put({
        type: ACTIONS.ADMIN_MODERATOR.UPDATE_SUPER_USER_ROLES_SUCCESS,
        data: action.data.updateRolesRequest
      });
      // close "AddUsers" modal if it is open, since the update request succeeded.
      if (action.data.addUsers) {
        yield put({
          type: ACTIONS.ADMIN_MODERATOR.HIDE_ADD_USERS_MODAL
        });
      }
    } else {
      yield put({
        type: ACTIONS.ADMIN_MODERATOR.UPDATE_SUPER_USER_ROLES_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.ADMIN_MODERATOR.UPDATE_SUPER_USER_ROLES_FAIL,
      data: error
    });
  }
}

function* getUserList(action) {
  let response;
  try {
    const URL = `${API.searchUrl}?${constructQueryParams(action.data)}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.ADMIN_MODERATOR.GET_USER_LIST_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.ADMIN_MODERATOR.GET_USER_LIST_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.ADMIN_MODERATOR.GET_USER_LIST_FAIL,
      data: error
    });
  }
}

export { getSuperuserList, deleteSuperUserList, updateUserRoles, getUserList };
