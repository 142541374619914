import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducers from './rootReducer';
import rootSaga from './rootSaga';
import {
  refreshSasTokenMiddleware,
  checkAccessTokenMiddleWare
} from './middlewares';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : null || compose;

const store = createStore(
  rootReducers,
  composeEnhancers(
    applyMiddleware(
      checkAccessTokenMiddleWare,
      refreshSasTokenMiddleware,
      sagaMiddleware
    )
  )
);

sagaMiddleware.run(function*() {
  /* eslint func-names: [0] */
  yield rootSaga();
});

export default store;
