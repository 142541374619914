import React from 'react';
import PropTypes from 'prop-types';
import SvgImage from '../SvgImage/SvgImage';

import plusIcon from '../../assets/images/plus-icon-grey.svg';
import allowIcon from '../../assets/images/allow-icon.svg';
import './SelectionButton.scss';

const SelectionButton = ({ selected, onChange }) => {
  return (
    <span className="selection-button">
      {selected ? (
        <SvgImage
          src={allowIcon}
          alt="Unselect"
          onClick={onChange}
          className="btn btn-unselect"
        />
      ) : (
        <SvgImage
          src={plusIcon}
          alt="Select"
          onClick={onChange}
          className="btn btn-select"
        />
      )}
    </span>
  );
};

SelectionButton.propTypes = {
  selected: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

SelectionButton.defaultProps = {
  selected: false
};

export default SelectionButton;
