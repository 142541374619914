import ENUM from './enum';

const CONSTANTS = {
  AUTH: {
    ACCESS_TOKEN_PARAMS: {
      response_type: 'token',
      prompt: 'login'
    },
    sessionTimeoutDurationInMs: 20 * 60 * 1000, // 20 minutes in ms
    sasTokenRefreshTimeInMs: 1 * 60 * 1000
  },
  LOGIN: {
    title: 'HCA Inspire',
    iconAltText: 'HCA Inspire Icon',
    proceedToLogin: 'PROCEED TO LOGIN',
    loginSuccessMessage: 'LOGIN_SUCCESS',
    INACTIVE_SESSION_MESSAGE:
      'Due to inactivity for the last 20 minutes, your session has been logged out. Please sign in again to use the moderator tool.',
    UNAUTHORIZED_USER: 'You are not authorized to access Moderator tool'
  },
  TASK_LIST: {
    title: 'Task List',
    pageSize: 15,
    TABLE_HEADER: ['USER', 'CONTENT', 'DATE POSTED', 'REPORTED BY', 'ACTIONS'],
    NO_DATA_FOUND:
      'There are no new tasks to moderate. Please check back later.',
    READ_MORE: {
      CHAR_LIMIT: 140,
      SHOW_TEXT: 'READ MORE',
      CLOSE_TEXT: 'CLOSE'
    }
  },
  REPORT: {
    TITLE: {
      HOME_PAGE: 'What kind of report would you like to generate?',
      USER_SEARCH: 'Who are you looking for?',
      DIVISION_SEARCH: 'Which division(s) are you looking for?',
      SUMMARY: 'Your Report'
    },
    DROPDOWN_OPTIONS: {
      DEFAULT_REMOVAL_REASON: {
        text: 'All Reason Types',
        value: 0
      },
      POSTS_BY: {
        ALL_HCA: {
          text: 'All HCA',
          value: ENUM.POSTS_BY.ALL_HCA
        },
        BY_PERSON: {
          text: 'By Person',
          value: ENUM.POSTS_BY.BY_PERSON
        },
        BY_DIVISION: {
          text: 'By Division',
          value: ENUM.POSTS_BY.BY_DIVISION
        }
      },
      DISPOSITION_TYPES: [
        {
          text: 'All Dispositions',
          value: ENUM.DISPOSITION_TYPES.ALL_DISPOSITIONS
        },
        {
          text: 'Allowed',
          value: ENUM.DISPOSITION_TYPES.ALLOWED
        },
        {
          text: 'Removed',
          value: ENUM.DISPOSITION_TYPES.REMOVED
        }
      ],
      DATE_RANGE: [
        {
          text: 'Any Time',
          value: ENUM.DATE_RANGE.ANY_TIME
        },
        {
          text: 'Today',
          value: ENUM.DATE_RANGE.TODAY
        },
        {
          text: 'Last Week',
          value: ENUM.DATE_RANGE.LAST_WEEK
        },
        {
          text: 'Last Month',
          value: ENUM.DATE_RANGE.LAST_MONTH
        },
        {
          text: 'Last Year',
          value: ENUM.DATE_RANGE.LAST_YEAR
        },
        {
          text: 'Select Dates',
          value: ENUM.DATE_RANGE.SELECT_DATES
        }
      ]
    },
    USER_SELECTION: {
      TABLE_HEADER: [
        'NAME',
        'HCA ID',
        'UNIT',
        'FACILITY',
        'DIVISION',
        'POSTS',
        '' // Checkbox column
      ],
      PAGE_SIZE: 10
    },
    DIVISION_SELECTION: {
      TABLE_HEADER: [
        'NAME',
        'REGION',
        '' // Checkbox column
      ],
      PAGE_SIZE: 10
    },
    REPORT_SUMMARY: {
      TABLE_HEADER: [
        'NAME',
        'CONTENT',
        'ACTION',
        'DATE OF ACTION',
        'REASON',
        'UNIT',
        'FACILITY'
      ],
      NEW_REPORT: 'New Report',
      NO_DATA_FOUND: 'No posts to show for the selected set of filters',
      DOWNLOAD_TYPES: {
        PDF: 'pdf',
        EXCEL: 'xlsx'
      },
      DOWNLOAD_FILE_NAME: 'Report-Summary'
    }
  },
  SEARCH: {
    TITLE: 'Search',
    PAGE_SIZE: 20,
    searchbarPlaceHolderText:
      'Search for users by first name, last name or 3-4ID',
    searchbarPlaceHolderDivision: 'Search for division(s) by name or region',
    SEARCH_TABLE_HEADER: [
      'NAME',
      'HCA ID',
      'UNIT',
      'FACILITY',
      'DIVISION',
      'POSTS'
    ],
    USER_PROFILE: {
      PAGE_SIZE: 15,
      NO_DATA_FOUND: 'There are no posts to show for this user'
    }
  },
  ADMIN_AND_MODERATORS: {
    title: 'Moderators & Admins',
    tableHeader: [
      'Name',
      'Unit',
      'Facility',
      'Division',
      'Last Active',
      'Role',
      '' // Remove Users column
    ],
    pageSize: 10,
    removeUserDialogTitle: 'Remove User?',
    removeUserConfirmation:
      'Are you sure you want to remove %s from the Moderators & Administrators list?',
    removeUserSubmit: 'Yes, Remove',
    removeAlertMessage:
      '%s. has been removed from the Moderators & Administrators list.',
    modifyAlertMessage: 'Updated role(s) successfully',
    alertMessageType: {
      delete: 'delete',
      modify: 'modify'
    },
    emptySearchResult: 'No results found for your search term',
    rolesDropdownOptions: {
      DEFAULT: {
        text: 'Choose role',
        value: 0
      },
      USER: {
        text: 'User',
        value: 1
      },
      MODERATOR: {
        text: 'Moderator',
        value: 2
      },
      ADMINISTRATOR: {
        text: 'Admin',
        value: 3
      }
    },
    addUser: {
      title: 'Add User',
      tableHeader: ['Name', 'Unit', 'Facility', 'Division', 'Role'],
      pageSize: 5,
      successMessage: 'Users have been added successfully'
    }
  },
  COMMON: {
    PAGE_TITLE: {
      TASK_LIST: 'Task List - Moderator Tool',
      GENERATE_REPORT: 'Generate Reports - Moderator Tool',
      REPORT_SUMMARY: 'Your Report - Moderator Tool',
      SEARCH: 'Search - Moderator Tool',
      MODERATOR_AND_ADMINS: 'Moderators and Admins - Moderator Tool',
      USER_PROFILE: 'User Profile - Moderator Tool'
    },
    CARD_REVEAL_TITLE: 'Reported by',
    CANCEL: 'Cancel',
    LOADING: 'Loading...',
    homepage: '/task-list',
    next: 'next',
    prev: 'prev',
    delete: 'Delete',
    saveChanges: 'Save changes',
    save: 'Save',
    SUBMIT: 'Submit',
    ALLOW: 'Allow',
    REMOVE: 'Remove',
    DISMISS: 'Dismiss',
    GENERATE: 'Generate',
    ACTION_ICON_SIZE: '21px',
    STATUS_MESSAGE: {
      1: 'Allowed by',
      2: 'Removed by'
    },
    FILTER_BY_OPTIONS: {
      MOST_RECENT: {
        text: 'Most Recent',
        value: 'createdOn,desc'
      },
      BY_OLDEST: {
        text: 'By Oldest',
        value: 'createdOn,asc'
      }
    },
    BACK: 'BACK',
    USERS: 'Users',
    DIVISIONS: 'Divisions',
    SELECTED: 'Selected',
    ALLOWED: 'Allowed',
    REMOVED: 'Removed',
    ACTIONS_MODAL: {
      ALLOW_MODAL_TITLE: 'Allow Post',
      REMOVE_MODAL_TITLE: 'Remove Post',
      EMP34: '3-4 ID:',
      MANAGER: 'Manager:',
      UNIT: 'Unit:',
      HOSPITAL: 'Hospital:',
      USER_DETAILS_FAIL: 'Unable to perform the intended action',
      COMMENTS_PLACEHOLDER: 'Additional comments...',
      ALLOW_SUCCESS: 'Post allowed successfully',
      REMOVE_SUCCESS: 'Post removed successfully',
      ALLOW_FAIL: 'Unable to allow post message',
      REMOVE_FAIL: 'Unable to remove post message'
    },
    WARNING_MODAL: {
      TITLE: 'Post Removed',
      MESSAGE:
        'This post has already been removed by another moderator. Thank you.'
    },
    CARD_MIN_HEIGHT: 275,
    JAVA_DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    CLEAR: 'Clear',
    APPLY: 'Apply',
    SHORT_DATE_FORMAT: 'MM/DD/YYYY',
    DATE_FORMAT_HYPHEN: 'YYYY-MM-DD',
    DATE_FORMAT_DOTTED: 'MM.DD.YYYY',
    TIME_FORMAT_HHMMA: 'HH:MM A',
    DEFAULT_CALENDAR_TEXT: 'Dates',
    START_DATE_TIME_PART: 'T00:00:00.000+00:00',
    END_DATE_TIME_PART: 'T23:59:59.999+00:00'
  }
};

export default CONSTANTS;
