import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Badge, SvgImage } from '../../../common';

import './NavigationItem.scss';

/**
 * @description
 * This functional component is used to render individual menu item.
 * @param {Object} props
 */

const NavigationItem = props => {
  const {
    activeLogo,
    inactiveLogo,
    link,
    name,
    tooltip,
    badge: { hasBadge, notificationCount }
  } = props;

  return (
    <li className="nav-item">
      <NavLink to={link} className="logo">
        {/* Active logo */}
        <SvgImage src={activeLogo} alt={name} className="active-logo" />

        {/* Badge component */}
        {hasBadge && (
          <Badge
            notificationCount={notificationCount}
            customClass="notification-badge"
          />
        )}

        {/* Inactive logo */}
        <SvgImage src={inactiveLogo} alt={name} className="inactive-logo" />
      </NavLink>
      {/* Tooltip */}
      <div className="nav-tooltip">{tooltip}</div>
    </li>
  );
};

NavigationItem.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  activeLogo: PropTypes.string.isRequired,
  inactiveLogo: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  badge: PropTypes.object.isRequired
};

export default NavigationItem;
