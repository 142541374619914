import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import { ACTIONS } from './redux/actions/actionType';

import { setLastLoggedOn } from './redux/saga/Auth.saga';
import {
  getSASToken,
  getUserRole,
  getNotificationCount,
  getRemovalReasons,
  fetchUserDetail,
  feedAction
} from './redux/saga/Common.saga';
import { fetchTaskList } from './redux/saga/TaskList.saga';
import {
  fetchSearchedUsers,
  fetchUserProfileTask,
  getUserPersonalDetails,
  loadMoreSearchedUsers
} from './redux/saga/Search.saga';
import {
  getSuperuserList,
  deleteSuperUserList,
  updateUserRoles,
  getUserList
} from './redux/saga/ModeratorList.saga';
import {
  fetchUserList,
  fetchDivisionList,
  fetchReportSummary
} from './redux/saga/Report.saga';

export default () =>
  all([
    takeEvery(ACTIONS.AUTH.SET_LAST_LOGGED_ON, setLastLoggedOn),
    takeEvery(ACTIONS.COMMON.GET_USER_ROLE, getUserRole),
    takeEvery(ACTIONS.COMMON.GET_SAS_TOKEN, getSASToken),
    takeEvery(ACTIONS.COMMON.GET_NOTIFICATION_COUNT, getNotificationCount),
    takeEvery(ACTIONS.COMMON.FEED_REMOVAL_REASON, getRemovalReasons),
    takeEvery(ACTIONS.COMMON.FETCH_USER_DETAIL, fetchUserDetail),
    takeEvery(ACTIONS.COMMON.FEED_ACTION, feedAction),
    takeEvery(ACTIONS.TASK_LIST.FETCH_TASK_LIST, fetchTaskList),
    takeLatest(ACTIONS.SEARCH.FETCH_SEARCHED_USER, fetchSearchedUsers),
    takeLatest(ACTIONS.SEARCH.LOAD_MORE_SEARCHED_USER, loadMoreSearchedUsers),
    takeEvery(ACTIONS.SEARCH.FETCH_USER_PROFILE, fetchUserProfileTask),
    takeEvery(ACTIONS.SEARCH.USER_PERSONAL_DETAILS, getUserPersonalDetails),
    takeLatest(ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST, getSuperuserList),
    takeEvery(ACTIONS.ADMIN_MODERATOR.DELETE_SUPER_USER, deleteSuperUserList),
    takeEvery(ACTIONS.ADMIN_MODERATOR.UPDATE_SUPER_USER_ROLES, updateUserRoles),
    takeLatest(ACTIONS.ADMIN_MODERATOR.GET_USER_LIST, getUserList),
    takeEvery(ACTIONS.REPORT.FETCH_USER_LIST, fetchUserList),
    takeEvery(ACTIONS.REPORT.FETCH_DIVISION_LIST, fetchDivisionList),
    takeEvery(ACTIONS.REPORT.FETCH_REPORT_SUMMARY, fetchReportSummary)
  ]);
