import { call, put } from 'redux-saga/effects';
import { patchData } from '../../utils/axios';

import { ACTIONS } from '../actions/actionType';
import API from '../../API';

// Set last logged In time
function* setLastLoggedOn() {
  try {
    const response = yield call(patchData, API.lastLoggedOn);
    if (response.status === 200) {
      yield put({ type: ACTIONS.AUTH.SET_LAST_LOGGED_ON_SUCCESS });
    } else {
      yield put({ type: ACTIONS.AUTH.SET_LAST_LOGGED_ON_FAIL });
    }
  } catch (error) {
    yield put({ type: ACTIONS.AUTH.SET_LAST_LOGGED_ON_FAIL });
  }
}

export { setLastLoggedOn };
