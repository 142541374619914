import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Header } from '../../../components';
import { ReportOptions, Button, Loader } from '../../../common';
import {
  setTitle,
  isNullOrUndefined,
  formatDateRange
} from '../../../utils/utils';
import { PrintCSV, printPDF } from '../../../utils/print';
import {
  clearReportInputs,
  fetchReportSummary
} from '../../../redux/actions/Report.actions';
import CONSTANTS from '../../../constants';

import './ReportSummary.scss';
import ENUM from '../../../enum';
import SummaryTable from './SummaryTable/SummaryTable';
import downloadIcon from '../../../assets/images/download.svg';

const {
  POSTS_BY,
  DEFAULT_REMOVAL_REASON,
  DISPOSITION_TYPES,
  DATE_RANGE
} = CONSTANTS.REPORT.DROPDOWN_OPTIONS;

class ReportSummary extends Component {
  static propTypes = {
    reportResults: PropTypes.array.isRequired,
    isEmojiSupported: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    removalReasons: PropTypes.array.isRequired,
    onClearReportInputs: PropTypes.func.isRequired,
    dispositionId: PropTypes.string.isRequired,
    removalReasonId: PropTypes.any.isRequired,
    postsById: PropTypes.string.isRequired,
    selectedUsers: PropTypes.object.isRequired,
    selectedDivisions: PropTypes.object.isRequired,
    dateRangeId: PropTypes.string.isRequired,
    onGenerateReport: PropTypes.func.isRequired,
    hasDataLoaded: PropTypes.bool.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    sasToken: PropTypes.object.isRequired
  };

  static defaultProps = {
    startDate: '',
    endDate: ''
  };

  /**
   * @description
   * Once this component loaded successfully, set the page's title
   * and send request to load report-summary
   */
  componentDidMount() {
    setTitle(CONSTANTS.COMMON.PAGE_TITLE.REPORT_SUMMARY);
    this.generateReport();
  }

  /**
   * @description
   * When user navigates to another page, clear all selected inputs
   * by dispatching `onClearReportInputs` action
   */
  componentWillUnmount() {
    this.props.onClearReportInputs();
  }

  /**
   * @description
   * Prepares request object to load report-summary and dispatches an action
   * that will load report summary.
   */
  generateReport = () => {
    const request = this.constructRequestMessage();
    this.props.onGenerateReport(request);
  };

  /**
   * @description
   * Prepares request object to load report-summary.
   * Populate only required keys in request object, else the API will fail.
   */
  constructRequestMessage = () => {
    const {
      dispositionId: action,
      removalReasonId: reasonId,
      postsById,
      dateRangeId
    } = this.props;

    const { ALLOWED, REMOVED } = ENUM.DISPOSITION_TYPES;
    const inputs = {};
    // populate only needed props. Having unnecessary properties returns error :(
    if (action === ALLOWED || action === REMOVED) {
      inputs.flagActionId = action;
    }

    if (+reasonId !== +DEFAULT_REMOVAL_REASON.value) {
      inputs.removalReasonId = reasonId;
    }

    if (postsById === ENUM.POSTS_BY.BY_PERSON) {
      inputs.userIds = Object.keys(this.props.selectedUsers);
    }

    if (postsById === ENUM.POSTS_BY.BY_DIVISION) {
      inputs.divisionIds = Object.keys(this.props.selectedDivisions);
    }
    const formattedStartDate = this.props.startDate.slice(0, 10);
    const formattedEndDate = this.props.endDate.slice(0, 10);

    if (dateRangeId !== ENUM.DATE_RANGE.ANY_TIME) {
      inputs.startDate = formattedStartDate;
      inputs.endDate = formattedEndDate;
    }
    return inputs;
  };

  /**
   * @description
   * Returns the current value or the default value for `disposition` dropdown.
   */
  getSelectedDispositionType = () => {
    const { dispositionId } = this.props;
    if (isNullOrUndefined(dispositionId)) {
      return DISPOSITION_TYPES[0];
    }
    return DISPOSITION_TYPES.find(item => +item.value === +dispositionId);
  };

  /**
   * @description
   * Returns the current value or the default value for `removalReason` dropdown.
   */
  getSelectedRemovalReason = () => {
    const { removalReasonId, removalReasons } = this.props;
    if (
      isNullOrUndefined(removalReasonId) ||
      +removalReasonId === 0 ||
      removalReasons.length === 0
    ) {
      return DEFAULT_REMOVAL_REASON;
    }
    return removalReasons.find(item => +item.value === +removalReasonId);
  };

  /**
   * @description
   * Returns the current value or the default value for `postsBy` dropdown.
   */
  getSelectedPostsByOption = () => {
    const { postsById } = this.props;
    if (isNullOrUndefined(postsById) || postsById === ENUM.POSTS_BY.ALL_HCA) {
      return POSTS_BY.ALL_HCA.text;
    }
    if (postsById === ENUM.POSTS_BY.BY_PERSON) {
      const { selectedUsers } = this.props;
      const userIds = Object.keys(selectedUsers);
      if (userIds.length < 2) {
        return `${selectedUsers[userIds[0]].name}`;
      }
      return `${selectedUsers[userIds[0]].name}, +${userIds.length - 1} more`;
    }
    if (postsById === ENUM.POSTS_BY.BY_DIVISION) {
      const { selectedDivisions } = this.props;
      const divisionIds = Object.keys(selectedDivisions);
      if (divisionIds.length < 2) {
        return `${selectedDivisions[divisionIds[0]].title}`;
      }
      return `${
        selectedDivisions[divisionIds[0]].title
      }, +${divisionIds.length - 1} more`;
    }
  };

  /**
   * @description
   * Returns the current value or the default value for `dateRange` dropdown.
   */
  getSelectedDateRange = () => {
    const { dateRangeId, startDate, endDate } = this.props;
    if (isNullOrUndefined(dateRangeId)) {
      return DATE_RANGE[0];
    }
    const dateRange = DATE_RANGE.find(item => +item.value === +dateRangeId);
    if (dateRangeId === ENUM.DATE_RANGE.SELECT_DATES) {
      return formatDateRange(startDate, endDate);
    }
    return dateRange.text;
  };

  /**
   * @description
   * This method is called on click of button "Start over", which clears all the
   * existing input values and allows user to generate another report
   * with different input values.
   */
  startOver = () => {
    this.props.history.push('/report');
    this.props.onClearReportInputs();
  };

  /**
   * @description
   * This function is caled when "PDF" button is clicked.
   * It dispatches an event by passing the file type as input
   */
  downloadAsPDF = () => {
    const { PDF } = CONSTANTS.REPORT.REPORT_SUMMARY.DOWNLOAD_TYPES;
    this.downloadReport(PDF);
  };

  render() {
    const { TITLE, REPORT_SUMMARY } = CONSTANTS.REPORT;
    const {
      reportResults: data,
      hasDataLoaded,
      isEmojiSupported,
      sasToken: { sharedAccessSignature: sasToken }
    } = this.props;

    return (
      <div className="container report-summary">
        {!hasDataLoaded && <Loader />}
        <Header title={TITLE.SUMMARY} />
        <section className="report-options-panel">
          <ReportOptions
            dispositionType={this.getSelectedDispositionType()}
            removalReason={this.getSelectedRemovalReason()}
            postsBy={this.getSelectedPostsByOption()}
            dateRange={this.getSelectedDateRange()}
          />
          <Button
            value={REPORT_SUMMARY.NEW_REPORT}
            customClass="btn-primary"
            onClick={this.startOver}
          />
        </section>
        <main className="report-summary-table">
          <SummaryTable
            data={data}
            isEmojiSupported={isEmojiSupported}
            sasToken={sasToken}
            hasDataLoaded={hasDataLoaded}
          />
        </main>
        {data.length > 0 && (
          <footer className="report-export-panel">
            <PrintCSV data={data} />
            <Button
              value="PDF"
              customClass="btn-right btn-primary btn-text"
              onClick={() => printPDF(data, sasToken)}
              iconPath={downloadIcon}
              iconWidth="12px"
              iconHeight="12px"
            />
          </footer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hasDataLoaded: state.report.hasDataLoaded,
  reportResults: state.report.reportResults,
  removalReasons: state.common.removalReasons,
  isEmojiSupported: state.common.isEmojiSupported,
  dispositionId: state.report.selectedDispositionType,
  removalReasonId: state.report.selectedRemovalReason,
  postsById: state.report.selectedPostsByOption,
  dateRangeId: state.report.selectedDateRange,
  selectedUsers: state.report.selectedUsers,
  selectedDivisions: state.report.selectedDivisions,
  startDate: state.report.startDate,
  endDate: state.report.endDate,
  sasToken: state.common.sasToken
});

const mapDispatchToProps = dispatch => ({
  onClearReportInputs: () => dispatch(clearReportInputs()),
  onGenerateReport: data => dispatch(fetchReportSummary(data))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportSummary)
);
