import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '..';
import CONSTANTS from '../../constants';

import './WarningModal.scss';

/**
 * @description
 * Functional UI component used to display an alert message in a modal.
 */
const WarningModal = props => {
  const { showModal, title, message, onDismiss } = props;

  return (
    <Modal show={showModal} modalClosed={props.onDismiss} title={title}>
      <p className="confirmation-text">{message}</p>
      <div className="button-panel warning-button-panel">
        <Button
          value={CONSTANTS.COMMON.DISMISS}
          customClass="btn-primary btn-text"
          onClick={onDismiss}
        />
      </div>
    </Modal>
  );
};

WarningModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default WarningModal;
