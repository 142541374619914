import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Header } from '../../components';
import { Button, RoundedDropDown, DateRangePickerDropdown } from '../../common';

import CONSTANTS from '../../constants';
import { fetchRemovalReasons } from '../../redux/actions/Common.actions';
import { updateReportInputs } from '../../redux/actions/Report.actions';
import ENUMS from '../../enum';

import './Report.scss';
import {
  constructQueryParams,
  parseQueryParams,
  isEmptyString,
  isNullOrUndefined,
  setTitle,
  convertDateRangeToUTC
} from '../../utils/utils';

const {
  POSTS_BY,
  DEFAULT_REMOVAL_REASON,
  DISPOSITION_TYPES,
  DATE_RANGE
} = CONSTANTS.REPORT.DROPDOWN_OPTIONS;

class Report extends Component {
  static propTypes = {
    onFetchRemovalReasons: PropTypes.func.isRequired,
    removalReasons: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    onUpdateReportInputs: PropTypes.func.isRequired
  };

  state = {
    selectedDispositionType: ENUMS.DISPOSITION_TYPES.ALL_DISPOSITIONS,
    selectedRemovalReason: DEFAULT_REMOVAL_REASON.value,
    selectedPostsByOption: ENUMS.POSTS_BY.ALL_HCA,
    selectedDateRange: ENUMS.DATE_RANGE.ANY_TIME,
    startDate: null,
    endDate: null
  };

  /**
   * @description
   * Fetches `removalReasons` array once the page completes loading.
   */
  componentDidMount() {
    setTitle(CONSTANTS.COMMON.PAGE_TITLE.GENERATE_REPORT);
    this.props.onFetchRemovalReasons();
    this.fetchInputsFromQueryParams();
  }

  /**
   * @description
   * This function gets called when user selects any option
   * from the first dropdown i.e., disposition types dropdown
   * It updates the internal state with the selected value;
   * @param {Object} option - one of the option in that dropdown
   */
  onDispositionTypeSelected = option =>
    this.setState({ selectedDispositionType: option.value });

  /**
   * @description
   * This function gets called when user selects any option
   * from the 2nd dropdown i.e., Removal reasons dropdown
   * It updates the internal state with the selected value;
   * @param {Object} option - one of the option in that dropdown
   *
   * NOTE: this dropdown is *only visible* if user selected "Removed" option as the
   * disposition type in the first dropdown.
   */
  onRemovalReasonSelected = option =>
    this.setState({ selectedRemovalReason: option.value });

  /**
   * @description
   * This function gets called when user selects whether to generate reports
   * of all users or by a selected set of users.
   * It updates the internal state with the selected value;
   * @param {Object} option - one of the option in that dropdown
   */
  onPostsBySelected = option =>
    this.setState({ selectedPostsByOption: option.value });

  /**
   * @description
   * This function gets called when user selects any option
   * from the date range dropdown
   * It updates the internal state with the selected value;
   * @param {Object} option - one of the option in that dropdown
   */
  onDateRangeSelected = option =>
    this.setState({
      selectedDateRange: option.value,
      startDate: option.start,
      endDate: option.end
    });

  /**
   * @description
   * This function is called when user clicks on "Generate" button
   * after selecting all the necessary inputs in the dropdowns.
   * If first navigates the page to "/report/summary" and then
   * updates the store with selected values.
   */
  generateReport = () => {
    const { startDate, endDate } = this.state;
    const dateRange = convertDateRangeToUTC(startDate, endDate);
    this.props.history.push('/report/summary');
    this.props.onUpdateReportInputs({
      selectedDispositionType: this.state.selectedDispositionType,
      selectedRemovalReason: this.state.selectedRemovalReason,
      selectedPostsByOption: this.state.selectedPostsByOption,
      selectedDateRange: this.state.selectedDateRange,
      ...dateRange
    });
  };

  /**
   * @description
   * This function reads all the selected values in the state
   * and constructs it as a query-parameter string.
   *
   * @return {String} query-parameter string
   */
  getInputsAsQueryParams = () => {
    const {
      selectedDispositionType: disposition,
      selectedRemovalReason,
      selectedDateRange: dateRange,
      selectedPostsByOption: postsBy,
      startDate: start,
      endDate: end
    } = this.state;
    const queryParams = {
      disposition,
      dateRange,
      postsBy,
      start,
      end
    };
    if (+disposition === +ENUMS.DISPOSITION_TYPES.REMOVED) {
      queryParams.removalReason = selectedRemovalReason;
    }
    return constructQueryParams(queryParams);
  };

  /**
   * @description
   * Navigates the application to the page where admin can select the
   * list of users to with which the report is generated
   */
  gotoSelectUsers = () => {
    this.props.history.push(
      `/report/by-person?${this.getInputsAsQueryParams()}`
    );
  };

  /**
   * @description
   * Navigates the application to the page where admin can select the
   * list of divsions to with which the report is generated
   */
  gotoSelectDivisions = () => {
    this.props.history.push(
      `/report/by-division?${this.getInputsAsQueryParams()}`
    );
  };

  /**
   * @description
   * Parses the query params and updates the state with input values.
   * So that the inputs are preserved even if the page is refreshed.
   */
  fetchInputsFromQueryParams = () => {
    const queryParams = this.props.history.location.search;
    if (!isEmptyString(queryParams) && !isNullOrUndefined(queryParams)) {
      const inputs = parseQueryParams(queryParams);
      this.setState({
        selectedDispositionType: inputs.disposition,
        selectedRemovalReason: inputs.removalReason || 0,
        selectedPostsByOption: inputs.postsBy,
        selectedDateRange: inputs.dateRange,
        startDate: inputs.start,
        endDate: inputs.end
      });
    }
  };

  /**
   * @description
   * Returns the current value or the default value for `disposition` dropdown.
   */
  getSelectedDispositionType = () => {
    const { selectedDispositionType } = this.state;
    if (isNullOrUndefined(selectedDispositionType)) {
      return DISPOSITION_TYPES[0];
    }
    return DISPOSITION_TYPES.find(
      item => +item.value === +selectedDispositionType
    );
  };

  /**
   * @description
   * Returns the current value or the default value for `removalReason` dropdown.
   */
  getSelectedRemovalReason = () => {
    const { selectedRemovalReason } = this.state;
    const { removalReasons } = this.props;
    if (
      isNullOrUndefined(selectedRemovalReason) ||
      +selectedRemovalReason === 0 ||
      removalReasons.length === 0
    ) {
      return DEFAULT_REMOVAL_REASON;
    }
    return removalReasons.find(item => +item.value === +selectedRemovalReason);
  };

  /**
   * @description
   * Returns the current value or the default value for `postsBy` dropdown.
   */
  getSelectedPostsByOption = () => {
    const { selectedPostsByOption } = this.state;
    if (
      isNullOrUndefined(selectedPostsByOption) ||
      selectedPostsByOption === ENUMS.POSTS_BY.ALL_HCA
    ) {
      return POSTS_BY.ALL_HCA;
    }
    if (selectedPostsByOption === ENUMS.POSTS_BY.BY_PERSON) {
      return POSTS_BY.BY_PERSON;
    }
    return POSTS_BY.BY_DIVISION;
  };

  /**
   * @description
   * Returns the current value or the default value for `dateRange` dropdown.
   */
  getSelectedDateRange = () => {
    const { selectedDateRange } = this.state;
    if (isNullOrUndefined(selectedDateRange)) {
      return DATE_RANGE[0];
    }
    const dateRange = DATE_RANGE.find(
      item => +item.value === +selectedDateRange
    );

    const { startDate, endDate } = this.state;
    if (dateRange.value === ENUMS.DATE_RANGE.SELECT_DATES) {
      dateRange.start = startDate;
      dateRange.end = endDate;
    }
    return dateRange;
  };

  render() {
    const { HOME_PAGE: reportPageTitle } = CONSTANTS.REPORT.TITLE;
    const { selectedDispositionType, selectedPostsByOption } = this.state;
    const { REMOVED } = ENUMS.DISPOSITION_TYPES;
    const { next, GENERATE } = CONSTANTS.COMMON;

    const byPerson = selectedPostsByOption === ENUMS.POSTS_BY.BY_PERSON;
    const byDivision = selectedPostsByOption === ENUMS.POSTS_BY.BY_DIVISION;
    // Show "NEXT" button if filtering by person or by division, otherwise show "GENERATE" button
    const footerBtnText = byPerson || byDivision ? next : GENERATE;
    // eslint-disable-next-line no-nested-ternary
    const footerBtnAction = byPerson
      ? this.gotoSelectUsers
      : byDivision
      ? this.gotoSelectDivisions
      : this.generateReport;

    return (
      <div className="report-container container">
        <Header title={reportPageTitle} />
        <main className="report-options">
          <RoundedDropDown
            options={DISPOSITION_TYPES}
            selectedOption={this.getSelectedDispositionType()}
            onChange={this.onDispositionTypeSelected}
            customClasses="report-input"
          />
          {/* show this dropdown only if "Removed" option is selected in the 1st dropdown */}
          {+selectedDispositionType === +REMOVED && (
            <RoundedDropDown
              options={[DEFAULT_REMOVAL_REASON, ...this.props.removalReasons]}
              selectedOption={this.getSelectedRemovalReason()}
              onChange={this.onRemovalReasonSelected}
              customClasses="report-input size-medium"
            />
          )}
          <RoundedDropDown
            options={[
              POSTS_BY.ALL_HCA,
              POSTS_BY.BY_PERSON,
              POSTS_BY.BY_DIVISION
            ]}
            selectedOption={this.getSelectedPostsByOption()}
            onChange={this.onPostsBySelected}
            customClasses="report-input"
          />
          <DateRangePickerDropdown
            selectedOption={this.getSelectedDateRange()}
            onSelect={this.onDateRangeSelected}
            customClasses="size-medium report-input"
          />
        </main>

        {/* Show "NEXT" button or "GENERATE" button based on the value of `postsBy` dropdown */}
        <Button
          value={footerBtnText}
          customClass="btn-primary btn-reports"
          onClick={footerBtnAction}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  removalReasons: state.common.removalReasons,
  selectedDispositionType: state.report.selectedDispositionType,
  selectedRemovalReason: state.report.selectedRemovalReason,
  selectedPostsByOption: state.report.selectedPostsByOption,
  selectedDateRange: state.report.selectedDateRange
});

const mapDispatchToProps = dispatch => ({
  onFetchRemovalReasons: () => dispatch(fetchRemovalReasons()),
  onUpdateReportInputs: data => dispatch(updateReportInputs(data))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Report)
);
