import React from 'react';
import CONSTANTS from '../../../constants';

import './LoginHeader.scss';
import icon from '../../../assets/images/Logo.svg';
import { SvgImage } from '../../../common';

export default () => {
  return (
    <header className="header-row">
      <SvgImage
        className="hca-inspire-icon"
        src={icon}
        alt={CONSTANTS.LOGIN.iconAltText}
        displayType="inline"
      />
      <h1 className="hca-inspire-title">{CONSTANTS.LOGIN.title}</h1>
    </header>
  );
};
