import React from 'react';
import PropTypes from 'prop-types';

import './Backdrop.scss';

/**
 * @param {Object} props
 * @description This is a functional component. Which is used to render backdrop and on clicking.
 * It will trigger onClick event which is connected to it.
 * @argument show: Boolean value based on which component will be rendered
 * @argument clicked: Function which is triggered when clicked on it.
 */
const Backdrop = ({ show, clicked }) =>
  show ? <div className="backdrop" role="button" onClick={clicked} /> : null;

Backdrop.propTypes = {
  show: PropTypes.bool.isRequired,
  clicked: PropTypes.func.isRequired
};

export default Backdrop;
