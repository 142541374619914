import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';

import SvgImage from '../../SvgImage/SvgImage';
import tickIcon from '../../../assets/images/tick.svg';

import CONSTANTS from '../../../constants';
import ENUM from '../../../enum';

import CalendarPanel from '../CalendarPanel/CalendarPanel';
import { calculateStartDate } from '../../../utils/utils';
import calendarIcon from '../../../assets/images/calendar.svg';

/**
 * @description
 * React UI Component - part of DateRangePickerDropdown.
 * This component is shown in the UI when `DateRangePickerDropdown` is opened.
 * It shows the list of options that can be chosen from the dropdown.
 */
const DropdownPanel = props => {
  const { SELECT_DATES } = ENUM.DATE_RANGE;
  const { isOpen, selectedOption } = props;
  const optionsListClass = cx(['options-list', { active: isOpen }]);
  const { DATE_RANGE: options } = CONSTANTS.REPORT.DROPDOWN_OPTIONS;
  const { start = moment(), end = moment() } = selectedOption;
  const selectedDate = moment.range(moment(start), moment(end));
  const [showCalendar, setShowCalendar] = useState(
    selectedOption.value === SELECT_DATES
  );

  /**
   * @description
   * This handler gets called when any option in the dropdown is selected or
   * a date-range is selected.
   * If "Select Dates" option is clicked, it just toggles the visibility of
   * the calendar.
   * @param {Object} item
   */
  const onItemSelected = item => {
    if (item.value !== SELECT_DATES) {
      const startDate = calculateStartDate(item.value);
      const endDate = moment().format();

      const selectedItem = {
        ...item,
        start: startDate,
        end: endDate
      };
      props.onSelectHandler(selectedItem);
    } else {
      setShowCalendar(!showCalendar);
    }
  };

  return (
    <div>
      {/* Options like: "Any date", "Last week", "Last month" */}
      <ul className={optionsListClass}>
        {options.map((item, index) => (
          <li
            key={`${item.value}-${index}`}
            className={cx(['options-item', { disabled: item.disabled }])}
            onClick={() => !item.disabled && onItemSelected(item)}
          >
            <span className="display-text">{item.text}</span>
            {item.value === SELECT_DATES ? (
              <SvgImage
                src={calendarIcon}
                alt="Calendar"
                className="display-icon"
                verticalAlign="none"
              />
            ) : (
              item.value === props.selectedOption.value && (
                <SvgImage
                  src={tickIcon}
                  alt="selected"
                  className="display-icon"
                  width="13px"
                />
              )
            )}
          </li>
        ))}
      </ul>
      {showCalendar && (
        <CalendarPanel
          selectedDate={selectedDate}
          onSelectHandler={props.onSelectHandler}
        />
      )}
    </div>
  );
};

DropdownPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedOption: PropTypes.object.isRequired,
  onSelectHandler: PropTypes.func.isRequired
};

export default DropdownPanel;
