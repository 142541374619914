import React, { Component, Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { UserDetails, InfiniteScroller } from '../../common';
import ContentBlock from '../ContentBlock/ContentBlock';
import AllowRemoveAction from '../AllowRemoveAction/AllowRemoveAction';

import { appendSASToken } from '../../utils/utils';
import CONSTANTS from '../../constants';
import ENUM from '../../enum';

import './TableView.scss';

class TableView extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    hasMore: PropTypes.bool.isRequired,
    fetchMore: PropTypes.func.isRequired,
    nextPage: PropTypes.number.isRequired,
    sasToken: PropTypes.string.isRequired,
    allowAction: PropTypes.func.isRequired,
    removeAction: PropTypes.func.isRequired,
    isEmojiSupported: PropTypes.bool.isRequired,
    onUserDetailsClicked: PropTypes.func
  };

  static defaultProps = {
    onUserDetailsClicked: () => {}
  };

  /**
   * @public For getting next page number when scrolled to fetch more data from server
   * @description
   * This method is used to fetch data(page) whose data has to been fetched when page is scrolled.
   */
  fetchData = () => {
    const { nextPage } = this.props;
    this.props.fetchMore(nextPage);
  };

  /**
   * @description
   * Renders Table header, based on header value set in constants file.
   *
   * @returns {ReactComponent} return table head
   */
  renderHeader = () => {
    const { TABLE_HEADER } = CONSTANTS.TASK_LIST;
    return (
      <Table.Row>
        {TABLE_HEADER.map((header, index) => (
          <Table.HeaderCell key={index} className="table-head">
            {header}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    );
  };

  /**
   * @description
   * This function takes a UTC time value either as a moment object or a string
   * and returns a UI component with two rows containing date and time respectively.
   *
   * @param {String | momentTime} time
   */
  renderDateAndTime = time => {
    if (!time) {
      return 'N/A';
    }

    const { DATE_FORMAT_DOTTED, TIME_FORMAT_HHMMA } = CONSTANTS.COMMON;
    return (
      <Fragment>
        <div>{moment(time).format(DATE_FORMAT_DOTTED)}</div>
        <div>{moment(time).format(TIME_FORMAT_HHMMA)}</div>
      </Fragment>
    );
  };

  /**
   * @description
   * This method is used to display individual row of the table which is passed on to it.
   * If data hasn't been passed to it, then it will return null.
   *
   * @param {Array} tableBodyData contains all row data.
   *
   * @returns {ReactComponent} return table body contain(row with data rendered in format).
   */
  renderBody = tableBodyData => {
    if (!tableBodyData.length) return null;

    return tableBodyData.map(rowData => this.prepareRow(rowData));
  };

  /**
   * @description
   * This method will be take reporter details and create UserDetails component. then return JSX
   *
   * @param {Array} reported Array containing reporters details.
   *
   * @returns {ReactComponent} JSX with UserDetails of individual reports
   */
  createReportedBy = reportedBy => {
    const { sasToken, onUserDetailsClicked } = this.props;
    return (
      <div className="reporter-wrapper">
        {reportedBy.map(reporter => {
          // Append SAS token for image
          const reporterImage = appendSASToken(
            sasToken,
            reporter.profileImageUrl
          );
          return (
            <UserDetails
              key={reporter.id}
              customClass="reporter-user-details"
              imageUrl={reporterImage}
              name={reporter.name}
              currentRole={reporter.currentRole}
              onClickAction={() => onUserDetailsClicked(reporter.id)}
            />
          );
        })}
      </div>
    );
  };

  /**
   * @description
   * This method is used to implement table's row in format. In order format of User, Content, Date posted, Reported by and Actions
   *
   * @param {Object} rowData contains object with individual row data.
   *
   * @returns {ReactComponent} It will return JSX with row data in format.
   */
  prepareRow = rowData => {
    // Checks if Item is of comment type.
    const isComment = rowData.itemType === ENUM.ITEM_TYPE.COMMENT;
    const { sasToken, isEmojiSupported, onUserDetailsClicked } = this.props;

    const row = (
      <Table.Row
        key={`${rowData.id}-${rowData.itemType}`}
        className="table-row"
      >
        <Table.Cell className="table-data">
          <UserDetails
            imageUrl={appendSASToken(sasToken, rowData.senderProfileImageUrl)}
            name={rowData.senderName}
            currentRole={rowData.senderCurrentRole}
            isComment={isComment}
            onClickAction={() => onUserDetailsClicked(rowData.senderId)}
          />
        </Table.Cell>
        <Table.Cell className="table-data">
          <ContentBlock
            imageUrl={appendSASToken(sasToken, rowData.imageUrl)}
            text={rowData.text}
            stickerTitle={rowData.stickerTitle}
            stickerUnicode={rowData.stickerUnicode}
            targetName={rowData.targetName}
            feedItemType={rowData.feedItemType}
            badgeName={rowData.badgeName}
            isEmojiSupported={isEmojiSupported}
          />
        </Table.Cell>
        <Table.Cell className="table-data">
          {this.renderDateAndTime(rowData.creationTime)}
        </Table.Cell>
        <Table.Cell className="table-data">
          {this.createReportedBy(rowData.reportedBy)}
        </Table.Cell>
        <Table.Cell className="table-data">
          {this.renderActionsButtonPanel(rowData)}
        </Table.Cell>
      </Table.Row>
    );

    return row;
  };

  /**
   * @description
   * Every row in a table has two action buttons: "Allow" or "Remove", which on clicked
   * opens up a pop-up panel where admin can take appropriate action.
   * This method returns the UI component which has the two buttons "Allow" & "Remove" aligned
   * column-wise as per the specification.
   *
   * @param {object} rowData An object that represents the properties of a row:
   * Some properties which this function is concerned about:
   * `senderId` - The user who initially posted that message.
   * `itemType` - Determines whether it is a comment or a post
   * `id` - Unique Id representing a post
   * `senderProfileImageUrl` - Url for profile pic of the sender
   */
  renderActionsButtonPanel = rowData => {
    const { sasToken } = this.props;
    const {
      senderProfileImageUrl,
      senderId,
      itemType,
      id,
      reportedBy,
      action
    } = rowData;
    const feedData = {
      id,
      senderId,
      itemType,
      imageUrl: appendSASToken(sasToken, senderProfileImageUrl)
    };
    const hasReporter = reportedBy.length >= 2;
    if (hasReporter || action != null) {
      return (
        <AllowRemoveAction
          onAllowClicked={() => this.props.allowAction(feedData)}
          onRemoveClicked={() => this.props.removeAction(feedData)}
          hasReporter={hasReporter}
          action={action}
        />
      );
    }
  };

  // Function which is used to prepare Table
  renderTable = data => (
    <Table basic="very" className="table-view">
      <Table.Header>{this.renderHeader()}</Table.Header>
      <Table.Body>{this.renderBody(data)}</Table.Body>
    </Table>
  );

  render() {
    const { data, hasMore } = this.props;
    const dataLength = data.length;
    return (
      <div className="table-view-container">
        <InfiniteScroller
          dataLength={dataLength}
          hasMore={hasMore}
          fetchMore={this.fetchData}
        >
          {this.renderTable(data)}
        </InfiniteScroller>
      </div>
    );
  }
}

export default TableView;
