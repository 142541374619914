import cookie from '../utils/cookie';

const setSession = (accessToken, expiresIn) => {
  cookie.set('_at', JSON.stringify(accessToken), expiresIn); // set expiration time for access token
};

/**
 * @description
 * Calling this method changes the browsers' addressbar url to '/login'
 * and shows the login page.
 */
const redirectToLogin = () => {
  window.location.assign('/login');
};

/**
 * @description Gets the access token from cookie. If not found it send null
 */

const getAccessToken = () => {
  let accessToken;
  try {
    accessToken = JSON.parse(cookie.get('_at'));
  } catch (e) {
    accessToken = null;
  }
  return accessToken;
};

/**
 * @description Delete the current user session
 */

const deleteSession = () => {
  cookie.delete('_at');
};

/**
 * @description Verifies if there are any existing session and if it is valid,
 * it moves directly to the home page.
 * Every access-token received from the server is stored in browser's cookie storage.
 * This method checks if there's an accesstoken stored already.
 *
 * Implement logic to validate the existing session based on the time the accessToken
 * was stored in cookie-storage.
 */
const isUserLoggedIn = () => {
  // return true;
  const accessToken = getAccessToken();

  if (accessToken) {
    return true;
  }
  return false;
};

/**
 * @description
 * Calling this method changes the browsers' addressbar url to '/logout'
 * and logs out the user from the application and shows the login page.
 */
const logout = () => {
  window.location.assign('/logout');
};

export {
  setSession,
  getAccessToken,
  deleteSession,
  isUserLoggedIn,
  logout,
  redirectToLogin
};
