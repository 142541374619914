// Importing action file
import { ACTIONS } from '../actions/actionType';
import CONSTANTS from '../../constants';
import { getIsEmojiSupported } from '../../utils/utils';

const createAlertMessage = data => {
  if (data) {
    const { message, alertType } = data;
    if (alertType === CONSTANTS.ADMIN_AND_MODERATORS.alertMessageType.delete) {
      return CONSTANTS.ADMIN_AND_MODERATORS.removeAlertMessage.replace(
        '%s',
        message
      );
    }
    return message;
  }
  return null;
};

/**
 * @description
 * Each removalReason object has these keys: `id`, `description`, `seq`.
 * But this array is going to be used with `RoundedDropdown` for which
 * it only accepts options if they have the following keys: `text`, `value`.
 * So, this array is transformed in to the type that is acceptable by
 * `RoundedDropdown`
 * @param {array} removalReasons - List of reasons to remove a post
 * @returns {array} removalReasons - In alphabetically sorted order
 */
const transformRemovalReasons = removalReasons =>
  removalReasons
    .map(item => ({
      text: item.description,
      value: item.id
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

const initialState = {
  sasToken: {},
  alertMessage: null,
  userRole: {},
  notificationCount: null,
  isEmojiSupported: getIsEmojiSupported(),
  removalReasons: [],
  actionModal: {
    show: false,
    isAllowAction: false,
    itemType: null,
    imageUrl: null,
    userDetail: null
  },
  warningModal: {
    show: false,
    message: ''
  }
};

const Common = (state = initialState, action) => {
  const tempState = { ...state };
  switch (action.type) {
    case ACTIONS.COMMON.QUEUE_ACTION_ON_REFRESH_SAS_TOKEN: {
      tempState.sasToken = { ...tempState.sasToken, isRefreshing: true };
      return tempState;
    }
    case ACTIONS.COMMON.GET_SAS_TOKEN_SUCCESS: {
      tempState.sasToken = { ...action.data, isRefreshing: false };
      return tempState;
    }
    case ACTIONS.COMMON.SET_ALERT_MESSAGE: {
      tempState.alertMessage = createAlertMessage(action.data);
      return tempState;
    }
    case ACTIONS.COMMON.GET_USER_ROLE_SUCCESS: {
      tempState.userRole = { ...action.data };
      return tempState;
    }
    case ACTIONS.COMMON.RESET_COMMON_REDUCER: {
      tempState.sasToken = { ...initialState.sasToken };
      tempState.userRole = { ...initialState.userRole };
      return tempState;
    }
    case ACTIONS.COMMON.GET_SAS_TOKEN_FAIL: {
      tempState.sasToken = { ...tempState.sasToken, isRefreshing: false };
      return tempState;
    }
    case ACTIONS.COMMON.GET_NOTIFICATION_COUNT_SUCCESS: {
      tempState.notificationCount = action.data.page.totalElements;
      return tempState;
    }
    case ACTIONS.COMMON.GET_NOTIFICATION_COUNT_FAIL: {
      tempState.notificationCount = null;
      return tempState;
    }
    case ACTIONS.COMMON.GET_USER_ROLE_FAIL: {
      return tempState;
    }
    case ACTIONS.COMMON.FEED_REMOVAL_REASON_SUCCESS: {
      tempState.removalReasons = transformRemovalReasons(action.data);
      return tempState;
    }
    case ACTIONS.COMMON.FEED_REMOVAL_REASON_FAIL: {
      tempState.removalReasons = [...initialState.removalReasons];
      return tempState;
    }
    case ACTIONS.COMMON.SHOW_ACTION_MODAL:
      tempState.actionModal = {
        ...state.actionModal,
        ...action.data,
        show: true
      };
      return tempState;
    case ACTIONS.COMMON.FETCH_USER_DETAIL_SUCCESS:
      tempState.actionModal = {
        userDetail: {
          ...action.data
        }
      };
      return tempState;
    case ACTIONS.COMMON.HIDE_ACTION_MODAL:
    case ACTIONS.COMMON.ALLOW_REMOVE_POST_SUCCESS:
    case ACTIONS.COMMON.FETCH_USER_DETAIL_FAIL:
      tempState.actionModal = {
        ...initialState.actionModal
      };
      return tempState;
    case ACTIONS.COMMON.ALLOW_REMOVE_DONE_ALREADY:
      tempState.actionModal = { ...initialState.actionModal };
      tempState.warningModal = {
        show: true,
        message: action.data.message
      };
      return tempState;
    case ACTIONS.COMMON.DISMISS_WARNING_MODAL:
      tempState.warningModal = { ...initialState.warningModal };
      return tempState;
    default:
      return tempState;
  }
};

export default Common;
