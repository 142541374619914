import { call, put } from 'redux-saga/effects';
import { constructQueryParams } from '../../utils/utils';
import { getData } from '../../utils/axios';

import API from '../../API';
import { ACTIONS } from '../actions/actionType';

function* fetchSearchedUsers(action) {
  let response;
  if (action.data.searchTerm) {
    try {
      const URL = `${API.searchUrl}?${constructQueryParams(action.data)}`;
      response = yield call(getData, URL);
      if (response.status === 200) {
        yield put({
          type: ACTIONS.SEARCH.FETCH_SEARCHED_USER_SUCCESS,
          data: response.data
        });
      } else {
        yield put({
          type: ACTIONS.SEARCH.FETCH_SEARCHED_USER_FAIL,
          data: response.data
        });
      }
    } catch (error) {
      yield put({
        type: ACTIONS.SEARCH.FETCH_SEARCHED_USER_FAIL,
        data: error
      });
    }
  } else {
    // Clear reducer if searchText is empty
    yield put({
      type: ACTIONS.SEARCH.CLEAR_SEARCHED_USER
    });
  }
}

function* loadMoreSearchedUsers(action) {
  let response;
  try {
    const URL = `${API.searchUrl}?${constructQueryParams(action.data)}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.SEARCH.LOAD_MORE_SEARCHED_USER_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.SEARCH.LOAD_MORE_SEARCHED_USER_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.SEARCH.LOAD_MORE_SEARCHED_USER_FAIL,
      data: error
    });
  }
}

function* fetchUserProfileTask(action) {
  let response;
  try {
    const URL = `${API.taskList}?${constructQueryParams(action.data)}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.SEARCH.FETCH_USER_PROFILE_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.SEARCH.FETCH_USER_PROFILE_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.SEARCH.FETCH_USER_PROFILE_FAIL,
      data: error
    });
  }
}

function* getUserPersonalDetails(action) {
  let response;
  try {
    const URL = `${API.searchUrl}/${action.data.userId}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.SEARCH.USER_PERSONAL_DETAILS_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.SEARCH.USER_PERSONAL_DETAILS_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.SEARCH.USER_PERSONAL_DETAILS_FAIL,
      data: error
    });
  }
}

export {
  fetchSearchedUsers,
  fetchUserProfileTask,
  getUserPersonalDetails,
  loadMoreSearchedUsers
};
