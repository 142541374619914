import React from 'react';
import PropTypes from 'prop-types';

import './Header.scss';

const Header = props => {
  const { title, children } = props;
  return (
    <header className="header-wrapper">
      <h1 className="title">{title}</h1>
      <div className="operational-section">{children}</div>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any
};

Header.defaultProps = {
  children: null
};

export default Header;
