import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { Header } from '../../../components';
import {
  Button,
  SearchBar,
  UserDetails,
  SvgImage,
  SelectionButton,
  Loader,
  PaginatedButton
} from '../../../common';
import {
  replaceWithNA,
  isEmptyString,
  isNullOrUndefined,
  parseQueryParams,
  convertDateRangeToUTC
} from '../../../utils/utils';
import {
  fetchUserList,
  updatedSelectedUsersList,
  clearUserList,
  updateReportInputs
} from '../../../redux/actions/Report.actions';
import CONSTANTS from '../../../constants';

import './UserSelection.scss';
import removeIcon from '../../../assets/images/remove-icon.svg';
// import ENUM from '../../../enum';

class UserSelection extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    sasToken: PropTypes.object.isRequired,
    onSearchUsers: PropTypes.func.isRequired,
    report: PropTypes.object.isRequired,
    onUserSelected: PropTypes.func.isRequired,
    onClearUserList: PropTypes.func.isRequired,
    onUpdateReportInputs: PropTypes.func.isRequired
  };

  state = {
    searchTerm: '',
    showSelectedUsers: false
  };

  onKeyUp = debounce(() => {
    const { page } = this.props.report.searchResult.page;
    if (this.state.searchTerm.length > 2) {
      this.fetchUsers(page);
    }
  }, 300);

  /**
   * @description
   * Navigates the application to reports page where user can select the values for
   * "Dispositions", "Reasons to remove" etc.
   */
  gotoReportsHomePage = () => {
    const url = `/report${this.props.history.location.search}`;
    this.props.history.push(url);
  };

  /**
   * @description
   * Updates the redux store with selected inputs obtained as query-params and
   * navigates the application to summary page where the report-summary is loaded.
   */
  generateReport = () => {
    this.updateQueryParamsToStore();
    this.props.history.push('/report/summary');
  };

  /**
   * @description
   * Parses the query params and updates the store with input values.
   * So that the inputs are preserved on navigating to Report Summary page
   */
  updateQueryParamsToStore = () => {
    const queryParams = this.props.history.location.search;
    if (!isEmptyString(queryParams) && !isNullOrUndefined(queryParams)) {
      const inputs = parseQueryParams(queryParams);
      const { start, end } = inputs;
      const dateRange = convertDateRangeToUTC(start, end);
      this.props.onUpdateReportInputs({
        selectedDispositionType: inputs.disposition,
        selectedRemovalReason: inputs.removalReason || 0,
        selectedPostsByOption: inputs.postsBy,
        selectedDateRange: inputs.dateRange,
        ...dateRange
      });
    }
  };

  /**
   * @description
   * This method gets called everytime when user types something in the search field.
   * It updates the internal state with the new search term and also dispatches
   * an action to fetch users based on the search field.
   */
  updateSearchTerm = searchTerm => this.setState({ searchTerm });

  /**
   * @description
   * This method constructs the input object with the search term that lists the users
   * with matching search term.
   */
  fetchUsers = page => {
    const searchTerm = this.state.searchTerm.trim();
    if (isEmptyString(searchTerm)) {
      this.props.onClearUserList();
    } else {
      const { PAGE_SIZE: size } = CONSTANTS.REPORT.USER_SELECTION;
      const sort = 'lastName,asc';
      const data = { page, size, searchTerm, sort };
      this.props.onSearchUsers(data);
    }
  };

  /**
   * @description
   * Renders Table header, based on header value set in constants file.
   *
   * @returns {ReactComponent} return table head
   */
  renderTableHeader = () => {
    const { TABLE_HEADER } = CONSTANTS.REPORT.USER_SELECTION;
    return (
      <Table.Row>
        {TABLE_HEADER.map((header, index) => (
          <Table.HeaderCell key={index} className="table-head">
            {header}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    );
  };

  /**
   * @description
   * This method appends SAS token to the image Url if there is an image Url, else returns null;
   *
   * @param {Object} rowData - row entry in the table
   */
  appendSASToken = rowData => {
    const { sharedAccessSignature } = this.props.sasToken;
    return rowData.imageUrl && sharedAccessSignature
      ? `${rowData.imageUrl}?${sharedAccessSignature}`
      : null;
  };

  // This is used to format data which which can push to table for rendering
  renderTableData = data =>
    data.map(rowData => (
      <Table.Row
        key={`${rowData.id}-${rowData.itemType}`}
        className="table-row"
      >
        <Table.Cell className="table-data">
          <UserDetails
            name={`${rowData.lastName}, ${rowData.firstName}`}
            imageUrl={this.appendSASToken(rowData)}
            currentRole={rowData.currentRole}
          />
        </Table.Cell>
        <Table.Cell className="table-data">{rowData.emp34}</Table.Cell>
        <Table.Cell className="table-data">
          {replaceWithNA(rowData.unitName)}
        </Table.Cell>
        <Table.Cell className="table-data">
          {replaceWithNA(rowData.hospitalName)}
        </Table.Cell>
        <Table.Cell className="table-data">
          {replaceWithNA(rowData.divisionName)}
        </Table.Cell>
        <Table.Cell className="table-data">
          {this.renderPostsCount(rowData.postCount, rowData.reportedCount)}
        </Table.Cell>
        <Table.Cell className="table-data">
          {this.state.showSelectedUsers
            ? this.renderRemoveUserButton(rowData.id, rowData)
            : this.renderSelectionButton(rowData.id, rowData)}
        </Table.Cell>
      </Table.Row>
    ));

  /**
   * @description
   * Checks the internally maintained map (which holds the selected users),
   * if there are any entries present.
   * @returns {boolean} - True - if no users are selected and False, otherwise.
   */
  hasAnyUserSelected = () => {
    return Object.keys(this.props.report.selectedUsers).length !== 0;
  };

  /**
   * @description
   * Returns the ReactComponent UI which has the post count and reported count
   * that is used in the results table.
   */
  renderPostsCount = (postCount, reportedCount) => {
    return (
      <div className="post-status">
        <div>{postCount} Posts</div>
        <div>{reportedCount} Reports</div>
      </div>
    );
  };

  /**
   * @description
   * This method gets called every time when a user is selected/unselected.
   * It dispatches an action with userId, userInfo and previously selected users.
   *
   * @param {number} userId - unique id representing every entry in the table.
   * @param {object} userInfo - the entire row's data object in the table.
   */
  updateUserSelection = (userId, userInfo) => {
    const { selectedUsers } = this.props.report;
    this.props.onUserSelected({ userId, userInfo, selectedUsers });
  };

  /**
   * @description
   * Similar to the method `updateUserSelection`. But this is called when a user is removed from selection.
   * It dispatches an action with userId, userInfo and previously selected users.
   * It also toggles the view to search results if there's no user in the selected list.
   *
   * @param {number} userId - unique id representing every entry in the table.
   * @param {object} userInfo - the entire row's data object in the table.
   */
  removeSelectedUser = (userId, userInfo) => {
    if (Object.keys(this.props.report.selectedUsers).length === 1) {
      this.setState({ showSelectedUsers: false });
    }
    this.updateUserSelection(userId, userInfo);
  };

  /**
   * @description
   * The tab button name should also show the total number of selected users.
   * This method appends the count to the button name resulting in this format:
   * Example:  `Selected (2)`
   * @returns {string} - Button name with count of selected users
   */
  formatSelectedButtonCount = () => {
    const selectedUsersCount = Object.keys(this.props.report.selectedUsers)
      .length;
    const { SELECTED } = CONSTANTS.COMMON;
    return `${SELECTED} (${selectedUsersCount})`;
  };

  /**
   * @description
   * Renders the last column of the results table - A button to toggle selection.
   * Clicking on it either selects or unselects the user
   *
   * @param {number} userId - unique id representing the entry in the results table
   * @param {object} userInfo - the entire row's data object in the table.
   */
  renderSelectionButton = (userId, rowData) => {
    const { selectedUsers } = this.props.report;
    const checked = !isNullOrUndefined(selectedUsers[userId]);
    return (
      <SelectionButton
        selected={checked}
        onChange={() => this.updateUserSelection(userId, rowData)}
      />
    );
  };

  /**
   * @description
   * Renders the last column of the selected users table - Remove Button.
   * Clicking on it removes the user from selected users list.
   *
   * @param {number} userId - unique id representing the entry in the results table
   * @param {object} userInfo - the entire row's data object in the table.
   */
  renderRemoveUserButton = (userId, rowData) => {
    return (
      <SvgImage
        src={removeIcon}
        alt="Remove"
        onClick={() => this.removeSelectedUser(userId, rowData)}
        className="btn-remove"
      />
    );
  };

  /**
   * @description
   * Renders the result table which contains the list of users that matches
   * the search term.
   *
   * NOTE: only top 5 matching entries are displayed in the table.
   */
  renderResultsTable = () => {
    const { PAGE_SIZE } = CONSTANTS.REPORT.USER_SELECTION;
    const { searchResult, hasDataLoaded, fetchingUsers } = this.props.report;

    const data = searchResult.users;
    if (fetchingUsers) {
      return <Loader alt={CONSTANTS.COMMON.LOADING} />;
    }
    // Render no data message if data is not found
    if (hasDataLoaded && (data == null || data.length === 0)) {
      return (
        <p className="data-not-found">
          {CONSTANTS.ADMIN_AND_MODERATORS.emptySearchResult}
        </p>
      );
    }
    const {
      page: currentPage,
      totalPages
    } = this.props.report.searchResult.page;

    const results = data.slice(0, PAGE_SIZE);

    return (
      <>
        <Table basic="very" className="table-view">
          <Table.Header>{this.renderTableHeader()}</Table.Header>
          <Table.Body>{this.renderTableData(results)}</Table.Body>
        </Table>
        <footer>
          <PaginatedButton
            prevClick={this.fetchUsers}
            nextClick={this.fetchUsers}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </footer>
      </>
    );
  };

  /**
   * @description
   * Renders the selected users table which contains the list of users
   * that are selected for report generation.
   */
  renderSelectedUsersTable = () => {
    const { selectedUsers } = this.props.report;

    const results = Object.keys(selectedUsers).map(key => selectedUsers[key]);
    return (
      <div className="scrollable">
        <Table basic="very" className="table-view">
          <Table.Header>{this.renderTableHeader()}</Table.Header>
          <Table.Body>{this.renderTableData(results)}</Table.Body>
        </Table>
      </div>
    );
  };

  render() {
    const { searchTerm, showSelectedUsers } = this.state;
    const { USER_SEARCH: title } = CONSTANTS.REPORT.TITLE;

    // search results count is restricted to maximum of 5 users (ie., PAGE_SIZE)
    const renderResultsTable = !isEmptyString(searchTerm)
      ? this.renderResultsTable()
      : null;

    const renderSelectedUsersTable = this.renderSelectedUsersTable();

    const searchResults = this.props.report.searchResult.users;
    const btnPanelClasses = cx([
      'user-selection-button-panel',
      {
        'with-search-results':
          searchResults != null &&
          searchResults.length > 0 &&
          (!isEmptyString(searchTerm) || this.hasAnyUserSelected())
      }
    ]);
    const isGenerateBtnDisabled = !this.hasAnyUserSelected();
    const generateBtnCss = cx([
      isGenerateBtnDisabled ? 'btn-primary-disabled' : 'btn-primary'
    ]);

    const tabHeaderUsersCss = cx([
      'btn btn-empty',
      {
        'btn-users-active': !showSelectedUsers
      }
    ]);

    const tabHeaderSelectedCss = cx([
      'btn btn-empty',
      {
        'btn-selected-active': showSelectedUsers
      }
    ]);

    return (
      <div className="report-container container">
        <Header title={title} />
        <main className="user-selection">
          <SearchBar
            customClass="user-search"
            value={searchTerm}
            onChange={this.updateSearchTerm}
            disabled={this.state.showSelectedUsers}
            onKeyUp={this.onKeyUp}
          />

          {/* Tab header buttons to toggle the tables: */}
          {this.hasAnyUserSelected() && (
            <section className="tab-header">
              <Button
                value={CONSTANTS.COMMON.USERS}
                customClass={tabHeaderUsersCss}
                size="small"
                onClick={() => this.setState({ showSelectedUsers: false })}
              />
              <Button
                value={this.formatSelectedButtonCount()}
                customClass={tabHeaderSelectedCss}
                onClick={() => this.setState({ showSelectedUsers: true })}
              />
            </section>
          )}

          {/* Show either the results table or the selected users table based on what the user has selected */}
          {this.state.showSelectedUsers
            ? renderSelectedUsersTable
            : renderResultsTable}
        </main>

        {/* footer button panel */}
        <section className={btnPanelClasses}>
          <Button
            value={CONSTANTS.COMMON.BACK}
            customClass="btn-secondary"
            onClick={this.gotoReportsHomePage}
          />
          <Button
            value={CONSTANTS.COMMON.GENERATE}
            customClass={generateBtnCss}
            onClick={this.generateReport}
            disabled={isGenerateBtnDisabled}
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sasToken: state.common.sasToken,
  report: state.report
});

const mapDispatchToProps = dispatch => ({
  onSearchUsers: data => dispatch(fetchUserList(data)),
  onUserSelected: data => dispatch(updatedSelectedUsersList(data)),
  onClearUserList: () => dispatch(clearUserList()),
  onUpdateReportInputs: data => dispatch(updateReportInputs(data))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserSelection)
);
