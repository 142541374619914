import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { logout, isUserLoggedIn } from '../services/AuthService';
import CONSTANTS from '../constants';
import Navigation from './Navigation/Navigation';
import { Loader } from '../common';
import {
  getSASToken,
  getUserRole,
  getNotificationCount
} from '../redux/actions/Common.actions';

import './MainLayout.scss';

class MainLayout extends Component {
  static propTypes = {
    children: PropTypes.object,
    common: PropTypes.object.isRequired,
    fetchSASToken: PropTypes.func.isRequired,
    fetchUserRole: PropTypes.func.isRequired,
    fetchNotificationCount: PropTypes.func.isRequired
  };

  static defaultProps = {
    children: {}
  };

  state = {
    sessionTimeOutId: null
  };

  constructor(props) {
    super(props);
    this.sessionTimer = debounce(this.sessionTimer, 100); // Debounce the session timer function
  }

  componentDidMount() {
    // Register the event listeners that define session activity on mount of the Main Layout
    this.registerActivityListeners();

    this.fetchPrerequisiteData();
  }

  componentWillUnmount() {
    const { sessionTimeOutId } = this.state;
    // Clear the existing timeout on component unmount
    if (sessionTimeOutId) {
      clearTimeout(sessionTimeOutId);
    }
    // Unregister the event listeners that define session activity on unmount of the Main Layout
    this.unregisterActivityListeners();
  }

  registerActivityListeners = () => {
    window.addEventListener('mousemove', this.sessionTimer);
    window.addEventListener('mousedown', this.sessionTimer);
    window.addEventListener('keypress', this.sessionTimer);
    window.addEventListener('scroll', this.sessionTimer);
  };

  unregisterActivityListeners = () => {
    window.removeEventListener('mousemove', this.sessionTimer);
    window.removeEventListener('mousedown', this.sessionTimer);
    window.removeEventListener('keypress', this.sessionTimer);
    window.removeEventListener('scroll', this.sessionTimer);
  };

  /**
   * @description This function saves the id of the timeOut function.
   * The id is linked with the component state and replaced with the new id whenever the function is called.
   */

  sessionTimer = () => {
    const { sessionTimeOutId } = this.state;

    if (sessionTimeOutId) {
      clearTimeout(sessionTimeOutId);
    }

    if (!isUserLoggedIn()) return;

    const newSessionTimeOutId = setTimeout(
      this.onSessionInactive,
      CONSTANTS.AUTH.sessionTimeoutDurationInMs
    );

    this.setState({ sessionTimeOutId: newSessionTimeOutId });
  };

  /**
   * @description
   * This method is called when the session is inactive for more than 20mins.
   * It sets a flag in localStorage representing that the logout
   * happened due to inactivity and logs out.
   * With the flag being set, the login page can recognize the reason for
   * logging out and show appropriate message in the login screen.
   */
  onSessionInactive = () => {
    localStorage.setItem('wasInactive', true);
    logout();
  };

  fetchPrerequisiteData = () => {
    const { sasToken, userRole, notificationCount } = this.props.common;
    if (Object.keys(sasToken).length === 0) {
      // API Call to fetch SAS Token
      this.props.fetchSASToken();
    }
    if (Object.keys(userRole).length === 0) {
      // Fetch user role. If not exist
      this.props.fetchUserRole();
    }
    if (!notificationCount) {
      // Fetch notification count
      this.props.fetchNotificationCount();
    }
  };

  render() {
    const { children } = this.props;
    const { sasToken, userRole, notificationCount } = this.props.common;

    // Show loader until sasToken and user role is obtained from server.
    if (
      Object.keys(userRole).length === 0 ||
      Object.keys(sasToken).length === 0
    ) {
      return <Loader />;
    }

    return (
      <div style={{ display: 'block', width: '100%' }}>
        <aside>
          <Navigation notificationCount={notificationCount} />
        </aside>
        <header className="top-bar">
          <p className="title">
            <span className="name">HCA Inspire </span> Moderator Tool
          </p>
        </header>
        <main
          style={{ display: 'inline-block', width: '90%', marginTop: '30px' }}
        >
          {children}
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  common: state.common
});

const mapDispatchToProps = dispatch => ({
  fetchSASToken: () => dispatch(getSASToken()),
  fetchUserRole: () => dispatch(getUserRole()),
  fetchNotificationCount: () => dispatch(getNotificationCount())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout);
