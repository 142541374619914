// Importing action file
import { ACTIONS } from '../actions/actionType';
import ENUM from '../../enum';
import CONSTANTS from '../../constants';

const { DEFAULT_REMOVAL_REASON } = CONSTANTS.REPORT.DROPDOWN_OPTIONS;

const initialState = {
  hasDataLoaded: false,
  selectedDispositionType: ENUM.DISPOSITION_TYPES.ALL_DISPOSITIONS,
  selectedRemovalReason: DEFAULT_REMOVAL_REASON.value,
  selectedPostsByOption: ENUM.POSTS_BY.ALL_HCA,
  selectedDateRange: ENUM.DATE_RANGE.ANY_TIME,
  fetchingUsers: false,
  fetchingDivisions: false,
  startDate: '',
  endDate: '',
  selectedUsers: {},
  selectedDivisions: {},
  searchResult: {
    users: [],
    divisions: [],
    page: {
      page: 0,
      size: 0,
      totalElements: 0,
      totalPages: 0
    }
  },
  reportResults: []
};

const Report = (state = initialState, action) => {
  const tempState = { ...state };
  switch (action.type) {
    case ACTIONS.REPORT.FETCH_USER_LIST: {
      tempState.fetchingUsers = true;
      return tempState;
    }
    case ACTIONS.REPORT.FETCH_DIVISION_LIST: {
      tempState.fetchingDivisions = true;
      return tempState;
    }
    case ACTIONS.REPORT.FETCH_REPORT_SUMMARY: {
      tempState.hasDataLoaded = false;
      return tempState;
    }
    case ACTIONS.REPORT.FETCH_USER_LIST_SUCCESS: {
      tempState.searchResult = {
        users: [...action.data._embedded.resources],
        page: { ...action.data.page }
      };
      tempState.hasDataLoaded = true;
      tempState.fetchingUsers = false;
      return tempState;
    }
    case ACTIONS.REPORT.FETCH_USER_LIST_FAIL: {
      tempState.searchResult = {
        ...initialState.searchResult
      };
      tempState.hasDataLoaded = true;
      return tempState;
    }
    case ACTIONS.REPORT.UPDATE_SELECTED_USERS_LIST: {
      tempState.selectedUsers = {
        ...action.selectedUsers
      };
      return tempState;
    }
    case ACTIONS.REPORT.CLEAR_USER_LIST: {
      tempState.searchResult = {
        ...initialState.searchResult
      };
      return tempState;
    }
    case ACTIONS.REPORT.FETCH_DIVISION_LIST_SUCCESS: {
      tempState.searchResult = {
        divisions: [...action.data._embedded.resources],
        page: { ...action.data.page }
      };
      tempState.hasDataLoaded = true;
      tempState.fetchingDivisions = false;
      return tempState;
    }
    case ACTIONS.REPORT.FETCH_DIVISION_LIST_FAIL: {
      tempState.searchResult = {
        ...initialState.searchResult
      };
      tempState.hasDataLoaded = true;
      return tempState;
    }
    case ACTIONS.REPORT.UPDATE_SELECTED_DIVISIONS_LIST: {
      tempState.selectedDivisions = {
        ...action.selectedDivisions
      };
      return tempState;
    }
    case ACTIONS.REPORT.CLEAR_DIVISION_LIST: {
      tempState.searchResult = {
        ...initialState.searchResult
      };
      return tempState;
    }
    case ACTIONS.REPORT.UPDATE_REPORT_INPUTS: {
      tempState.selectedDispositionType = action.data.selectedDispositionType;
      tempState.selectedRemovalReason = action.data.selectedRemovalReason;
      tempState.selectedPostsByOption = action.data.selectedPostsByOption;
      tempState.selectedDateRange = action.data.selectedDateRange;
      tempState.startDate = action.data.startDate;
      tempState.endDate = action.data.endDate;
      return tempState;
    }
    case ACTIONS.REPORT.RESET_STATE: {
      return { ...initialState };
    }
    case ACTIONS.REPORT.FETCH_REPORT_SUMMARY_SUCCESS: {
      tempState.hasDataLoaded = true;
      tempState.reportResults = [...action.data._embedded.resources];
      return tempState;
    }
    case ACTIONS.REPORT.FETCH_REPORT_SUMMARY_FAIL: {
      tempState.hasDataLoaded = true;
      tempState.reportResults = initialState.reportResults;
      return tempState;
    }
    default:
      return tempState;
  }
};

export default Report;
