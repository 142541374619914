import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';

// Importing layout
import EmptyLayoutRoute from './layout/EmptyLayoutRoute';
import MainLayoutRoute from './layout/MainLayoutRoute';

// Render component list.
import LoginContainer from './container/Login/Login';
import AuthCallBack from './container/Login/AuthCallBack/AuthCallBack';
import TaskListContainer from './container/TaskList/TaskList';
import ReportContainer from './container/Report/Report';
import UserSelectionContainer from './container/Report/UserSelection/UserSelection';
import DivisionSelectionContainer from './container/Report/DivisionSelection/DivisionSelection';
import ReportSummaryContainer from './container/Report/ReportSummary/ReportSummary';
import SearchContainer from './container/Search/Search';
import UserProfileComponent from './container/UserProfile/UserProfile';
import AdminModerateContainer from './container/ModeratorList/ModeratorList';
import Logout from './container/Logout/Logout';
import CONSTANTS from './constants';

import 'semantic-ui-css/semantic.min.css';
import './App.scss';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <EmptyLayoutRoute
        exact
        path="/login"
        component={LoginContainer}
        type="login"
      />
      <EmptyLayoutRoute
        path="/authcallback"
        component={AuthCallBack}
        type="login"
      />
      <EmptyLayoutRoute exact path="/logout" component={Logout} type="logout" />
      <MainLayoutRoute
        path={CONSTANTS.COMMON.homepage}
        component={TaskListContainer}
        type="taskList"
      />
      <MainLayoutRoute
        path="/report/by-person"
        component={UserSelectionContainer}
        type="report"
      />
      <MainLayoutRoute
        path="/report/by-division"
        component={DivisionSelectionContainer}
        type="report"
      />
      <MainLayoutRoute
        path="/report/summary"
        component={ReportSummaryContainer}
        type="reportSummary"
      />
      <MainLayoutRoute
        path="/report"
        component={ReportContainer}
        type="report"
      />
      <MainLayoutRoute
        path="/search"
        component={SearchContainer}
        type="search"
      />
      <MainLayoutRoute
        path="/profile/:userId"
        component={UserProfileComponent}
        type="userProfile"
      />
      <MainLayoutRoute
        path="/moderator-list"
        component={AdminModerateContainer}
        type="moderatorList"
      />
      <Redirect to={CONSTANTS.COMMON.homepage} />
    </Switch>
  </Router>
);

export default App;
