// Importing action file
import { ACTIONS } from '../actions/actionType';

const initialState = {
  superAdminList: [],
  superAdminPage: {
    page: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  },
  initialDataLoaded: false,
  superUserSearchLoaded: true,
  modifiedRoles: {},
  showAddUserModal: false,
  user: {
    list: [],
    page: {
      number: 0,
      size: 0,
      totalElements: 0,
      totalPages: 0
    },
    modifiedRoles: {},
    searchDataLoaded: true,
    isInitialState: true
  }
};

const ModeratorList = (state = initialState, action) => {
  const tempState = { ...state };
  switch (action.type) {
    case ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST: {
      tempState.superUserSearchLoaded = false;
      return tempState;
    }
    case ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST_SUCCESS: {
      tempState.superAdminList = [...action.data._embedded.resources];
      tempState.superAdminPage = { ...action.data.page };
      tempState.initialDataLoaded = true;
      tempState.superUserSearchLoaded = true;
      return tempState;
    }
    case ACTIONS.ADMIN_MODERATOR.GET_SUPER_USER_LIST_FAIL: {
      tempState.superAdminList = initialState.superAdminList;
      tempState.superAdminPage = initialState.superAdminPage;
      tempState.initialDataLoaded = true;
      tempState.superUserSearchLoaded = true;
      return tempState;
    }
    case ACTIONS.ADMIN_MODERATOR.CLEAR_SUPER_USER_LIST: {
      tempState.superAdminList = initialState.superAdminList;
      tempState.superAdminPage = initialState.superAdminPage;
      tempState.initialDataLoaded = initialState.initialDataLoaded;
      tempState.superUserSearchLoaded = initialState.superUserSearchLoaded;
      return tempState;
    }
    case ACTIONS.ADMIN_MODERATOR.DELETE_SUPER_USER_FAIL:
    case ACTIONS.ADMIN_MODERATOR.UPDATE_SUPER_USER_ROLES_FAIL:
      return tempState;
    case ACTIONS.ADMIN_MODERATOR.UPDATE_SUPER_USER_ROLES_SUCCESS:
      tempState.modifiedRoles = {};
      tempState.user.modifiedRoles = {};
      return tempState;
    case ACTIONS.ADMIN_MODERATOR.UPDATE_SUPER_USER_ROLES_MODIFIED:
      tempState.modifiedRoles = { ...action.modifiedRoles };
      return tempState;
    case ACTIONS.ADMIN_MODERATOR.GET_USER_LIST: {
      tempState.user = {
        ...state.user,
        searchDataLoaded: false,
        isInitialState: false
      };
      return tempState;
    }
    case ACTIONS.ADMIN_MODERATOR.GET_USER_LIST_SUCCESS: {
      tempState.user = {
        ...state.user,
        list: [...action.data._embedded.resources],
        page: { ...action.data.page },
        searchDataLoaded: true,
        isInitialState: false
      };
      return tempState;
    }
    case ACTIONS.ADMIN_MODERATOR.GET_USER_LIST_FAIL: {
      tempState.user = {
        ...initialState.user,
        isInitialState: false
      };
      return tempState;
    }
    case ACTIONS.ADMIN_MODERATOR.CLEAR_USER_LIST: {
      tempState.user = {
        ...initialState.user
      };
      return tempState;
    }
    case ACTIONS.ADMIN_MODERATOR.UPDATE_USER_ROLES_MODIFIED:
      tempState.user = {
        ...state.user,
        modifiedRoles: { ...action.modifiedRoles }
      };
      return tempState;
    case ACTIONS.ADMIN_MODERATOR.SHOW_ADD_USERS_MODAL:
      tempState.showAddUserModal = true;
      return tempState;
    case ACTIONS.ADMIN_MODERATOR.HIDE_ADD_USERS_MODAL:
      tempState.showAddUserModal = false;
      tempState.user = { ...initialState.user };
      return tempState;
    case ACTIONS.ADMIN_MODERATOR.DELETE_SUPER_USER_SUCCESS:
      delete tempState.modifiedRoles[action.data];
      return tempState;
    default:
      return tempState;
  }
};

export default ModeratorList;
