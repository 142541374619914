import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CONSTANTS from '../../constants';
import ENUM from '../../enum';
import { unescapeString } from '../../utils/utils';
import EmojiText from '../../common/EmojiText/EmojiText';
import { ImgWithExif } from '../../common';
import './ContentBlock.scss';

class ContentBlock extends Component {
  static propTypes = {
    text: PropTypes.any,
    imageUrl: PropTypes.any,
    stickerUnicode: PropTypes.any,
    stickerTitle: PropTypes.any,
    targetName: PropTypes.any,
    feedItemType: PropTypes.any,
    fromCard: PropTypes.bool,
    badgeName: PropTypes.string,
    isEmojiSupported: PropTypes.bool.isRequired
  };

  static defaultProps = {
    text: '',
    badgeName: '',
    imageUrl: null,
    feedItemType: null,
    stickerUnicode: null,
    stickerTitle: null,
    targetName: null,
    fromCard: false
  };

  state = {
    readMore: false
  };

  /**
   * @description
   * This method is used to toggle readMore state.
   *
   * @param {Boolean} bool If true, then readMore will be set to true, i.e Read More block gets expanded. Else if false then Read More block will be closed.
   */
  setReadMore = bool => this.setState({ readMore: bool });

  /**
   * @description
   * This method will render text as it is, if the number of character is less then CHAR_LIMIT(140) character.
   * Else this will create an component, where READ MORE / CLOSE button with respective toggling will happen.
   *
   * @argument readMore true, then full text with CLOSE button will appear.
   * @argument readMore false, then substring from starting to character, with Read more button will appear.
   *
   * @param {String} text Comment/Feed message
   *
   * @returns {ReactComponent} This will be returning component to render text.
   */
  renderText = escapedString => {
    const { CHAR_LIMIT, CLOSE_TEXT, SHOW_TEXT } = CONSTANTS.TASK_LIST.READ_MORE;
    const { readMore } = this.state;
    const text = unescapeString(escapedString);
    const { isEmojiSupported, fromCard } = this.props;
    // Checking for length of text ia greater then or equal to max char length. If less then return text. Or if it from card then render text.
    if (text.length < CHAR_LIMIT || fromCard) {
      return (
        <EmojiText
          className="emoji-sm"
          noWrapper
          isEmojiSupported={isEmojiSupported}
        >
          <p className="content-text">{text}</p>
        </EmojiText>
      );
    }

    // Length is greater then char limit. If readMore is true. Show full text with close button, On click of CLOSE_TEXT button will trigger setReadMore Method.
    if (readMore) {
      return (
        <Fragment>
          <p className="content-text">{text}</p>
          <span
            className="read-more"
            role="button"
            onClick={() => this.setReadMore(false)}
          >
            {CLOSE_TEXT}
          </span>
        </Fragment>
      );
    }

    // summaryText is cropped text till max char text. Once clicked on SHOW_TEXT button will trigger setReadMore Method.
    const summaryText = text.substring(0, CHAR_LIMIT);
    return (
      <Fragment>
        <EmojiText
          className="emoji-sm"
          noWrapper
          isEmojiSupported={isEmojiSupported}
        >
          <p className="content-text">{summaryText}</p>
        </EmojiText>
        <span
          className="read-more"
          role="button"
          onClick={() => this.setReadMore(true)}
        >
          {SHOW_TEXT}
        </span>
      </Fragment>
    );
  };

  /**
   * @description
   * Here this component renders. Data are represented in the following order
   *
   * ORDER: Appreciation followed by image then content. Note in table view, image and content will be adjust to each other.
   *
   * Content section will contain Sticker(Emoji and followed by Sticker text). Then We'll have Text(message) on which feed has been flagged.
   */
  render() {
    const {
      text,
      imageUrl,
      targetName,
      fromCard,
      feedItemType,
      badgeName,
      stickerTitle,
      stickerUnicode,
      isEmojiSupported
    } = this.props;

    // Conditional render class. If card view then render card-content-block class. In scss file will have different behaviour.
    const containerClass = cx([
      'content-block',
      { 'card-content-block': fromCard }
    ]);

    // Conditional render content section class. If image is present then render content section class differently. In scss file will have different behaviour.
    const contentSectionClass = cx([
      {
        'badge-section': badgeName,
        'content-section': imageUrl
      }
    ]);

    // Conditional render image section class. If badge is present then render image section class differently. In scss file will have different behaviour.
    const imageSectionClass = cx([
      'image-section',
      {
        'badge-image': badgeName
      }
    ]);

    const stickerClasses = cx([
      'sticker',
      { 'emoji-unicode': isEmojiSupported }
    ]);
    // Content to be displayed

    return (
      <div className={containerClass}>
        {/* Target/Appreciation section */}
        {targetName && feedItemType !== ENUM.FEED_ITEM_TYPE.BADGE && (
          <p className="appreciated">
            Just appreciated <span className="target">{targetName}</span>
          </p>
        )}
        {/* Sticker section */}
        {stickerUnicode && (
          <div className="sticker-section">
            <span className={stickerClasses}>
              <EmojiText
                className="emoji-lg"
                isEmojiSupported={isEmojiSupported}
              >
                {unescapeString(stickerUnicode)}
              </EmojiText>
            </span>
            <span className="sticker-title">{stickerTitle}</span>
          </div>
        )}
        {/* Image section */}
        {imageUrl && (
          <div className={imageSectionClass}>
            <ImgWithExif src={imageUrl} alt={text} />
          </div>
        )}
        {/* Badge Name */}
        {badgeName && <span className="badge-name">{badgeName}</span>}
        {/* Content section */}
        {text && (
          <div className={contentSectionClass}>{this.renderText(text)}</div>
        )}
      </div>
    );
  }
}

export default ContentBlock;
