import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Alert.scss';
import SvgImage from '../SvgImage/SvgImage';

/**
 * Note: setting Timeout = -1 or any negative value will retain the alert
 * message indefinitely.
 */
function Alert({ message, timeout, onDismiss, alertIcon }) {
  const [dismissed, setDismissed] = useState(false);

  if (timeout > 0) {
    useEffect(() => {
      setTimeout(() => {
        setDismissed(true);
        onDismiss();
      }, timeout);
    }, []);
  }

  const alertClasses = cx(['alert-bar', { 'alert-dismissed': dismissed }]);
  return (
    <div className={alertClasses}>
      {alertIcon && (
        <SvgImage src={alertIcon} alt="Alert" className="alert-bar-icon" />
      )}
      <p className="alert-bar-message">{message}</p>
    </div>
  );
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  onDismiss: PropTypes.func,
  alertIcon: PropTypes.any
};

Alert.defaultProps = {
  timeout: 3000,
  onDismiss: () => {},
  alertIcon: null
};

export default Alert;
