import React, { useState } from 'react';
import DateRangePicker from 'react-daterange-picker';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';

import './CalendarPanel.scss';
import CONSTANTS from '../../../constants';
import ENUM from '../../../enum';
import { formatDateRange } from '../../../utils/utils';

/**
 * @description
 * React UI Component - part of DateRangePickerDropdown.
 * This component is shown in the UI when `DateRangePickerDropdown` is opened.
 * It shows the calendar view along with the list of options in the dropdown.
 */
const CalendarPanel = ({ selectedDate, onSelectHandler }) => {
  const { CLEAR, APPLY, DEFAULT_CALENDAR_TEXT } = CONSTANTS.COMMON;
  const [dateRangeText, setDateRangeText] = useState(DEFAULT_CALENDAR_TEXT);
  const [dates, setDates] = useState(selectedDate);

  /**
   * @description
   * This gets called when a date-range selection is made.
   * It sets the internal state with selected start & end dates and a label
   * @param {object} date - Selected date range
   * Contains these keys: `start` & `end`
   */
  const onDateClicked = date => {
    const { start, end } = date;
    const dateRange = formatDateRange(start, end);
    setDates(date);
    setDateRangeText(dateRange);
  };

  /**
   * @description
   * This method gets called when "clear" button is clicked.
   * It clears the selected date range and resets the label to default text;
   */
  const onClearButtonClicked = () => {
    const today = moment();
    setDates(moment.range(today, today));
    setDateRangeText(DEFAULT_CALENDAR_TEXT);
  };

  /**
   * @description
   * This method gets called when "Apply" button is clicked.
   * It calls the `onSelectHandler` to which it supplies an object
   * with text, startDate & endDate.
   */
  const onApplyButtonClicked = () => {
    const startDate = dates.start.format();
    const endDate = dates.end.format();

    onSelectHandler({
      text: dateRangeText,
      value: ENUM.DATE_RANGE.SELECT_DATES,
      start: startDate,
      end: endDate
    });
  };

  /**
   * @description
   * Disable "Apply" button if user has selected a single date or didn't select any date at all.
   * @return {boolean} True/False based on selected date range.
   */
  const isApplyButtonDisabled = () => {
    return moment(dates.start).isSame(moment(dates.end));
  };

  const applyBtnClass = cx([
    'calendar-btn apply',
    {
      disabled: isApplyButtonDisabled()
    }
  ]);

  return (
    <div className="calendar-panel">
      <DateRangePicker
        singleDateRange
        value={dates}
        onSelect={onDateClicked}
        maximumDate={new Date()}
        initialDate={new Date()}
      />
      <p className="selected-date-range">{dateRangeText}</p>
      <footer className="calendar-button-panel">
        <button className="calendar-btn clear" onClick={onClearButtonClicked}>
          {CLEAR}
        </button>
        <button
          className={applyBtnClass}
          disabled={isApplyButtonDisabled()}
          onClick={onApplyButtonClicked}
        >
          {APPLY}
        </button>
      </footer>
    </div>
  );
};

CalendarPanel.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  onSelectHandler: PropTypes.func.isRequired
};

export default CalendarPanel;
