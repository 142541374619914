import React from 'react';
import PropTypes from 'prop-types';

import SvgImage from '../../SvgImage/SvgImage';
import dropIcon from '../../../assets/images/drop.svg';
import './DropdownButton.scss';
import ENUM from '../../../enum';
import { isNullOrUndefined, formatDateRange } from '../../../utils/utils';

/**
 * @description
 * React UI Component - part of DateRangePickerDropdown.
 * This component is shown in the UI when `DateRangePickerDropdown` is in closed state.
 * It only shows the selected option's label.
 *
 * @param {Object} props - Contains two necessary properties
 * toggleHandler - a callback, invoked on click of this label which opens the panel
 * selectedOption - option to be displayed as selected.
 */
const DropdownButton = ({ toggleHandler, selectedOption }) => {
  const getDropdownLabel = option => {
    if (
      option.value !== ENUM.DATE_RANGE.SELECT_DATES &&
      !isNullOrUndefined(option.text)
    ) {
      return option.text;
    }

    const { start = '', end = '' } = option;
    return formatDateRange(start, end);
  };

  return (
    <div className="selected-item" role="button" onClick={toggleHandler}>
      <span className="display-text">{getDropdownLabel(selectedOption)}</span>
      <SvgImage src={dropIcon} alt="drop down" className="display-icon" />
    </div>
  );
};

DropdownButton.propTypes = {
  toggleHandler: PropTypes.func.isRequired,
  selectedOption: PropTypes.object.isRequired
};

export default DropdownButton;
