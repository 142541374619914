import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { isEmptyString } from '../../utils/utils';

import './Button.scss';
import SvgImage from '../SvgImage/SvgImage';

const Button = ({
  size = 'medium',
  customClass,
  href,
  value,
  iconPath,
  iconHeight,
  iconWidth,
  iconVerticalAlign,
  ...props
}) => {
  const classes = cx([`size-${size}`, `${customClass}`]);

  return href ? (
    <div>
      <a className={classes} href={href} {...props}>
        {value}
      </a>
    </div>
  ) : (
    <button
      type={props.type}
      className={`${classes} button`}
      {...props}
      value={value}
    >
      {iconPath && (
        <SvgImage
          src={iconPath}
          height={iconHeight}
          width={iconWidth}
          verticalAlign={iconVerticalAlign}
          className="btn-icon"
          alt="icon"
        />
      )}
      {!isEmptyString(value) && <span className="btn-text">{value}</span>}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  customClass: PropTypes.string,
  href: PropTypes.string,
  value: PropTypes.any,
  iconPath: PropTypes.string,
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  iconVerticalAlign: PropTypes.string
};

Button.defaultProps = {
  type: 'default',
  customClass: '',
  href: '',
  size: 'medium',
  value: '',
  iconPath: '',
  iconHeight: '21px',
  iconWidth: '21px',
  iconVerticalAlign: ''
};
export default Button;
