import React from 'react';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import CONSTANTS from '../constants';
import { Button } from '../common';
import { downloadIcon } from '../assets/images/download.svg';

const { DATE_FORMAT_DOTTED, TIME_FORMAT_HHMMA } = CONSTANTS.COMMON;

const PrintCSV = data => {
  const formattedData = [];
  data.data.forEach(task => {
    const formattedDate =
      moment(task.actionDate).format(DATE_FORMAT_DOTTED) +
      moment(task.actionDate).format(TIME_FORMAT_HHMMA);

    const formattedTask = {
      action: task.action,
      actionDate: formattedDate,
      hospitalName: task.hospitalName,
      id: task.id,
      itemType: task.itemType,
      reason: task.reason,
      senderCurrentRole: task.senderCurrentRole,
      senderName: task.senderName,
      text: task.text,
      unitName: task.unitName
    };
    formattedData.push(formattedTask);
  });

  const headers = [
    { label: 'Name', key: 'senderName' },
    { label: 'Content', key: 'text' },
    { label: 'Action', key: 'action' },
    { label: 'Date of Action', key: 'actionDate' },
    { label: 'Reason', key: 'reason' },
    { label: 'Unit', key: 'unitName' },
    { label: 'Facility', key: 'hospitalName' }
  ];
  return (
    <CSVLink
      filename="Report"
      data={formattedData}
      headers={headers}
      target="_blank"
    >
      <Button
        value="EXCEL"
        customClass="btn-left btn-primary btn-text"
        iconPath={downloadIcon}
        iconWidth="12px"
        iconHeight="12px"
      />
    </CSVLink>
  );
};

const printPDF = (data, sasToken) => {
  const tasks = [];
  data.forEach(task => {
    const formattedDate =
      moment(task.actionDate).format(DATE_FORMAT_DOTTED) +
      moment(task.actionDate).format(TIME_FORMAT_HHMMA);

    tasks.push({
      id: task.id,
      senderName: task.senderName,
      senderCurrentRole: task.senderCurrentRole,
      text: `${task.text}\n${task.stickerTitle || ''}`,
      image: task.imageUrl ? `${task.imageUrl}${sasToken}` : '',
      action: task.action,
      date: formattedDate || 'N/A',
      reason: task.reason || 'N/A',
      unitName: task.unitName || 'N/A',
      hospitalName: task.hospitalName || 'N/A'
    });
  });
  const columns = [
    { header: 'Name', dataKey: 'senderName' },
    { header: 'Role', dataKey: 'senderCurrentRole' },
    { header: 'Content', dataKey: 'text' },
    { header: 'Image', dataKey: 'image' },
    { header: 'Action', dataKey: 'action' },
    { header: 'Date of Action', dataKey: 'date' },
    { header: 'Reason', dataKey: 'reason' },
    { header: 'Unit', dataKey: 'unitName' },
    { header: 'Facility', dataKey: 'hospitalName' }
  ];

  // eslint-disable-next-line new-cap
  const doc = new jsPDF({ orientation: 'landscape' });
  doc.autoTable({
    willDrawCell: item => {
      if (item.cell.raw === 'Allowed') {
        doc.setTextColor('green');
      }
      if (item.cell.raw === 'Removed') {
        doc.setTextColor('red');
      }
    },
    columns,
    body: tasks,
    margin: { top: 20 },
    styles: {
      minCellHeight: 20,
      cellWidth: 27,
      halign: 'left',
      fontSize: 11
    }
  });
  doc.save('Report.pdf');
};

export { PrintCSV, printPDF };
