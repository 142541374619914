import { call, put } from 'redux-saga/effects';
import { constructQueryParams } from '../../utils/utils';
import { getData } from '../../utils/axios';

import API from '../../API';
import { ACTIONS } from '../actions/actionType';

function* fetchUserList(action) {
  let response;
  try {
    const URL = `${API.searchUrl}?${constructQueryParams(action.data)}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.REPORT.FETCH_USER_LIST_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.REPORT.FETCH_USER_LIST_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.REPORT.FETCH_USER_LIST_FAIL,
      data: error
    });
  }
}

function* fetchDivisionList(action) {
  let response;
  try {
    const URL = `${API.divisionUrl}?${constructQueryParams(action.data)}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.REPORT.FETCH_DIVISION_LIST_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.REPORT.FETCH_DIVISION_LIST_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.REPORT.FETCH_DIVISION_LIST_FAIL,
      data: error
    });
  }
}

function* fetchReportSummary(action) {
  let response;
  try {
    const URL = `${API.generateReportUrl}?${constructQueryParams(action.data)}`;
    response = yield call(getData, URL);
    if (response.status === 200) {
      yield put({
        type: ACTIONS.REPORT.FETCH_REPORT_SUMMARY_SUCCESS,
        data: response.data
      });
    } else {
      yield put({
        type: ACTIONS.REPORT.FETCH_REPORT_SUMMARY_FAIL,
        data: response.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTIONS.REPORT.FETCH_REPORT_SUMMARY_FAIL,
      data: error
    });
  }
}

export { fetchUserList, fetchDivisionList, fetchReportSummary };
