import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import MainLayout from './MainLayout';
import { isUserLoggedIn } from '../services/AuthService';

const MainLayoutRoute = ({ component: Component, ...rest }) => {
  /**
   * Check if user is logged in already, else redirect to login page.
   */
  if (!isUserLoggedIn()) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      )}
    />
  );
};
MainLayoutRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default withRouter(MainLayoutRoute);
