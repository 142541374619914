import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Backdrop from '../Backdrop/Backdrop';

import close from '../../assets/images/Close.svg';
import './Modal.scss';
import SvgImage from '../SvgImage/SvgImage';

/**
 * @description Modal component that will be using backdrop component as overlay.
 * This component is used for modal/alert purpose.
 * @argument show: Boolean value based on which component will be rendered
 * @argument title: String value which is used to render title of modal box.
 * @argument modalClosed: Function which will be triggered when clicked on close icon.
 * When modalClosed event is triggered, in parent component we can reset show value to false
 * @argument children: React.Element any JSX event written between component is rendered.
 */
const Modal = props => {
  const { show, modalClosed, children, title, customClasses } = props;

  const modalClasses = cx(['modal', { 'modal-show': show }, customClasses]);
  return (
    <Fragment>
      <Backdrop show={show} clicked={modalClosed} />
      <div className={modalClasses}>
        <div className="modal-header">
          <h1 className="modal-title">{title}</h1>
          <SvgImage
            className="modal-close"
            src={close}
            alt="close modal"
            role="button"
            onClick={modalClosed}
          />
        </div>
        {children}
      </div>
    </Fragment>
  );
};

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  modalClosed: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  customClasses: PropTypes.string
};

Modal.defaultProps = {
  show: false,
  customClasses: ''
};

export default Modal;
