import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ContentBlock from '../../ContentBlock/ContentBlock';
import AllowRemoveAction from '../../AllowRemoveAction/AllowRemoveAction';
import { SvgImage, UserDetails } from '../../../common';

import { appendSASToken, timeFromCreation } from '../../../utils/utils';
import CONSTANTS from '../../../constants';
import ENUM from '../../../enum';

import InfoIcon from '../../../assets/images/Icn_Info.svg';
import Close from '../../../assets/images/card-close.svg';

import './Card.scss';

const Card = props => {
  const {
    sasToken,
    isEmojiSupported,
    data,
    allowAction,
    removeAction,
    onUserDetailsClicked
  } = props;
  const {
    id,
    creationTime,
    senderName,
    senderProfileImageUrl,
    imageUrl,
    text,
    itemType,
    senderId,
    stickerTitle,
    stickerUnicode,
    targetName,
    feedItemType,
    badgeName,
    reportedBy,
    action
  } = data;

  // Set minimum height as that of Card reveal max height.
  const [minHeight, setMinHeight] = useState(CONSTANTS.COMMON.CARD_MIN_HEIGHT);
  const [showReveal, setShowReveal] = useState(false);

  // Create a reference to card node to get it's height
  const heightRef = useRef(null);

  useEffect(() => {
    const updatedHeight = heightRef.current.clientHeight;

    // If current min height is smaller then updated height, then update minimum height
    if (minHeight < updatedHeight) {
      // set height min.
      setMinHeight(updatedHeight);
      // update minimum height
      heightRef.current.style.height = updatedHeight;
    }
  });

  /**
   * @description
   * This method is used to render card header with user's details and info button, on click of it card will be rotated.
   *
   * @returns {ReactComponent} with header.
   */
  const getCardHeader = () => {
    const isComment = itemType === ENUM.ITEM_TYPE.COMMENT;
    const senderImageUrl = appendSASToken(sasToken, senderProfileImageUrl);

    const icon = showReveal ? Close : InfoIcon;
    return (
      <div className="card-header">
        <div className="left-section">
          {showReveal ? (
            <h1 className="reveal-title">
              {CONSTANTS.COMMON.CARD_REVEAL_TITLE}
            </h1>
          ) : (
            <UserDetails
              name={senderName}
              imageUrl={senderImageUrl}
              time={creationTime}
              isComment={isComment}
              onClickAction={() => onUserDetailsClicked(senderId)}
            />
          )}
        </div>
        <div className="right-section">
          <SvgImage
            src={icon}
            alt="info icon"
            onClick={() => setShowReveal(!showReveal)}
          />
        </div>
      </div>
    );
  };

  /**
   * @description
   * This method is used to render card contains.
   *
   * @returns {ReactComponent} Card content
   */
  const getCardContent = () => {
    return (
      <div className="card-contain">
        <ContentBlock
          imageUrl={appendSASToken(sasToken, imageUrl)}
          text={text}
          stickerTitle={stickerTitle}
          stickerUnicode={stickerUnicode}
          targetName={targetName}
          feedItemType={feedItemType}
          badgeName={badgeName}
          isEmojiSupported={isEmojiSupported}
          fromCard
        />
      </div>
    );
  };

  /**
   * @description
   * This method is used to render allow and remove action button.
   */
  const getCardAction = () => {
    const feedData = {
      id,
      senderId,
      itemType,
      imageUrl: appendSASToken(sasToken, senderProfileImageUrl)
    };
    // const hasReporter = true;
    const hasReporter = reportedBy.length >= 2;
    if (hasReporter || action != null) {
      return (
        <div className="card-action">
          <AllowRemoveAction
            onAllowClicked={() => allowAction(feedData)}
            onRemoveClicked={() => removeAction(feedData)}
            hasReporter={hasReporter}
            action={action}
            fromCard
          />
        </div>
      );
    }
  };

  /**
   * @deprecated
   * This method is used to render card reveal contents.
   *
   * @returns {ReactComponent} card reveal content.
   */
  const getCardReporter = () => {
    return (
      <div className="card-reveal">
        <div className="separator" />
        <div className="reporters">
          {reportedBy.length === 0 ? (
            <p className="no-reporters">This post has not been reported</p>
          ) : (
            <Fragment>
              {reportedBy.map(reporter => {
                const imgUrl = appendSASToken(
                  sasToken,
                  reporter.profileImageUrl
                );
                return (
                  <div className="reporter" key={reporter.id}>
                    <div className="user-profile">
                      <UserDetails
                        name={reporter.name}
                        imageUrl={imgUrl}
                        currentRole={reporter.currentRole}
                        customClass="reporter-details"
                        onClickAction={() => onUserDetailsClicked(reporter.id)}
                      />
                      <p className="reported-time">
                        {timeFromCreation(reporter.reportedTime)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Fragment>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="card" ref={heightRef} style={{ minHeight }}>
      {getCardHeader()}
      {showReveal ? (
        <div className="card-back">{getCardReporter()}</div>
      ) : (
        <div className="card-front">
          {getCardContent()}
          {getCardAction()}
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  data: PropTypes.object.isRequired,
  sasToken: PropTypes.string.isRequired,
  isEmojiSupported: PropTypes.bool.isRequired,
  allowAction: PropTypes.func.isRequired,
  removeAction: PropTypes.func.isRequired,
  onUserDetailsClicked: PropTypes.func
};

Card.defaultProps = {
  onUserDetailsClicked: () => {}
};

export default Card;
