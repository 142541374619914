import moment from 'moment';
import ENUM from '../enum';
import CONSTANTS from '../constants';

/**
 * @param {Object} data - "key-value" pairs which should be used to construct url
 * @description This function takes an object and returns a string representing the url with query params
 * Example:
 *  input: { key1: 'abc', key2: 'xyz', key3: 34 }
 *  result: "key1=abc&key2=xyz&key3=34"
 */
const constructQueryParams = data => {
  const ret = [];
  const keysList = Object.keys(data);
  keysList.forEach(key => {
    if (Array.isArray(data[key])) {
      data[key].forEach(value => {
        ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      });
    } else {
      ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
    }
  });
  return ret.join('&');
};

/**
 * @param {Number} date
 * @description This method is used to convert UNIX timestamp to MM/DD/YYYY format
 * @example
 * unixTimeToDate(1555041071);
 */
const unixTimeToDate = timeStamp =>
  moment(moment.unix(timeStamp))
    .startOf('min')
    .fromNow();

/**
 * @param {Array} a
 * @param {Array} b
 * @description This method is used to get unique element two array.
 * This method will remove duplicate elements from arrays.
 * @example
 * a = [1, 2, 3];
 * b = [2, 3, 4, 5];
 * c = getUniqueArray(a, b); // c = [1, 2, 3, 4, 5];
 */
const getUniqueArray = (a, b) =>
  Array.from(new Set([...new Set(a), ...new Set(b)]));

/**
 * @param {Number} value
 * @description This method is used to get role of a user based on Enum value.
 * @example
 * mapEnumToRole('1'); // 'User'
 * mapEnumToRole('2'); // 'Moderator'
 * mapEnumToRole('3'); // 'Admin'
 * mapEnumToRole('4'); // 'Superuser'
 * mapEnumToRole('5'); // 'Unknown Role'
 */

const mapEnumToRole = value => {
  switch (value) {
    case 'User':
      return 'User';
    case 'Moderator':
      return 'Moderator';
    case 'Admin':
      return 'Admin';
    case 'Superuser':
      return 'Superuser';
    default:
      return 'Unknown Role';
  }
};

/**
 * @param {*} url - the page to be opened in a separate window
 * @param {*} title - the title shown in window's title bar, possibly gets overridden by the page's content
 * @param {*} width - default value = 480 pixels
 * @param {*} height - default value = 480 pixels
 * @description Opens the given Url in a separate window in the center of the screen
 * according to the given width/height constraints
 */
const openPopupInCenter = (url, title = '', width = 480, height = 440) => {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;
  return window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no,
    resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
  );
};

/**
 * @description
 * Determines if a given reference is undefined or null.
 *
 * @returns {boolean} True if `object` is undefined or null.
 */
const isNullOrUndefined = object => {
  return object === null || object === undefined;
};

/**
 * @description
 * Determines if a given string is empty or not.
 *
 * @param {string} stringVal
 *
 * @returns {boolean} True if `string` is empty.
 * @returns {boolean} False if `string` is not empty
 *  or if the input type is anything else other than a string.
 */

const isEmptyString = stringVal => {
  return typeof stringVal === 'string' && stringVal.length === 0;
};

/**
 * @description
 * Checks if the given string is a valid string.
 * If valid, it returns the input untouched or else, the string value "N/A" is returned.
 *
 * @param {string} stringVal Example: Hospital name
 * @returns {string} Either "N/A" or the original string itself
 */
const replaceWithNA = stringVal => {
  return isNullOrUndefined(stringVal) || isEmptyString(stringVal)
    ? 'N/A'
    : stringVal;
};

/**
 * @description
 * Calculates the `startDate` based on the selectedDateRange.
 *
 * @param {number} dateRangeId - One of the values defined in ENUM.DATE_RANGE
 * @returns {string} `startDate` in ISO date format
 */
const calculateStartDate = dateRangeId => {
  let startDate = '';
  switch (dateRangeId) {
    case ENUM.DATE_RANGE.TODAY:
      startDate = moment().format();
      break;
    case ENUM.DATE_RANGE.LAST_WEEK:
      startDate = moment()
        .subtract(7, 'days')
        .format();
      break;
    case ENUM.DATE_RANGE.LAST_MONTH:
      startDate = moment()
        .subtract(1, 'month')
        .format();
      break;
    case ENUM.DATE_RANGE.LAST_YEAR:
      startDate = moment()
        .subtract(1, 'year')
        .format();
      break;
    default:
      return '';
  }
  return startDate;
};

//  Used to convert ISODataString to month date, year time format. If Date string is passed.
const formatTimeFromISODate = ISODataString => {
  if (ISODataString) {
    return moment(ISODataString).format('lll');
  }
  return 'N/A';
};

// Unescape emojis from a string and handle non escaped \n characters.
const unescapeString = string => {
  const regExp = /\\u([\d\w]{4})/gi;
  const replacedString = string.replace(regExp, (match, grp) => {
    return String.fromCharCode(parseInt(grp, 16));
  });
  return unescape(replacedString);
};

// Check the compatibility of emoji on OS
const getIsEmojiSupported = () => {
  if (!document.createElement('canvas').getContext) return false;

  const context = document.createElement('canvas').getContext('2d');

  if (typeof context.fillText !== 'function') return false;

  const emoji = String.fromCharCode(55357) + String.fromCharCode(56835);

  context.textBaseline = 'top';
  context.font = '32px Arial';
  context.fillText(emoji, 0, 0);
  return context.getImageData(16, 16, 1, 1).data[0] !== 0;
};

/**
 * @description
 * This function is used to hide scroll to body if modal is open.
 *
 * @param {Boolean} isModalOpen It will contains all arguments sent to this function.
 *
 * @return {void} This function will remove scroll if any one of the argument passed to it is true. else it will enable scroll for body.
 */
const toggleBodyScroll = isModalOpen => {
  if (typeof isModalOpen === 'boolean') {
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
};

/**
 * @description
 * This method is used to append SAS Token for images if there is SAS token and imageUrl with it.
 *
 * @param {String} sasToken This should be appended to image to obtain data from Azure blob storage.
 * @param {String | null} imageUrl This will have image url or no image for a given data.
 *
 * @returns {String | null} This will return null if image url or sas token is not there. Else it will return image URL with SAS token appended to it.
 */
const appendSASToken = (sasToken, imageUrl) => {
  // Checking for image and SASToken
  if (imageUrl && sasToken) {
    return `${imageUrl}${sasToken}`;
  }
  return null;
};

/**
 * @description
 * This method is used to update title of the page.
 *
 * @param {String} title That has to be updated
 */
const setTitle = title => {
  document.title = title;
};

/**
 * @description
 * This method is used to return time from creation of the post/comment
 *
 * @param {ISODataString}
 */
const timeFromCreation = time => {
  return moment(time).fromNow();
};

/**
 * @description
 * Reads the given url and internally constructs a map of its query parameters, if any available.
 *
 * @param {string} baseUrl - Url with query parameters that needs to be manipulated
 * @returns {object} - A javascript object with keys and values present in the query params.
 */
const parseQueryParams = baseUrl => {
  if (isEmptyString(baseUrl) || isNullOrUndefined(baseUrl)) {
    return null;
  }

  const qMap = {};
  const baseUrlIndex = baseUrl.indexOf('?');
  const queryString = baseUrl.substring(baseUrlIndex + 1);
  const queries = queryString.split('&');

  queries.forEach(query => {
    const keyValuePair = query.split('=');
    qMap[decodeURIComponent(keyValuePair[0])] = decodeURIComponent(
      keyValuePair[1]
    );
  });

  return qMap;
};

/**
 * @description
 * This method will take the time and return in DD/MM/YYYY format.
 *
 * @param {String} time ISOString date of action
 */
const renderTimeForAction = time => {
  return moment(time).format('MM/DD/YYYY');
};

/**
 * @description
 * This method is used to create masonry/staggered grid.
 *
 * @param {Array} input list of data which has to be rearranged to form masonry/staggered grid.
 * @param {Number} columnCount This method is used to determine number of columns, with default 3.
 *
 * @returns {Array} array containing masonry grid alignment.
 */
const createMasonryArray = (input, columnCount = 3) => {
  const accArray = [];

  // Create accArray in form of [[], [], []]
  for (let i = 0; i < columnCount; i += 1) {
    accArray.push([]);
  }

  // Creating masonry based column arrangement.
  return input.reduce((acc, el, index) => {
    // Identifying column whose el should be added to
    const accIndex = index % columnCount;
    // Adding that element to internal array.
    acc[accIndex].push(el);
    return acc;
  }, accArray);
};

/**
 * This function converts the given dates into a readable string of this format:
 * "MM/DD/YYYY - MM/DD/YYYY"
 * @param {string | moment} startDate
 * @param {string | moment} endDate
 */
const formatDateRange = (startDate, endDate) => {
  const { SHORT_DATE_FORMAT } = CONSTANTS.COMMON;
  const start = moment(startDate).format(SHORT_DATE_FORMAT);
  const end = moment(endDate).format(SHORT_DATE_FORMAT);
  return `${start} - ${end}`;
};

/**
 * @description
 * This function takes two dates and ignores the time by replacing the
 * time part with start and end times excluding the time-zone.
 *
 * @param {String | moment} start - Date in UTC time-zone
 * @param {String | moment} end - Date in UTC time-zone
 */
const convertDateRangeToUTC = (start, end) => {
  const {
    DATE_FORMAT_HYPHEN,
    START_DATE_TIME_PART: startTime,
    END_DATE_TIME_PART: endTime
  } = CONSTANTS.COMMON;

  const startDate = `${moment(start).format(DATE_FORMAT_HYPHEN)}${startTime}`;
  const endDate = `${moment(end).format(DATE_FORMAT_HYPHEN)}${endTime}`;
  return {
    startDate,
    endDate
  };
};

/**
 * @description
 * This function takes an HTML element and returns true if it is the root HTML element.
 *
 * Usage:
 * Dropdowns should not be closed when user tries to click on scroll-bar/scroll-buttons
 *
 * Reference:
 * https://github.com/JedWatson/react-select/blob/c22a4b20aef2161ebaa3a0373de7e0e179e73693/packages/react-select/src/Select.js#L962
 *
 * @param {HTMLElement} element
 * @returns Boolean
 */
const isDocumentElement = element => {
  return (
    [document.documentElement, document.body, window].indexOf(element) > -1
  );
};

export {
  unixTimeToDate,
  getUniqueArray,
  constructQueryParams,
  mapEnumToRole,
  openPopupInCenter,
  isNullOrUndefined,
  isEmptyString,
  replaceWithNA,
  calculateStartDate,
  formatTimeFromISODate,
  unescapeString,
  getIsEmojiSupported,
  toggleBodyScroll,
  appendSASToken,
  setTitle,
  timeFromCreation,
  parseQueryParams,
  renderTimeForAction,
  createMasonryArray,
  formatDateRange,
  convertDateRangeToUTC,
  isDocumentElement
};
