// Importing action file
import { ACTIONS } from '../actions/actionType';

/**
 * @description
 * This method when called, removes a post from `tasks` array that matches
 * the given `id` (postId).
 * Used after performing allow/remove action on a post.
 *
 * @param {array} tasks - list of posts shown in the UI
 * @param {integer} id - unique id representing a post.
 */
const removeTaskById = (tasks, id) => tasks.filter(item => item.id !== id);

const initialState = {
  tasks: [],
  tasksPage: {
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  },
  initialDataLoaded: false
};

const TaskList = (state = initialState, action) => {
  const tempState = { ...state };
  switch (action.type) {
    case ACTIONS.TASK_LIST.RESET_TASK_LIST: {
      tempState.tasks = [...initialState.tasks];
      tempState.tasksPage = { ...initialState.tasksPage };
      tempState.initialDataLoaded = false;
      return tempState;
    }
    case ACTIONS.TASK_LIST.FETCH_TASK_LIST_SUCCESS: {
      return {
        ...state,
        tasksPage: action.data.page,
        tasks: [...state.tasks, ...action.data._embedded.resources],
        initialDataLoaded: true
      };
    }
    case ACTIONS.TASK_LIST.FETCH_TASK_LIST_FAIL: {
      return { ...tempState, initialDataLoaded: true };
    }
    case ACTIONS.TASK_LIST.REMOVE_TASK_FROM_LIST: {
      tempState.tasks = removeTaskById(tempState.tasks, action.data);
      return tempState;
    }
    default:
      return tempState;
  }
};

export default TaskList;
