import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import EmptyLayout from './EmptyLayout';

const EmptyLayoutRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <EmptyLayout>
        <Component {...matchProps} />
      </EmptyLayout>
    )}
  />
);

EmptyLayoutRoute.propTypes = {
  component: PropTypes.func.isRequired
};
export default EmptyLayoutRoute;
