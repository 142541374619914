import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './Login.scss';
import CONSTANTS from '../../constants';
import LoginHeader from './LoginHeader/LoginHeader';
import Button from '../../common/Button/Button';
import API from '../../API';
import {
  openPopupInCenter,
  isEmptyString,
  isNullOrUndefined
} from '../../utils/utils';
import { isUserLoggedIn } from '../../services/AuthService';
import { setLastLoggedOn } from '../../redux/actions/Auth.actions';
import { Alert } from '../../common';
import flagIcon from '../../assets/images/flag.svg';

export class Login extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onUserLoggedInTime: PropTypes.func.isRequired
  };

  state = {
    alertMessage: '',
    alertIconPath: null
  };

  /**
   * @description Verifies if user has already logged in based on function
   * implemented in AuthService.js.
   * In case of existing session, navigate the user to Homepage - Tasks List
   * Else, it shows the login page with some alert message based on how
   * the application was logged out previously.
   */
  componentDidMount = () => {
    if (isUserLoggedIn()) {
      this.props.history.push(CONSTANTS.COMMON.homepage);
    } else {
      this.initializeAlerts();
    }
  };

  /**
   * @description Unregisters the listener attached at the time of logging in.
   */
  componentWillUnmount() {
    this.unregisterMessageEventListener();
  }

  /**
   * @description
   * When "Proceed to login" is clicked, it opens a separate window
   * asks for credentials, logs in and redirects to our application with code in url.
   * That Url is received in child window then its posts a message: "LOGIN_SUCCESS" and closes itself.
   * This "eventListener" receives that success message and redirects our application
   * in the parent window to the homepage.
   */
  loginEventListener = event => {
    const origin = `${window.location.protocol}//${window.location.host}`;
    if (event.origin !== origin) return;

    if (event.data === CONSTANTS.LOGIN.loginSuccessMessage) {
      this.props.onUserLoggedInTime();
      this.unregisterMessageEventListener();
      this.props.history.push(CONSTANTS.COMMON.homepage);
    }
  };

  /**
   * @description This method is called when user clicks on the button
   *  "PROCEED TO LOGIN".
   * Redirects the user to external login page provided by OpenID. (Step - 1)
   */

  openLoginPopup = () => {
    this.registerMessageEventListener();
    const loginUrl = API.getLoginUrl();
    openPopupInCenter(loginUrl);
  };

  /**
   * @description
   * This function checks the local storage for flags: `wasInactive` && `unauthorized`
   * and sets the internal state with appropriate error message and icons
   * And removes the flags from local storage.
   */
  initializeAlerts = () => {
    const inactive = localStorage.getItem('wasInactive');
    if (inactive) {
      const { INACTIVE_SESSION_MESSAGE: alertMessage } = CONSTANTS.LOGIN;

      this.setState({ alertMessage, alertIconPath: flagIcon });
      localStorage.removeItem('wasInactive');
    }

    const unauthorized = localStorage.getItem('unauthorized');
    if (unauthorized) {
      const { UNAUTHORIZED_USER: alertMessage } = CONSTANTS.LOGIN;
      this.setState({ alertMessage });
      localStorage.removeItem('unauthorized');
    }
  };

  registerMessageEventListener = () => {
    window.addEventListener('message', this.loginEventListener);
  };

  unregisterMessageEventListener = () => {
    window.removeEventListener('message', this.loginEventListener);
  };

  render() {
    const { proceedToLogin } = CONSTANTS.LOGIN;
    const { alertMessage, alertIconPath } = this.state;
    const hasAlert =
      !isEmptyString(alertMessage) && !isNullOrUndefined(alertMessage);

    return (
      <div className="login-layout">
        {hasAlert && (
          <Alert
            message={alertMessage}
            timeout={-1}
            alertIcon={alertIconPath}
          />
        )}
        <div className="login-form">
          <LoginHeader />
          <Button
            value={proceedToLogin}
            customClass="btn-primary size-xlarge btn-text btn-center"
            onClick={this.openLoginPopup}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onUserLoggedInTime: () => dispatch(setLastLoggedOn())
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Login)
);
