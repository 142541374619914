import { combineReducers } from 'redux';

import common from './redux/reducer/Common.reducer';
import auth from './redux/reducer/Auth.reducer';
import task from './redux/reducer/TaskList.reducer';
import report from './redux/reducer/Report.reducer';
import search from './redux/reducer/Search.reducer';
import admin from './redux/reducer/ModeratorList.reducer';

const rootReducer = combineReducers({
  common,
  auth,
  task,
  report,
  search,
  admin
});

export default rootReducer;
