import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SvgImage from '../SvgImage/SvgImage';
import UserAvatar from '../UserAvatar/UserAvatar';

import { timeFromCreation, isNullOrUndefined } from '../../utils/utils';

import commentIcon from '../../assets/images/comment.svg';

import './UserDetails.scss';

/**
 * @param {Object} props
 * @description This is a functional component. Which is used to render user's details.
 * This will have user avatar, name and designation.
 * @argument imageUrl: Boolean value for image rendering inside avatar.
 * @argument name: String value for user name.
 * @argument currentRole: String which is used for displaying designation.
 * @argument size: Integer value for size of the avatar.
 * @argument defaultAvatarBg: Hex code color value.
 * @argument customClass: String value used to add customClass.
 * @argument isComment: Boolean value to represent comment icon.
 * @argument hideAvatar: Boolean value to show/hide user avatar; Shown by default
 */
const UserDetails = props => {
  const {
    imageUrl,
    name,
    customClass,
    isComment,
    hideAvatar,
    onClickAction
  } = props;

  /**
   * @description based on props to this component. If currentRole is passed then it will be rendered else time will.
   * If both are empty, then it render an empty string
   */
  const getUserInfo = () => {
    const { currentRole, time } = props;
    if (currentRole) {
      return currentRole;
    }

    if (time) {
      return timeFromCreation(time);
    }

    return '';
  };

  const userCardClass = cx([
    'user-card',
    `${customClass}`,
    {
      'profile-link-enabled': !isNullOrUndefined(onClickAction)
    }
  ]);

  return (
    <div role="button" className={userCardClass} onClick={onClickAction}>
      {!hideAvatar && (
        <div className="user-avatar">
          <UserAvatar name={name} imageUrl={imageUrl} />
        </div>
      )}
      <div className="user-details">
        <h1 className="user-name">
          {name}
          {/* If comment then show comment icon */}
          {isComment && (
            <div className="comment-icon">
              <SvgImage src={commentIcon} alt="comment icon" />
            </div>
          )}
        </h1>
        <p className="user-info">{getUserInfo()}</p>
      </div>
    </div>
  );
};

UserDetails.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  currentRole: PropTypes.string,
  time: PropTypes.string,
  customClass: PropTypes.string,
  isComment: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  onClickAction: PropTypes.func
};

UserDetails.defaultProps = {
  imageUrl: '',
  currentRole: '',
  time: '',
  customClass: '',
  isComment: false,
  hideAvatar: false,
  onClickAction: null
};

export default UserDetails;
