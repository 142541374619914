import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { SearchBar, Loader } from '../../common';
import { Header } from '../../components';
import SearchTable from './SearchTable/SearchTable';

import { setTitle } from '../../utils/utils';

import CONSTANTS from '../../constants';
import {
  fetchSearchedUser,
  clearSearchUser
} from '../../redux/actions/Search.actions';

import './Search.scss';

class Search extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    common: PropTypes.object.isRequired,
    onSearchUsers: PropTypes.func.isRequired,
    onClearSearchUsers: PropTypes.func.isRequired
  };

  state = {
    searchTerm: ''
  };

  componentDidMount() {
    setTitle(CONSTANTS.COMMON.PAGE_TITLE.SEARCH);
  }

  componentWillUnmount() {
    this.props.onClearSearchUsers();
  }

  onKeyUp = debounce(() => {
    if (this.state.searchTerm.length > 2) {
      this.fetchData();
    }
  }, 1000);

  /**
   * @description
   * This method is used to fetch data from server.
   */
  fetchData = () => {
    const data = this.fetchQueryData();
    this.props.onSearchUsers(data);
  };

  /**
   * @description
   * This method gets called on scrolling down the search results page.
   * It requests the backend to load more items in the table.
   */
  loadMoreUsers = page => {
    const data = this.fetchQueryData(page);
    this.props.onSearchUsers(data);
  };

  /**
   * @description
   * This method listen to change event in search bar and set's state.
   *
   * @param {String} searchTerm contains value/character typed in search bar.
   */
  onSearchChanged = searchTerm => {
    this.setState({ searchTerm });
  };

  /**
   * @description
   * To create object which contains all query params for API request.
   *
   * @returns {Object} This will contain all query params
   */
  fetchQueryData = (page = 0) => {
    const { PAGE_SIZE: size } = CONSTANTS.SEARCH;
    const searchTerm = this.state.searchTerm.trim();
    const type = 'User';
    return { searchTerm, size, page, type };
  };

  /**
   * @description
   * Checks if current page number has reached total page .
   *
   * @returns
   * This function return true if current page number and total page number are different.
   * Else this will return false.
   */
  hasMorePages = () => {
    const {
      searchUserPage: { number, totalPages }
    } = this.props.search;
    return number < totalPages - 1;
  };

  // renders the search results in a table or renders default message if no data found
  renderSearchResultsTable = () => {
    const {
      searchResultsLoaded,
      searchUserList,
      searchUserPage: { page, totalElements: totalUsers, totalPages }
    } = this.props.search;

    const { sharedAccessSignature: sasToken } = this.props.common.sasToken;
    // Show loading if awaiting api call for users
    if (!searchResultsLoaded && this.state.searchTerm > 2) {
      return <Loader alt={CONSTANTS.COMMON.LOADING} />;
    }

    if (searchResultsLoaded) {
      if (totalUsers === 0) {
        return (
          <p className="data-not-found">
            {CONSTANTS.ADMIN_AND_MODERATORS.emptySearchResult}
          </p>
        );
      }
      // Table header
      const { SEARCH_TABLE_HEADER } = CONSTANTS.SEARCH;
      return (
        <Fragment>
          {<h1 className="result-count">Results: {totalUsers} users</h1>}
          <SearchTable
            header={SEARCH_TABLE_HEADER}
            data={searchUserList}
            fetchMore={this.loadMoreUsers}
            hasMore={this.hasMorePages()}
            currentPage={page}
            sasToken={sasToken}
            totalPages={totalPages}
          />
        </Fragment>
      );
    }
    return null;
  };

  render() {
    const { searchResultsLoaded, isInitialState } = this.props.search;
    const { searchTerm } = this.state;

    return (
      <div className="search-container container">
        <Header title={CONSTANTS.SEARCH.TITLE} />
        <SearchBar
          customClass="search"
          value={searchTerm}
          onKeyUp={this.onKeyUp}
          onChange={this.onSearchChanged}
        />
        {!searchResultsLoaded && searchTerm.length > 2 && (
          <Loader alt={CONSTANTS.COMMON.LOADING} />
        )}

        {!isInitialState && this.renderSearchResultsTable()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  search: state.search,
  common: state.common
});

const mapDispatchToProps = dispatch => ({
  onSearchUsers: data => dispatch(fetchSearchedUser(data)),
  onClearSearchUsers: () => dispatch(clearSearchUser())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
