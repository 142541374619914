import { ACTIONS } from './actionType';

const fetchUserList = data => {
  return {
    type: ACTIONS.REPORT.FETCH_USER_LIST,
    data
  };
};

const fetchDivisionList = data => {
  return {
    type: ACTIONS.REPORT.FETCH_DIVISION_LIST,
    data
  };
};

/**
 * @description
 * This method maintains the selected users in a javascript object.
 * UserId is the key and the corresponding userInfo is the value.
 * if any key is already present, it is removed from the object assuming
 * that it was unselected.
 * else add the userId and userInfo to the object.
 * @param {object} data
 */
const onUserSelectionChanged = ({ userId, userInfo, selectedUsers }) => {
  const existingSelection = { ...selectedUsers };
  if (existingSelection[userId]) {
    delete existingSelection[userId];
    return { ...existingSelection };
  }

  return {
    ...existingSelection,
    [userId]: userInfo
  };
};

const onDivisionSelectionChanged = ({
  divisionId,
  divisionInfo,
  selectedDivisions
}) => {
  const existingSelection = { ...selectedDivisions };
  if (existingSelection[divisionId]) {
    delete existingSelection[divisionId];
    return { ...existingSelection };
  }

  return {
    ...existingSelection,
    [divisionId]: divisionInfo
  };
};

const updatedSelectedUsersList = data => {
  return {
    type: ACTIONS.REPORT.UPDATE_SELECTED_USERS_LIST,
    selectedUsers: onUserSelectionChanged(data)
  };
};
const updatedSelectedDivisionsList = data => {
  return {
    type: ACTIONS.REPORT.UPDATE_SELECTED_DIVISIONS_LIST,
    selectedDivisions: onDivisionSelectionChanged(data)
  };
};

const clearUserList = () => {
  return {
    type: ACTIONS.REPORT.CLEAR_USER_LIST
  };
};

const clearDivisionList = () => {
  return {
    type: ACTIONS.REPORT.CLEAR_DIVISION_LIST
  };
};

const updateReportInputs = data => {
  return {
    type: ACTIONS.REPORT.UPDATE_REPORT_INPUTS,
    data
  };
};

const clearReportInputs = () => {
  return {
    type: ACTIONS.REPORT.RESET_STATE
  };
};

const fetchReportSummary = data => {
  return {
    type: ACTIONS.REPORT.FETCH_REPORT_SUMMARY,
    data
  };
};

export {
  fetchUserList,
  updatedSelectedUsersList,
  clearUserList,
  fetchDivisionList,
  updatedSelectedDivisionsList,
  clearDivisionList,
  updateReportInputs,
  clearReportInputs,
  fetchReportSummary
};
