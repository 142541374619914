import { ACTIONS } from './actionType';

const getSASToken = () => ({
  type: ACTIONS.COMMON.GET_SAS_TOKEN
});

const setAlertMessage = data => ({
  type: ACTIONS.COMMON.SET_ALERT_MESSAGE,
  data
});

const getUserRole = () => ({
  type: ACTIONS.COMMON.GET_USER_ROLE
});

const resetCommonReducer = () => ({
  type: ACTIONS.COMMON.RESET_COMMON_REDUCER
});

const queueActionsOnSASTokenRefresh = () => ({
  type: ACTIONS.COMMON.QUEUE_ACTION_ON_REFRESH_SAS_TOKEN
});

const getNotificationCount = () => ({
  type: ACTIONS.COMMON.GET_NOTIFICATION_COUNT
});

const fetchRemovalReasons = () => ({
  type: ACTIONS.COMMON.FEED_REMOVAL_REASON
});

const openActionModal = data => {
  return {
    type: ACTIONS.COMMON.SHOW_ACTION_MODAL,
    data
  };
};

const fetchUserDetails = data => {
  return {
    type: ACTIONS.COMMON.FETCH_USER_DETAIL,
    data
  };
};

/**
 * @description
 * This method is used to return an action for component where it will perform feed action.
 *
 * @param {Object} data Contains object for performing feed actions
 * @param {Boolean} fromTaskList with default value false. If true then we need to remove an entry from task-list.
 */
const feedAction = (data, fromTaskList = false) => {
  return {
    type: ACTIONS.COMMON.FEED_ACTION,
    data,
    fromTaskList
  };
};

const closeActionModal = () => {
  return { type: ACTIONS.COMMON.HIDE_ACTION_MODAL };
};

const dismissWarningModal = () => {
  return { type: ACTIONS.COMMON.DISMISS_WARNING_MODAL };
};

export {
  getSASToken,
  setAlertMessage,
  getUserRole,
  resetCommonReducer,
  queueActionsOnSASTokenRefresh,
  getNotificationCount,
  fetchRemovalReasons,
  openActionModal,
  fetchUserDetails,
  feedAction,
  closeActionModal,
  dismissWarningModal
};
