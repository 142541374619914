import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import './PaginatedTable.scss';

/**
 * @param {Object} props
 * @description This component accepts two array, one for header and other is for array of rows,
 * which will contain row's data.
 */
const PaginatedTable = props => {
  const { headers, data } = props;

  return (
    <Table padded singleLine basic="very" className="paginated-table">
      <Table.Header className="table-header">
        <Table.Row>
          {headers.map((header, index) => (
            <Table.HeaderCell key={index} className="table-head">
              {header}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body className="table-body">
        {data.map((rows, rowIndex) => (
          <Table.Row key={rowIndex} className="table-row">
            {rows.map((row, index) => (
              <Table.Cell key={index} className="table-cell">
                {row}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

PaginatedTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

export default PaginatedTable;
