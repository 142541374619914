import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'semantic-ui-react';

import CONSTANTS from '../../../../constants';
import ContentBlock from '../../../../components/ContentBlock/ContentBlock';
import {
  replaceWithNA,
  appendSASToken,
  isNullOrUndefined
} from '../../../../utils/utils';
import { UserDetails } from '../../../../common';

import './SummaryTable.scss';

const SummaryTable = ({
  header,
  data,
  isEmojiSupported,
  hasDataLoaded,
  sasToken
}) => {
  /**
   * @description
   * Renders the table cell for 'action taken' column.
   * If the action taken is "Removed" the text appears in red color.
   * @param {Object} rowData contains object with individual row data.
   */
  const renderActionTaken = rowData => {
    if (rowData.action === 'Allowed') {
      return <div className="action-allowed">{CONSTANTS.COMMON.ALLOWED}</div>;
    }
    return <div className="action-removed">{CONSTANTS.COMMON.REMOVED}</div>;
  };

  /**
   * @description
   * Renders the table cell for 'Date of action' column.
   * @param {Object} rowData contains object with individual row data.
   */
  const renderDateOfAction = actionTime => {
    if (isNullOrUndefined(actionTime)) {
      return 'N/A';
    }
    const { DATE_FORMAT_DOTTED, TIME_FORMAT_HHMMA } = CONSTANTS.COMMON;
    return (
      <Fragment>
        <div>{moment(actionTime).format(DATE_FORMAT_DOTTED)}</div>
        <div>{moment(actionTime).format(TIME_FORMAT_HHMMA)}</div>
      </Fragment>
    );
  };

  /**
   * @description
   * This method is used to implement table's row in format. In order format of User, Content, Date posted, Reported by and Actions
   *
   * @param {Object} rowData contains object with individual row data.
   *
   * @returns {ReactComponent} It will return JSX with row data in format.
   */
  const prepareRow = rowData => (
    <Table.Row key={`${rowData.id}-${rowData.itemType}`} className="table-row">
      <Table.Cell className="table-data">
        <UserDetails
          name={rowData.senderName}
          currentRole={rowData.senderCurrentRole}
          hideAvatar
        />
      </Table.Cell>
      <Table.Cell className="table-data">
        <ContentBlock
          text={rowData.text}
          stickerTitle={rowData.stickerTitle}
          stickerUnicode={rowData.stickerUnicode}
          targetName={rowData.targetName}
          feedItemType={rowData.feedItemType}
          badgeName={rowData.badgeName}
          isEmojiSupported={isEmojiSupported}
          imageUrl={appendSASToken(sasToken, rowData.imageUrl)}
        />
      </Table.Cell>
      <Table.Cell className="table-data">
        {renderActionTaken(rowData)}
      </Table.Cell>
      <Table.Cell className="table-data">
        {renderDateOfAction(rowData.actionDate)}
      </Table.Cell>
      <Table.Cell className="table-data">
        {replaceWithNA(rowData.reason)}
      </Table.Cell>
      <Table.Cell className="table-data">
        {replaceWithNA(rowData.unitName)}
      </Table.Cell>
      <Table.Cell className="table-data">
        {replaceWithNA(rowData.hospitalName)}
      </Table.Cell>
    </Table.Row>
  );

  /**
   * @description
   * Renders Table header, based on header value set in constants file.
   *
   * @returns {ReactComponent} return table head
   */
  const renderHeader = () => {
    return (
      <Table.Row>
        {header.map((headerText, index) => (
          <Table.HeaderCell key={index} className="table-head">
            {headerText}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    );
  };

  /**
   * @description
   * This method is used to display individual row of the table which is passed on to it.
   * If data hasn't been passed to it, then it will return null.
   *
   * @returns {ReactComponent} return table body contain(row with data rendered in format).
   */
  const renderBody = () => {
    if (!data.length) return null;

    return data.map(prepareRow);
  };

  return (
    <Fragment>
      {hasDataLoaded && data.length === 0 ? (
        <p className="data-not-found">
          {CONSTANTS.REPORT.REPORT_SUMMARY.NO_DATA_FOUND}
        </p>
      ) : (
        <Table basic="very" className="summary-table table-view">
          <Table.Header>{renderHeader()}</Table.Header>
          <Table.Body>{renderBody()}</Table.Body>
        </Table>
      )}
    </Fragment>
  );
};

SummaryTable.propTypes = {
  header: PropTypes.array,
  data: PropTypes.array.isRequired,
  isEmojiSupported: PropTypes.bool.isRequired,
  hasDataLoaded: PropTypes.bool.isRequired,
  sasToken: PropTypes.string.isRequired
};

SummaryTable.defaultProps = {
  header: CONSTANTS.REPORT.REPORT_SUMMARY.TABLE_HEADER
};

export default SummaryTable;
