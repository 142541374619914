import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Alert, RoundedDropDown, Loader, WarningModal } from '../../common';

import {
  ActionModal,
  Header,
  TableView,
  CardView,
  ViewBy
} from '../../components';

import CONSTANTS from '../../constants';

import {
  resetTaskList,
  fetchTaskList
} from '../../redux/actions/TaskList.actions';

import {
  setAlertMessage,
  fetchRemovalReasons,
  fetchUserDetails,
  dismissWarningModal
} from '../../redux/actions/Common.actions';

import { toggleBodyScroll, setTitle } from '../../utils/utils';

import './TaskList.scss';

export class TaskList extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onFetchTaskList: PropTypes.func.isRequired,
    onFetchRemovalReasons: PropTypes.func.isRequired,
    resetTaskListStore: PropTypes.func.isRequired,
    onFetchUserDetails: PropTypes.func.isRequired,
    onSetAlertMessage: PropTypes.func.isRequired,
    onWarningModalDismiss: PropTypes.func.isRequired
  };

  state = {
    viewCard: true,
    filterBy: CONSTANTS.COMMON.FILTER_BY_OPTIONS.MOST_RECENT
  };

  // Once the component is mounted get initial data.
  componentDidMount() {
    const {
      tasksPage: { number: page }
    } = this.props.tasks;
    setTitle(CONSTANTS.COMMON.PAGE_TITLE.TASK_LIST);
    this.fetchTaskData(page);
    this.props.onFetchRemovalReasons();
  }

  // Check if warning modal or action modal is open. If open then disable body scroll.
  componentDidUpdate() {
    const {
      warningModal: { show: showWarning },
      actionModal: { show: showAction }
    } = this.props.common;
    toggleBodyScroll(showWarning || showAction);
  }

  componentWillUnmount() {
    this.props.resetTaskListStore();
  }

  /**
   * This method extracts it's argument from.
   * sort: From state, where this value will be stored in filterBy object(Updated from drop down).
   * page: From task store, we take tasksPage object which contains
   */
  getFetchTaskDataParams = page => {
    const {
      filterBy: { value: sort }
    } = this.state;
    const { pageSize: size } = CONSTANTS.TASK_LIST;
    return { page, sort, size };
  };

  // This method does API call by fetching all required request params.
  fetchTaskData = (page = 0) => {
    const data = this.getFetchTaskDataParams(page);
    this.props.onFetchTaskList(data);
  };

  // This method updated filterBy state. On click of drop down.
  onChangeFilterBy = filterBy => {
    this.props.resetTaskListStore();
    this.setState({ filterBy }, () => {
      // After updating filter by option, do an API call.
      this.fetchTaskData();
    });
  };

  // Method that toggle view to card view to table view or vice versa. When clicked on icons
  viewToggle = viewCard => {
    this.setState({ viewCard });
  };

  // Reset alert message after dismissal
  resetAlertMessage = () => {
    this.props.onSetAlertMessage(null);
  };

  /**
   * @description
   * Checks if current page number has reached total page .
   *
   * @returns
   * This function return true if current page number and total page number are different.
   * Else this will return false.
   */
  hasMorePages = () => {
    const {
      tasksPage: { number, totalPages }
    } = this.props.tasks;
    return number < totalPages - 1;
  };

  // Method which is used to render card view
  cardViewRender = (tasks, hasMore, nextPage, sasToken, isEmojiSupported) => (
    <CardView
      data={tasks}
      fetchMore={this.fetchTaskData}
      hasMore={hasMore}
      nextPage={nextPage}
      sasToken={sasToken}
      allowAction={this.openAllowActionModal}
      removeAction={this.openRemoveActionModal}
      isEmojiSupported={isEmojiSupported}
      onUserDetailsClicked={this.navigateToUserProfile}
    />
  );

  // Method which is used to render table view
  tableViewRender = (tasks, hasMore, nextPage, sasToken, isEmojiSupported) => (
    <TableView
      data={tasks}
      fetchMore={page => this.fetchTaskData(page)}
      hasMore={hasMore}
      nextPage={nextPage}
      sasToken={sasToken}
      allowAction={this.openAllowActionModal}
      removeAction={this.openRemoveActionModal}
      isEmojiSupported={isEmojiSupported}
      onUserDetailsClicked={this.navigateToUserProfile}
    />
  );

  navigateToUserProfile = userId => {
    this.props.history.push(`/profile/${userId}`);
  };

  /**
   * @description
   * This method when called, dispatches an action to the reducer with some data
   * which opens the "ActionsModal".
   * This method is passed as a property to the UI Component TableView.js and is wired with
   * "Allow" button's onClick property.
   * It has a boolean flag `isAllowAction` which represents the context that the "ActionsModal"
   * is launched to allow the post message.
   *
   * @param {object} feedData An object that represents the properties of a row:
   * Some properties which this function is concerned about:
   * `senderId` - The user who initially posted that message.
   * `itemType` - Determines whether it is a comment or a post
   * `id` - Unique Id representing a post
   * `imageUrl` - Url for profile pic of the sender with SAS token appended to it
   */
  openAllowActionModal = feedData => {
    const isAllowAction = true;
    this.props.onFetchUserDetails({
      isAllowAction,
      ...feedData
    });
  };

  /**
   * @description
   * This method when called, dispatches an action to the reducer with some data
   * which opens the "ActionsModal".
   * This method is passed as a property to the UI Component TableView.js and is wired with
   * "Remove" button's onClick property.
   *
   * @params {object} An object representing the table's row data which only has the following props:
   * `senderId` The user who initially posted that message.
   * `imageUrl` user profile picture icon's url
   * `itemType` Determines whether it is a comment or a post
   */
  openRemoveActionModal = feedData => {
    const isAllowAction = false;
    this.props.onFetchUserDetails({
      isAllowAction,
      ...feedData
    });
  };

  /**
   * @deprecated
   * Based on viewCard value. Either card view or table view is rendered.
   *
   * @param {Boolean} viewCard
   *
   * @argument true then card view render function will be called.
   * @argument false then table view render function will be called.
   */
  renderView = () => {
    const { viewCard } = this.state;
    const {
      tasks,
      tasksPage: { number: page },
      initialDataLoaded
    } = this.props.tasks;
    const { isEmojiSupported } = this.props.common;
    const hasMore = this.hasMorePages();
    const nextPage = page + 1;

    // Loading while data is fetching
    if (!initialDataLoaded) {
      return <Loader />;
    }

    // Post call if there are no data then show empty message.
    if (tasks.length === 0) {
      return (
        <h1 className="no-task-warning">{CONSTANTS.TASK_LIST.NO_DATA_FOUND}</h1>
      );
    }

    const { sharedAccessSignature: sasToken } = this.props.common.sasToken;
    // Render based on
    if (viewCard) {
      return this.cardViewRender(
        tasks,
        hasMore,
        nextPage,
        sasToken,
        isEmojiSupported
      );
    }
    return this.tableViewRender(
      tasks,
      hasMore,
      nextPage,
      sasToken,
      isEmojiSupported
    );
  };

  render() {
    const { filterBy, viewCard } = this.state;
    const {
      actionModal: { show },
      warningModal: { show: showWarningModal, message: warningMessage }
    } = this.props.common;
    const {
      TASK_LIST: { title },
      COMMON: { FILTER_BY_OPTIONS }
    } = CONSTANTS;
    const dropDownOption = Object.values(FILTER_BY_OPTIONS);
    const { alertMessage } = this.props.common;
    const { onWarningModalDismiss } = this.props;
    const { TITLE: warningTitle } = CONSTANTS.COMMON.WARNING_MODAL;

    return (
      <div className="task-list container">
        {alertMessage && (
          <Alert message={alertMessage} onDismiss={this.resetAlertMessage} />
        )}
        <WarningModal
          showModal={showWarningModal}
          title={warningTitle}
          message={warningMessage}
          onDismiss={onWarningModalDismiss}
        />
        <ActionModal showModal={show} fromTaskList />
        <Header title={title}>
          <RoundedDropDown
            options={dropDownOption}
            selectedOption={filterBy}
            onChange={this.onChangeFilterBy}
          />
          <ViewBy onClick={this.viewToggle} viewCard={viewCard} />
        </Header>
        <div className="view-wrapper">{this.renderView(viewCard)}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  common: state.common,
  tasks: state.task
});

const mapDispatchToProps = dispatch => ({
  onFetchTaskList: data => dispatch(fetchTaskList(data)),
  resetTaskListStore: () => dispatch(resetTaskList()),
  onFetchRemovalReasons: () => dispatch(fetchRemovalReasons()),
  onFetchUserDetails: data => dispatch(fetchUserDetails(data)),
  onSetAlertMessage: data => dispatch(setAlertMessage(data)),
  onWarningModalDismiss: () => dispatch(dismissWarningModal())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TaskList)
);
