import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Modal, UserDetails, Button, RoundedDropDown } from '../../common';
import CONSTANTS from '../../constants';
import {
  closeActionModal,
  feedAction
} from '../../redux/actions/Common.actions';

import './ActionModal.scss';
import { isNullOrUndefined, replaceWithNA } from '../../utils/utils';
import ENUM from '../../enum';

/**
 * UI for the pop-up modal opened when actions buttons: "Allow" or "Remove" is clicked.
 * This modal is shown or hidden based on a flag: "showModal" which is present in the redux
 * store file: 'TaskList.reducer.js'
 *
 * Properties:
 * `actionModal` - Includes properties that are specific to the modal such as
 * senderName, managerName, hospital & unit etc.
 * `onActionModalClosed` - A function when called, dispatches an action to
 * redux store which hides the modal.
 * `onFeedActionPerformed` - This function when called, dispatches an action
 * which sends request to the Backend to perform action on the post.
 * `removalReasons` - List of reasons for removing the post.
 * Reason has to be selected only if a post is to be removed.
 */
class ActionModal extends Component {
  static propTypes = {
    userRole: PropTypes.object.isRequired,
    actionModal: PropTypes.object.isRequired,
    onActionModalClosed: PropTypes.func.isRequired,
    onFeedActionPerformed: PropTypes.func.isRequired,
    removalReasons: PropTypes.array.isRequired,
    fromTaskList: PropTypes.bool
  };

  static defaultProps = {
    fromTaskList: false
  };

  state = {
    description: '',
    selectedReason: null
  };

  /**
   * @description
   * This function resets the internal state maintained by this component.
   * When user closes the modal by clicking outside of the modal or by clicking the "X" button,
   * the internal data: "description" & "selectedReason" has to be cleared.
   * So that opening the modal again for a different post won't retain those changes.
   *
   * @parameters
   * callBack - Function to be called after clearing the internal state
   * params - Necessary params to be passed to that callBack function
   */
  resetModalState = (callBack, ...params) => {
    this.setState(
      {
        description: '',
        selectedReason: null
      },
      () => callBack(...params)
    );
  };

  /**
   * @description
   * Function to close the modal on click of the button X in the top left corner
   * or on click of "Cancel" button.
   * Dispatches an action to the redux store which resets the flag that is responsible
   * for the modal's visibility.
   */
  closeModal = () => {
    this.resetModalState(this.props.onActionModalClosed);
  };

  /**
   * @description
   * 1) Gathers all the required properties like: user "comment", "reason to remove", "action type"
   * from the UI Components
   * 2) Constructs the request object in this format:
   *  {
   *    status: 1,
   *    description: '',
   *    removalReasonId: 3
   *  }
   * 3) Triggers the method: "onFeedActionPerformed" which in turn
   * dispatches a saga action to request the backend to
   * take appropriate action on a post.
   */
  submitAction = () => {
    const { id, isAllowAction, itemType } = this.props.actionModal;
    const { ALLOWED, REMOVED } = ENUM.STATUS;
    const { description, selectedReason } = this.state;
    const { username } = this.props.userRole;

    const request = {
      status: isAllowAction ? ALLOWED : REMOVED,
      description: description.trim(),
      ...(!isAllowAction && { removalReasonId: selectedReason.value })
    };

    const { fromTaskList } = this.props;
    this.resetModalState(
      this.props.onFeedActionPerformed,
      {
        id,
        itemType,
        request,
        username
      },
      fromTaskList
    );
  };

  /**
   * @description
   * This method is attached to the textarea field in the modal and is called everytime
   * when user types something in the textarea.
   * It updates the internal state with recent description.
   *
   * @param {SyntheticEvent} event - React event object fired on change of any input element
   */
  updateComments = event => {
    const { value: description = '' } = event.target;
    this.setState({ description });
  };

  /**
   * @description
   * Similar to the above method: "updateComments".
   * This gets called everytime when user selects a reason in RoundedDropdown.
   * It updates the internal state with `selectedReason`
   *
   * @param {Object} selectedReason - An object from `removalReasons` array.
   */
  updateSelectedDisposition = selectedReason => {
    this.setState({ selectedReason });
  };

  canSubmitBeEnabled = () => {
    const { isAllowAction } = this.props.actionModal;
    const { description, selectedReason } = this.state;

    if (isAllowAction) return description.trim().length;
    return selectedReason;
  };

  render() {
    const {
      show,
      isAllowAction,
      imageUrl,
      userDetail
    } = this.props.actionModal;

    if (!show || isNullOrUndefined(userDetail)) {
      // if userDetail is not yet loaded, then there's nothing to show in the modal so return null;
      return null;
    }

    const {
      name = '',
      currentRole = '',
      manager = '',
      unitName = '',
      hospitalName = ''
    } = userDetail;
    const { removalReasons } = this.props;

    const {
      ALLOW_MODAL_TITLE: allowTitle,
      REMOVE_MODAL_TITLE: removeTitle,
      MANAGER,
      UNIT,
      HOSPITAL,
      COMMENTS_PLACEHOLDER
    } = CONSTANTS.COMMON.ACTIONS_MODAL;

    const { selectedReason, description = '' } = this.state;

    const dispositionDropDownOptions = [
      { value: 'default', text: 'Select Reason', disabled: true },
      ...removalReasons
    ];

    /**
     * Submit button should be disabled until user types a comment
     * in case of remove action
     */

    const isSubmitBtnEnabled = this.canSubmitBeEnabled();
    const submitBtnCss = cx([
      'btn btn-right btn-text btn-primary',
      { 'btn-primary-disabled': !isSubmitBtnEnabled }
    ]);

    return (
      <Modal
        show={show}
        modalClosed={this.closeModal}
        title={isAllowAction ? allowTitle : removeTitle}
        customClasses="modal-allow-remove-action modal-gray"
      >
        <header className="action-modal-header">
          <UserDetails
            name={name}
            currentRole={currentRole}
            imageUrl={imageUrl}
          />
          <div className="info-panel">
            <div className="info-item">
              <span className="label">{MANAGER}</span>
              {replaceWithNA(manager)}
            </div>
            <div className="info-item">
              <span className="label">{UNIT}</span>
              {replaceWithNA(unitName)}
            </div>
            <div className="info-item">
              <span className="label">{HOSPITAL}</span>
              {replaceWithNA(hospitalName)}
            </div>
          </div>
        </header>

        <main className="action-modal-main">
          {!isAllowAction && (
            // Dropdown to select reasons to remove a post - only for remove action.
            <RoundedDropDown
              customClasses="full-width"
              options={dispositionDropDownOptions}
              selectedOption={
                isNullOrUndefined(selectedReason)
                  ? dispositionDropDownOptions[0]
                  : selectedReason
              }
              onChange={this.updateSelectedDisposition}
            />
          )}
          <textarea
            rows="10"
            className="comments"
            placeholder={COMMENTS_PLACEHOLDER}
            value={description}
            onChange={this.updateComments}
          />
        </main>

        <footer className="button-panel action-modal-footer">
          <Button
            value={CONSTANTS.COMMON.CANCEL}
            customClass="btn btn-left btn-primary-outline btn-text"
            onClick={this.closeModal}
          />
          <Button
            value={CONSTANTS.COMMON.SUBMIT}
            onClick={this.submitAction}
            customClass={submitBtnCss}
            disabled={!isSubmitBtnEnabled}
          />
        </footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  userRole: state.common.userRole,
  actionModal: state.common.actionModal,
  removalReasons: state.common.removalReasons
});

const mapDispatchToProps = dispatch => ({
  onActionModalClosed: () => dispatch(closeActionModal()),
  onFeedActionPerformed: (data, fromTaskList) =>
    dispatch(feedAction(data, fromTaskList))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionModal);
