import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '../../../common';
import CONSTANTS from '../../../constants';

import './RemoveUsers.scss';

/**
 * Functional component which has the UI for Remove users modal.
 */

const RemoveUsers = props => {
  const { showModal, userName, closeModal, submitAction } = props;
  const {
    removeUserDialogTitle,
    removeUserConfirmation,
    removeUserSubmit
  } = CONSTANTS.ADMIN_AND_MODERATORS;
  return (
    <Modal
      show={showModal}
      modalClosed={closeModal}
      title={removeUserDialogTitle}
    >
      <p className="confirmation-text">
        {removeUserConfirmation.replace('%s', userName)}
      </p>
      <div className="button-panel">
        <Button
          value={CONSTANTS.COMMON.CANCEL}
          customClass="btn-left btn-primary-outline btn-text"
          onClick={closeModal}
        />
        <Button
          value={removeUserSubmit}
          customClass="btn-right btn-primary btn-text"
          onClick={submitAction}
        />
      </div>
    </Modal>
  );
};

RemoveUsers.propTypes = {
  showModal: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RemoveUsers;
