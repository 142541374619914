import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import './PaginationButton.scss';

import PrevIcon from '../../assets/images/previous.svg';
import NextIcon from '../../assets/images/next.svg';

const PaginationButton = props => {
  const { prevClick, nextClick, currentPage, totalPages } = props;

  /**
   * This method will decrement page value by one, if page value is not equal to zero
   * It will also pass decremented value back to prevClick function(props)
   */
  const prevButtonClicked = () => {
    if (currentPage !== 0) {
      prevClick(currentPage - 1);
    }
  };

  /**
   * This method will increment page value by one, if page value is not equal to max page
   * It will also pass incremented value back to prevClick function(props)
   */
  const nextButtonClicked = () => {
    if (currentPage !== totalPages - 1) {
      nextClick(currentPage + 1);
    }
  };

  // To check if we would like to disable the next or prev buttons.
  const isPrevBtnDisable = currentPage === 0;
  const isNextBtnDisable = currentPage === totalPages - 1;

  return (
    <div className="pagination-btn">
      <Button
        customClass="nav-btn"
        title="prev"
        onClick={prevButtonClicked}
        disabled={isPrevBtnDisable}
        iconPath={PrevIcon}
        iconWidth="7px"
        iconHeight="12px"
      />
      <div className="page-details">
        <span className="page-count">{currentPage + 1}</span> of {totalPages}
      </div>
      <Button
        customClass="nav-btn"
        title="next"
        onClick={nextButtonClicked}
        disabled={isNextBtnDisable}
        iconPath={NextIcon}
        iconWidth="7px"
        iconHeight="12px"
      />
    </div>
  );
};

PaginationButton.propTypes = {
  prevClick: PropTypes.func.isRequired,
  nextClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default PaginationButton;
