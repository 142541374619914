import React from 'react';
import PropTypes from 'prop-types';

import NavigationItem from './NavigationItem/NavigationItem';
import routes, { TASK_LIST_NAME } from '../../route';

import './Navigation.scss';

/**
 * @description
 * This function is wrapper function for navigation bar
 * This renders all the navigation mentioned in route json
 */
const Navigation = ({ notificationCount }) => {
  return (
    <nav className="navigation">
      <ul className="nav-bar">
        {routes.map((item, index) => {
          // Create a badge object with boolean that checks if has badge, and notificationCount
          const badge = { hasBadge: false, notificationCount };
          // If route is task list then render badge.
          if (item.name === TASK_LIST_NAME) {
            badge.hasBadge = true;
          }
          return (
            <NavigationItem
              key={index}
              link={item.path}
              name={item.name}
              activeLogo={item.activeIcon}
              inactiveLogo={item.inactiveIcon}
              tooltip={item.tooltip}
              badge={badge}
            />
          );
        })}
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  notificationCount: PropTypes.number
};

Navigation.defaultProps = {
  notificationCount: 0
};

export default Navigation;
