import React, { Fragment } from 'react';

import loader from '../../assets/images/Loader.gif';
import { Backdrop } from '..';
import './Loader.scss';
import CONSTANTS from '../../constants';
import SvgImage from '../SvgImage/SvgImage';

const Loader = () => {
  const { LOADING: loadingText } = CONSTANTS.COMMON;
  return (
    <Fragment>
      <Backdrop show clicked={() => {}} />
      <div className="loader">
        <SvgImage
          src={loader}
          className="loader-image"
          alt=""
          width="70px"
          height="70px"
        />
        <p className="loader-text">{loadingText}</p>
      </div>
    </Fragment>
  );
};

export default Loader;
