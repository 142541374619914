import {
  getLoginUrl,
  searchUrl,
  divisionUrl,
  storageUrl,
  userUrl,
  rolesUrl,
  superUserUrl,
  taskListUrl,
  feedUrl,
  masterUrl,
  commentUrl,
  generateReportUrl,
  downloadReportUrl
} from './endpoints';

const API = {
  getLoginUrl,
  sas: `${storageUrl}/sas`,
  searchUrl,
  divisionUrl,
  userUrl,
  lastLoggedOn: `${superUserUrl}/last-logged-on`,
  rolesUrl,
  superUserRole: `${superUserUrl}/roles`,
  taskList: taskListUrl,
  contentRemovalReasons: `${masterUrl}/content-removal-reasons`,
  feedUrl,
  commentUrl,
  generateReportUrl,
  downloadReportUrl
};

export default API;
